import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'expanding-input-field',
  templateUrl: './expanding-input-field.component.html',
  styleUrls: ['./expanding-input-field.component.scss'],
})
export class ExpandingInputFieldComponent implements OnInit {
  myControl = new UntypedFormControl();
  @Output() onSubmit: EventEmitter<string> = new EventEmitter<string>();
  @Input() autocompleteOptions: Array<string>;
  filteredOptions: Array<string>;
  constructor() {}

  ngOnInit(): void {
    //Change the available options
    if (this.autocompleteOptions) {
      this.filteredOptions = this.autocompleteOptions;
      this.myControl.valueChanges.subscribe((value: string) => {
        this.filteredOptions = this.autocompleteOptions.filter((tagName) => {
          return tagName.toLowerCase().startsWith(value.toLowerCase());
        });
      });
    }
  }
  displayFn(tagName: string): string {
    return tagName ? tagName : '';
  }
  clickInput(): void {
    const el = document.getElementById('expandInput');
    const elStyles = window.getComputedStyle(el);
    let fieldClosed: boolean =
      +elStyles.width.slice(0, elStyles.width.length - 2) <= 50;
    if (this.myControl.value && !fieldClosed) {
      // if field is open and pressed then submit and close and reset value
      this.onSubmit.emit(this.myControl.value);
      this.myControl.setValue(''); //reset input field
      el.blur();
    } else {
      if (fieldClosed) {
        el.focus();
      } else {
        el.blur();
      }
    }
  }
}
