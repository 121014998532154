import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from 'src/app/Services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {

  constructor(private auth: AuthenticationService){}

  canActivate(route: ActivatedRouteSnapshot): boolean {
      return this.auth.isAdmin();
  }
  
}
