import { Injectable } from '@angular/core';
import { Timeslot } from '../Models/timeslot';
import * as moment from 'moment';

export const ALL_ADVISORS: string = 'Alla Rådgivare';

@Injectable({
  providedIn: 'root',
})
export class TimelineHelperService {
  constructor() {}

  splitTimeslots(
    timeslots: Array<Timeslot>
  ): [Array<Timeslot>, Array<Timeslot>] {
    let futureTimeslots: Array<Timeslot> = [];
    let pastTimeslots: Array<Timeslot> = [];
    timeslots.forEach((timeslot) => {
      let startTime: moment.Moment = moment(timeslot.start);
      startTime.diff(moment(), 'days') >= 0
        ? futureTimeslots.push(timeslot)
        : pastTimeslots.push(timeslot);
    });
    futureTimeslots = futureTimeslots;
    pastTimeslots = pastTimeslots.reverse();
    return [futureTimeslots, pastTimeslots];
  }

  getComingDays(futureTimeslots: Array<Timeslot>): Array<string> {
    moment.locale('sv');
    let result: Array<string> = [];
    futureTimeslots.forEach((timeslot) => {
      if (!result.includes(moment(timeslot.start).format('D MMMM, YYYY'))) {
        result.push(moment(timeslot.start).format('D MMMM, YYYY'));
      }
    });
    return result;
  }

  filterAvailable(
    available: boolean,
    timeslots: Array<Timeslot>
  ): Array<Timeslot> {
    let result: Array<Timeslot> = [];
    timeslots.forEach((timeslot) => {
      if (available && !timeslot.advice) result.push(timeslot);
      else if (!available && timeslot.advice) result.push(timeslot);
    });
    return result;
  }

  filterAdvisor(
    advisorName: string,
    timeslots: Array<Timeslot>
  ): Array<Timeslot> {
    let result: Array<Timeslot> = [];
    if (advisorName === ALL_ADVISORS) {
      result = [...timeslots];
    } else {
      timeslots.forEach((timeslot): void => {
        if (
          timeslot.advisor &&
          timeslot.advisor.name &&
          timeslot.advisor.name == advisorName
        ) {
          result.push(timeslot);
        }
      });
    }
    return result;
  }
}
