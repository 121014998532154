<div class="page">
  <ng-container [ngSwitch]="(store$.status$ | async)">
    <ng-container *ngSwitchCase="'loading'">
      <div class="loader">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'failure'">
      <h1 class="text-center pt-12">Något gick fel vid laddningen, försök igen</h1>
    </ng-container>

    <ng-container *ngSwitchCase="'success'">
      <div class="content pt-8">
        <div class="active">
          <h1 class="tk-skolar-latin">Aktiva</h1>
          <app-news-list-header *ngIf="(store$.activeNews$ | async).length > 0"
                                [forActive]="true"></app-news-list-header>
          <app-news-list-item *ngFor="let item of store$.activeNews$ | async" [item]="item" class="block mb-4"
                              (deleted)="deactivateNews($event)"></app-news-list-item>
        </div>
        <div class="inactive">
          <h1 class="tk-skolar-latin">Inaktiva</h1>
          <app-news-list-header *ngIf="(store$.inactiveNews | async).length > 0"
                                [forActive]="false"></app-news-list-header>
          <app-news-list-item *ngFor="let item of store$.inactiveNews | async" [item]="item"
                              class="block mb-4"></app-news-list-item>

        </div>
      </div>

      <div class=" fixed right-28 bottom-40">
        <button class="flex justify-center items-center" mat-fab (click)="createNews()" color="accent">
          <mat-icon >add</mat-icon>
        </button>
      </div>
    </ng-container>


  </ng-container>
</div>

