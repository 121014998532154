<div class="page">
  <h1 class="page-title">Konsulthantering</h1>

  <div class="mat-spinner-container" *ngIf="!hasLoaded; else loadedConsultants">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</div>

<ng-template #loadedConsultants>
  <div class="consultants-container">
    <div class="header">
      <div class="name-container">Namn</div>
      <div class="company-container">Företag</div>
      <div class="e-mail-container">E-postadress</div>
      <div class="phone-container">Telefonnummer</div>
      <div class="ass-companies-container">Antal tilldelade</div>
    </div>
    <div class="consultants-list-container">
      <consultant-list-item
        *ngFor="let consultant of consultants"
        [consultant]="consultant"
        (consultantClicked)="openDialog(consultant)"
      >
      </consultant-list-item>
    </div>
    <div class="fab-button-container">
      <button mat-fab color="primary" (click)="openDialog()">
        <mat-icon
          class="material-icon"
          aria-hidden="false"
          aria-label="Example home icon"
          >add</mat-icon
        >
      </button>
    </div>
  </div>
</ng-template>
