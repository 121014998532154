<ng-container *ngIf="isMobile; else desktop">
  <ng-container
    *ngIf="insideChatAndMobile; then mobileChat; else mobileOutsideChat"
  ></ng-container>
</ng-container>

<ng-template #desktop>
  <div class="top-bar">
    <img src="../assets/Wrebit Logo Green.png" alt="WREBIT Logo" class="logo" />
    <div class="navbar" [ngClass]="{'single-item-navbar' : !isAdmin}">
      <div class="nav-button-container">
        <button class="nav-button" id="search" (click)="setActive($event)">
          Sök företag
        </button>
        <img
          src="../../../assets/weird-shape1.svg"
          id="search-blob"
          alt=""
          class="bg-blob"
        />
      </div>
      <div *ngIf="isAdmin" class="nav-button-container">
        <button class="nav-button" id="advice" (click)="setActive($event)">
          Rådgivningstider
        </button>
        <img
          src="../../../assets/weird-shape1.svg"
          id="advice-blob"
          alt=""
          class="bg-blob"
        />
      </div>

      <div *ngIf="isAdmin" class="nav-button-container">
        <button class="nav-button" id="bookkeep" (click)="setActive($event)">
          Bokför åt kund
        </button>
        <img
          src="../../../assets/weird-shape1.svg"
          id="bookkeep-blob"
          alt=""
          class="bg-blob"
        />
      </div>

      <div *ngIf="isAdmin" class="nav-button-container">
        <button class="nav-button" id="chat" (click)="setActive($event)">
          Chatt
        </button>
        <img
          src="../../../assets/weird-shape1.svg"
          id="chat-blob"
          alt=""
          class="bg-blob"
        />
      </div>

      <div *ngIf="isAdmin" class="nav-button-container">
        <button class="nav-button" id="log" (click)="setActive($event)">
          Loggade ärenden
        </button>
        <img
          src="../../../assets/weird-shape1.svg"
          id="log-blob"
          alt=""
          class="bg-blob"
        />
      </div>

      <div *ngIf="isAdmin" class="nav-button-container">
        <button class="nav-button" id="consultant-handling" (click)="setActive($event)">
          Konsulthantering
        </button>
        <img
          src="../../../assets/weird-shape1.svg"
          id="consultant-handling-blob"
          alt=""
          class="bg-blob"
        />
      </div>
      <div *ngIf="isAdmin" class="nav-button-container">
        <button class="nav-button" id="news-handling" (click)="setActive($event)">
          Nyheter
        </button>
        <img
          src="../../../assets/weird-shape1.svg"
          id="news-handling-blob"
          alt=""
          class="bg-blob"
        />
      </div>
      <div *ngIf="isAdmin" class="nav-button-container">
        <button class="nav-button" id="account-handling" (click)="setActive($event)">
          Kontohantering
        </button>
        <img
          src="../../../assets/weird-shape1.svg"
          id="account-handling-blob"
          alt=""
          class="bg-blob"
        />
      </div>

    </div>

    <div class="login-info">
      <button mat-button [matMenuTriggerFor]="logoutMenu" class="user-name">
        {{ activeUser ? activeUser : "Okänd användare" }}
      </button>
      <mat-menu #logoutMenu>
        <button mat-menu-item class="logout-button" (click)="logout()">
          Logga ut
        </button>
      </mat-menu>
    </div>
  </div></ng-template
>

<ng-template #mobileOutsideChat>
  <div class="mobile-top-bar">
    <div class="mobile-logo-container">
      <img
        src="../assets/Wrebit Logo Green.png"
        alt="WREBIT Logo"
        class="mobile-logo"
      />
      <p class="mobile-logo-admin">admin</p>
    </div>
    <button class="text-button mobile-logout" (click)="logout()">
      Logga ut
    </button>
  </div>
</ng-template>

<ng-template #mobileChat>
  <div class="mobile-inside-chat-top-bar">
    <back-button id="chat-back-button" (click)="goBack()"></back-button>
    <button class="text-button quit-chat-button" (click)="quitChat()">
      Avsluta
    </button>
  </div>
</ng-template>
