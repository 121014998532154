<div class="page">
  <div class="title-container">
    <h1 class="title">Lägg till information</h1>

    <div class="input-container">
      <div class="left">
        <h2 class="input-title">Antal minuter</h2>
        <div class="input-group">
          <input
            [formControl]="minutesControl"
            type="text"
            class="form-control"
            placeholder="Minuter"
          />
          <div class="input-group-append">
            <span class="input-group-text"> min </span>
          </div>
        </div>

        <div class="input-group mt-2">
          <input
            *ngIf="type === 'bookkeep-for-me'"
            [formControl]="bfmPriceControl"
            type="text"
            class="form-control"
            placeholder="pris"
          />
          <div class="input-group-append">
            <span class="input-group-text"> kr </span>
          </div>
        </div>
        <p class="payment" *ngIf="type !== 'bookkeep-for-me'">
          <span class="price">{{
            ConvertStringToNumber(minutesControl.value) * 12 + ' kr '
          }}</span
          >för kund att betala
        </p>
      </div>
      <div class="right">
        <h2 class="input-title">Internt meddelande</h2>
        <mat-form-field appearance="outline" id="message-box">
          <textarea
            matInput
            [formControl]="messageControl"
            placeholder="Internt meddelande"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
  <button
    class="confirm-button"
    (click)="makeHandled()"
    [disabled]="
      (minutesControl.value && minutesControl.value.length === 0) ||
      !minutesControl.value
    "
  >
    {{ 'Klar!  Lägg till i lista' | uppercase }}
  </button>
</div>
