import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormArray,
  Validators,
  Form,
} from '@angular/forms';
import {
  Row,
  Verification,
  FinancialYear,
  VerificationResponse,
  Account,
} from '../../Models/accounting-row';
import { BookkeepForMe } from 'src/app/Models/bookkeep-for-me';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/Services/api.service';
import { AuthenticationService } from 'src/app/Services/auth.service';
import { Company } from 'src/app/Models/company';
import { MatDialog } from '@angular/material/dialog';
import { HandleIssueModalComponent } from 'src/app/Modals/handle-issue-modal/handle-issue-modal.component';
import * as moment from 'moment';

@Component({
  selector: 'app-manual-accounting',
  templateUrl: './manual-accounting.component.html',
  styleUrls: ['./manual-accounting.component.scss'],
})
export class ManualAccountingComponent implements OnInit {
  rows: Array<Row> = [];
  general: UntypedFormGroup;
  bfm: BookkeepForMe;
  companyInfo: Company;
  showAccountPlan = false;
  accountPlan: Array<Account>;
  rowsForm: UntypedFormGroup;
  customerContacted = false;
  items: UntypedFormArray;
  note: string;
  fRows: any;
  balance = 0;
  debitControl = new UntypedFormControl();
  date = new UntypedFormControl(new Date());

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private auth: AuthenticationService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.bfm = JSON.parse(this.route.snapshot.paramMap.get('bfm'));
    console.log(this.bfm);
    this.customerContacted = this.bfm.customerContacted ? true : false;
    this.api.getAccountPlan().subscribe((accountPlan) => {
      this.accountPlan = (accountPlan as any).accounts;
      console.log(this.accountPlan);
    });
    this.rowsForm = this.formBuilder.group({
      date: [this.bfm.paymentDate],
      description: [''],
      rows: this.formBuilder.array([this.getRow()]),
    });
    this.fRows = (this.rowsForm.controls.rows as any).value;
    this.api.getCompany(this.bfm.companyId).subscribe((company: Company) => {
      this.companyInfo = company;
    });
    console.log('version 1.1.3');
  }

  sendVerification() {
    const ver: Verification = new Verification();

    const verDate = new Date(this.rowsForm.value.date);
    this.api.getCompany(this.bfm.companyId).subscribe((company) => {
      this.api
        .getFinancialYears(this.bfm.companyId)
        .subscribe((financialYears: Array<FinancialYear>) => {
          // Needed to not convert to 00:00 localtime --> wrong date
          const fixedVerDate = new Date(moment(verDate).format('YYYY-MM-DD'));
          financialYears.forEach((financialYear) => {
            if (
              fixedVerDate >= new Date(financialYear.start) &&
              fixedVerDate <= new Date(financialYear.end) &&
              !financialYear.locked
            ) {
              console.log('WORKS');
              ver.financialYearId = financialYear.id;
            }
          });
          if (ver.financialYearId) {
            ver.series = 'A';
            ver.accountingDate = fixedVerDate.toISOString().split('T')[0];
            ver.description = this.rowsForm.value.description;

            ver.rows = [];
            this.formData.value.forEach((row, index) => {
              ver.rows.push(new Row(index, row.amount, row.account, row.debit));
            });

            ver.images = [this.bfm.receiptURL];
            ver.otherInfo = {};
            ver.bookkeepForMeId = this.bfm.id;
            console.log(ver);

            this.api
              .createVer(ver, this.bfm.companyId, this.auth.getUserId())
              .subscribe(
                (res: VerificationResponse) => {
                  console.log(res);
                  alert(`Verifikation ${res.number} skapad`);
                  this.auth
                    .getActiveUserName()
                    .then(
                      (name) => {
                        console.log('Test');
                        console.log(name);
                        const dialogRef = this.dialog.open(
                          HandleIssueModalComponent,
                          {
                            data: {
                              type: this.bfm.type,
                            },
                          },
                        );
                        dialogRef.componentInstance.exit.subscribe((event) => {
                          this.api
                            .handleBookkeepForMe(
                              this.bfm.id,
                              this.note,
                              this.auth.getUserId(),
                              name,
                              res.id,
                            )
                            .subscribe(
                              (bfmRes) => {
                                this.router.navigateByUrl('assigments');
                                alert(
                                  'Ärendet ligger nu under loggade ärenden där du kan fylla i estimerad tidsåtgång',
                                );
                              },
                              (error) => {
                                console.error(error);
                                alert(
                                  'Något gick fel vid hantering av ärende. Verifikationen är skapad men kunde inte markera ärendet som färdigt. Var vänlig kontakt support.',
                                );
                              },
                            );

                          dialogRef.close();
                        });

                        dialogRef.componentInstance.send.subscribe((event) => {
                          dialogRef.close();
                          this.api
                            .handleBookkeepForMe(
                              this.bfm.id,
                              this.note,
                              this.auth.getUserId(),
                              name,
                              res.id,
                              event.duration,
                            )
                            .subscribe(
                              (bfmRes) => {
                                this.router.navigateByUrl('assigments');
                              },
                              (error) => {
                                console.error(error);
                                alert(
                                  'Något gick fel vid hantering av ärende. Verifikationen är skapad men kunde inte markera ärendet som färdigt. Var vänlig kontakt support.',
                                );
                              },
                            );
                        });
                      },
                      (error) => {
                        console.error(error);
                        alert(
                          'Något gick fel vid hantering av ärende. Verifikationen är skapad men kunde inte markera ärendet som färdigt. Var vänlig kontakt support.',
                        );
                      },
                    )
                    .catch((err) => {
                      console.error(err);
                    });
                },
                (error) => {
                  console.log(error);
                  alert(
                    'Något gick fel vid skapande av verifikation. Dubbelkolla att kontonummer stämmer och att verifikatet balanserar.',
                  );
                },
              );
          } else {
            alert('Inget räkenskapsår passar för det aktuella underlaget');
          }
        });
    });
  }

  updateCustomerContacted(): void {
    if (!this.customerContacted) {
      this.api.updateBFM(this.bfm.id, 'null').subscribe((res) => {
        console.log(res);
        this.customerContacted = false;
      });
    } else {
      this.api.updateBFM(this.bfm.id, 'true').subscribe((res) => {
        console.log(res);
        this.customerContacted = true;
      });
    }
  }

  addRow(): void {
    this.items = this.rowsForm.controls.rows as UntypedFormArray;
    this.items.push(this.getRow());
  }
  private getRow() {
    return this.formBuilder.group({
      amount: '',
      account: '',
      debit: 'debit',
    });
  }
  getBalance(): number {
    let result = 0;
    this.formData.value.forEach((data: any) => {
      const row = new Row(null, data.amount, data.account, data.debit);
      if (row.debit === true && row.account && row.amount) {
        result += +row.amount;
      } else if (row.debit === false && row.account && row.amount) {
        result -= +row.amount;
      }
    });
    return +result.toFixed(2);
  }
  get formData() {
    return this.rowsForm.get('rows') as UntypedFormArray;
  }
  /**
   * Toggles between showing accountplan and not
   */
  toggleShowAccounts(): void {
    this.showAccountPlan = !this.showAccountPlan;
  }
}
