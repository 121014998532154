import { Component, EventEmitter, OnInit, Output, Inject } from '@angular/core';

import { AuthenticationService } from 'src/app/Services/auth.service';
import { ChatService } from 'src/app/Services/chat.service';

interface HandleModalData {
  type: 'bookkeep-for-me' | 'chat';
}
@Component({
  selector: 'app-handle-issue-modal',
  templateUrl: './handle-issue-modal.component.html',
  styleUrls: ['./handle-issue-modal.component.scss'],
})
export class HandleIssueModalComponent implements OnInit {
  minutes: string;
  price: string;
  isMobile: boolean;
  type = 'chat';
  @Output() exit: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() send: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private chatService: ChatService,
    private auth: AuthenticationService,
  ) {
    this.isMobile = this.auth.mobilCheck();
  }

  ngOnInit(): void {}
  cancel(): void {
    this.exit.emit(true);
  }
  submit(): void {
    console.log(this.minutes + ' ' + this.price);
    this.send.emit({ duration: this.minutes, price: this.price });
  }
}
