import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ResetPasswordGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot): boolean {
    return !!route.queryParams.token;
  }
  
}
