import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {concatMap, from, of} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(private http: HttpClient) {
  }

  getImageFileAsB64(file: File) {
    return from(new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    }));
  }

  uploadImage(file: File) {
    return this.getImageFileAsB64(file).pipe(
      catchError((err, caught) => of(null)),
      concatMap((value: string | null) => value ? this.http.post<{ url: string }>(
          `${environment.apiUrl}/companies/upload-logo`,
          {data: value},
        ) : of(null)
      ),
    );


  }
}
