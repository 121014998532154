<div class="form-section">
  <label for="assignItems">{{ label }}</label>
  <input
    type="text"
    class="form-input-field"
    id="assignItems"
    matInput
    [formControl]="assignItemsControl"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    autoActiveFirstOption
    [displayWith]="getItemValue"
    (optionSelected)="selectCurrentItem($event)"
  >
    <mat-option *ngFor="let item of availableItems | keyvalue" [value]="item">
      {{ item.value }}
    </mat-option>
  </mat-autocomplete>
</div>
<div class="assigned-items-list-section">
  <div class="none-selected" *ngIf="!selectedItems.size">
    {{ noneSelectedText }}
  </div>
  <button
    class="tag"
    type="button"
    *ngFor="let item of selectedItems | keyvalue; let i = index"
    (click)="unselectCurrentItem(item)"
  >
    <div class="tag-text">{{ item.value }}</div>
  </button>
</div>
