import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';
import { BookkeepForMe } from '../Models/bookkeep-for-me';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  transform(value: any[], type): any[] {
    if (type === 'bfm') {
      return value.sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
    } else if (type === 'accountPlan') {
      return value.sort((a, b) => a.accountNumber - b.accountNumber);
    } else {
      //Unknown how to sort
      return value;
    }
  }
}
