import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DatePipe, NgIf} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {InboxItemWithViewStats} from '@hawco/wrebit-contracts';

@Component({
  selector: 'app-news-list-item',
  templateUrl: './news-list-item.component.html',
  styleUrls: ['./news-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DatePipe,
    MatButtonModule,
    NgIf,
    MatIconModule
  ]
})
export class NewsListItemComponent implements OnInit {
  @Input() item: InboxItemWithViewStats;
  @Output() selected: EventEmitter<InboxItemWithViewStats> = new EventEmitter<InboxItemWithViewStats>();
  @Output() deleted: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit(): void {
  }

  onDelete() {
    this.deleted.emit(this.item.id);
  }

}
