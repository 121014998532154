import { Component, OnInit } from '@angular/core';
import { SearchService } from 'src/app/Services/search.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthenticationService } from 'src/app/Services/auth.service';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss'],
  providers: [SearchService],
})
export class SearchPageComponent implements OnInit {
  value = '';
  results: Object;
  companies = [];
  resultsCount: number = 0;
  searchTerm$ = new Subject<string>();
  excludeCompanyIds: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(
    [],
  );
  availableCompanies: boolean = true;
  hasLoaded: boolean = false;
  noCompaniesText: string = '';

  constructor(
    private searchService: SearchService,
    private auth: AuthenticationService,
  ) {
    this.searchService
      .searchCompanies(
        this.searchTerm$,
        '10',
        undefined,
        this.excludeCompanyIds,
      )
      .subscribe((results: any) => {
        this.results = results;
        this.companies = results.companies;
        this.resultsCount = results.count;
        if (results.count > 0) {
          this.availableCompanies = true;
        }
        this.hasLoaded = true;
      });
  }

  ngOnInit(): void {
    if (!this.auth.isAdmin()) {
      this.initSearch();
    } else {
      this.hasLoaded = true;
    }
  }

  initSearch(): void {
    this.searchService
      .searchCompanies(
        new BehaviorSubject<string>(''),
        '10',
        undefined,
        this.excludeCompanyIds,
      )
      .subscribe(
        (results: any) => {
          this.results = results;
          this.companies = results.companies;
          this.resultsCount = results.count;
          if (results.count === 0) {
            this.availableCompanies = false;
            this.noCompaniesText = 'Inga företag tillgängliga';
          }
          this.hasLoaded = true;
        },
        (error) => {
          this.availableCompanies = false;
          this.noCompaniesText = 'Något gick fel! Kunde inte hämta företag';
          console.log(error.error);
          this.hasLoaded = true;
        },
      );
  }
}
