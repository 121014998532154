import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BackButtonComponent } from 'src/app/Components/back-button/back-button.component';
import { AuthenticationService } from 'src/app/Services/auth.service';

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss'],
})
export class ForgotPasswordPageComponent implements OnInit {
  form: UntypedFormGroup;
  submitted: boolean = false;
  error: string = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private auth: AuthenticationService,
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
    });
    this.form.setValue({ email: localStorage.getItem('forgotPasswordEmail') });
  }

  get formControls() {
    return this.form.controls;
  }

  get formValues() {
    return this.form.value;
  }

  requestResetLink() {
    this.submitted = true;
    let confirmation = JSON.stringify({
      type: 'sentResetEmail',
      email: this.formControls.email.value,
    });
    localStorage.setItem('confirmationObject', confirmation);
    this.auth.requestPasswordReset(this.formControls.email.value).subscribe(
      (resp) => {
        this.router.navigateByUrl('/confirmation');
      },
      (error) => {
        console.log(error.error);
        if (error.error === 'No email provided') {
          this.error = 'Fyll i din mailadress!';
        } else if (error.error === 'No such user') {
          this.error = 'Det finns inget konto för den angivna mailadressen';
        }
        else {
          this.error = 'Något gick fel! Kunde inte återställa lösenord.';
        }

      },
    );
  }

  toHomepage(): void {
    this.router.navigateByUrl('/');
  }
}
