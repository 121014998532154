import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Company } from 'src/app/Models/company';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss'],
})
export class CompanyInfoComponent implements OnInit, OnChanges {
  @Input() company: Company;
  financialYear: any;
  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.getCurrentFinancialYear();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.company) {
      this.getCurrentFinancialYear();
    }
  }

  getCurrentFinancialYear() {
    if (this.company) {
      this.api
        .getFinancialYears(this.company.id)
        .subscribe((financialYears: Array<any>) => {
          financialYears.forEach((financialYear) => {
            console.log(financialYear);
            const from = new Date(financialYear.start);
            const to = new Date(financialYear.end);
            const today = new Date();
            if (
              today.getTime() > from.getTime() &&
              today.getTime() < to.getTime()
            ) {
              this.financialYear = financialYear;
            }
          });
        });
    }
  }
}
