<div
  class="item-container"
  (click)="companyClicked()"
  [ngClass]="{ 'item-container-hover': isHovered || expanded }"
  (mouseenter)="setHovered(true)"
  (mouseleave)="setHovered(false)"
>
  <div class="information-container">
    <div class="header-row">
      <h4 class="header-primary">{{ company.name || '(Namn saknas)' }}</h4>
      <h4 class="secondary-info">{{ company.orgNr || '(Org.nr saknas)' }}</h4>
    </div>
    <div class="expand" [ngClass]="{ expanded: expanded }">
      <div class="extra-info">
        <div class="contact-container">
          <h4 class="primary-info title">Kontakt</h4>
          <h4 class="secondary-info">{{ company.address }}</h4>
          <h4 class="secondary-info">{{ company.postalCode }}</h4>
          <h4 class="secondary-info">{{ company.city }}</h4>
          <h4 class="secondary-info">{{ company.phoneNr }}</h4>
        </div>
        <div class="right-column">
          <div class="integrations-container">
            <h4 class="primary-info title">Integrationer</h4>
            <h4 class="secondary-info" *ngFor="let integration of integrations">
              {{ integration | titlecase }}
            </h4>
          </div>
        </div>
      </div>
      <div class="bottom-container">
        <div class="confirm-button-container">
          <button class="confirm-button" (click)="enterCompany()">
            <div class="button-content">
              <p class="button-text">Öppna företag</p>
            </div>
            <div class="icon-container">
              <img
                src="../../../assets/arrow_right.svg"
                alt="Open company icon"
                class="button-icon"
              />
            </div>
          </button>
        </div>
        <div
          *ngIf="isAdmin"
          class="consultant-assignment-container"
          (click)="$event.stopPropagation()"
        >
          <assignment-search-field
            [label]="'Tilldela rådgivare'"
            [noneSelectedText]="'Ingen rådgivare tilldelad'"
            [selectedItems]="assignedConsultants"
            [availableItems]="availableConsultants"
            (updateSearch)="searchForConsultants($event)"
            (selectItem)="addConsultant($event)"
            (unselectItem)="removeConsultant($event)"
          >
          </assignment-search-field>
        </div>
      </div>
    </div>
  </div>
</div>
