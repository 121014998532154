import { Component, Input, OnInit } from '@angular/core';
import { FinancialYear } from 'src/app/Models/accounting-row';
import { FileSaverService } from 'ngx-filesaver';
import { Company } from 'src/app/Models/company';
import * as moment from 'moment';
import { ApiService } from 'src/app/Services/api.service';

@Component({
  selector: 'sie-export',
  templateUrl: './sie-export.component.html',
  styleUrls: ['./sie-export.component.scss'],
})
export class SieExportComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() selectedFinancialYear: FinancialYear;
  @Input() company: Company;
  @Input() token: string;

  constructor(
    private api: ApiService,
    private fileSaverService: FileSaverService
  ) {}

  ngOnInit(): void {}
  sieExport(): void {

    const fileName: string = `${
      this.company.name ? this.company.name : 'unknown'
    }_${moment(this.selectedFinancialYear.start).format('YYYY-MM-DD')}_${moment(
      this.selectedFinancialYear.end
    ).format('YYYY-MM-DD')}.se`;
    const fileType = this.fileSaverService.genType(fileName);
    this.api
      .getSieData(this.company.id, this.selectedFinancialYear.id, this.token)
      .subscribe((data) => {
        this.fileSaverService.save(data, fileName);
      });
  }
}
