import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/Services/auth.service';

@Component({
  selector: 'back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {
  isMobile: boolean;
  constructor(private auth: AuthenticationService) {
    this.isMobile = this.auth.mobilCheck();
  }

  ngOnInit(): void {}
}
