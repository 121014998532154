import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ApiService } from 'src/app/Services/api.service';
import * as moment from 'moment';
import * as fuzzysort from 'fuzzysort';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { BookkeepForMe } from 'src/app/Models/bookkeep-for-me';
import { ChatService } from 'src/app/Services/chat.service';
import { Chat } from 'src/app/Models/chat';
import { Advice } from 'src/app/Models/advice';

export enum supportTypes {
  All = 0,
  BookkeepForMe = 1,
  Phone = 2,
  Mail = 3,
  Chat = 4,
}

@Component({
  selector: 'app-history-page',
  templateUrl: './history-page.component.html',
  styleUrls: ['./history-page.component.scss'],
})
export class HistoryPageComponent implements OnInit {
  loading = true;
  searchValue = '';
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });
  allEntries: Array<any> = [];
  unhandledEntries: Array<any> = [];
  filteredEntries: Array<any> = [];
  typeFilteredEntries: Array<any> = [];
  dateFilteredEntries: Array<any> = [];
  searchFilteredEntries: Array<any> = [];
  bfmEntries = [];
  chatEntries = [];

  activeFilterTypes: Array<number> = [];

  constructor(private api: ApiService, private chatService: ChatService) {
  }

  ngOnInit(): void {
    this.loading = true;
    // assign bfm
    const promiseOfBFM = new Promise<void>((resolve, reject) => {
      this.api
        .getHandledBookkeepForMe()
        .subscribe((bfmEntriesByCompany: BookkeepForMe[][]) => {
          bfmEntriesByCompany.forEach((bfms) => {
            bfms.forEach((bfm) => {
              if (typeof bfm.duration === 'number') {
                this.bfmEntries.push(bfm);
                this.allEntries.push(bfm);
              } else {
                this.unhandledEntries.push(bfm);
              }
            });
          });
          resolve();
        });
    });
    const promiseOfHistory = new Promise<void>((resolve, reject) => {
      // assign all advices
      this.api.getAllHistory().subscribe((history: Array<any>) => {
        history.forEach((entry: any) => {
          if (typeof entry.duration === 'number') {
            this.allEntries.push(entry);
          } else {
            this.unhandledEntries.push(entry);
          }
        });
        resolve();
      });
    });

    const promiseOfChat = new Promise<void>((resolve, reject) => {
      // Assign all chats
      this.chatService.getFinishedChats().subscribe((chats: Chat[]) => {
        chats.forEach((chat) => {
          if (typeof chat.duration === 'number') {
            this.chatEntries.push(chat);
            this.allEntries.push(chat);
          } else {
            this.unhandledEntries.push(chat);
          }
        });
        resolve();
      });
    });

    Promise.all([promiseOfBFM, promiseOfChat, promiseOfHistory]).then(() => {
      this.loading = false;
      this.filteredEntries = this.allEntries;
      this.dateFilteredEntries = this.allEntries;
      this.typeFilteredEntries = this.allEntries;
      this.searchFilteredEntries = this.allEntries;
    });
    // Update datefiltered entries
    this.range.valueChanges.subscribe((value) => {
      const startDate = new Date(value.start);
      const endDate = new Date(value.end);
      this.dateFilteredEntries = [];

      if (value.start) {
        // handle timeslots
        this.allEntries.forEach((entry) => {
          let entryDate: Date;

          switch (entry.type) {
            case 'phone':
              entryDate = new Date((entry as Advice).timeslot.start);
              break;

            case 'email':
              entryDate = new Date((entry as any).issued);
              break;

            case 'bookkeep-for-me':
              entryDate = new Date((entry as BookkeepForMe).handled);
              break;

            case 'chat':
              entryDate = new Date((entry as Chat).chatFinished);
              break;

            default:
              break;
          }
          if (!value.end && moment(entryDate).isAfter(startDate)) {
            this.dateFilteredEntries.push(entry);
          } else if (
            startDate &&
            endDate &&
            moment(entryDate).isBetween(startDate, endDate, 'day', '[]')
          ) {
            this.dateFilteredEntries.push(entry);
          }
        });
      } else {
        this.dateFilteredEntries = this.allEntries;
      }

      this.applyFilters();
    });
  }

  clickedFilterType(type): void {
    switch (type) {
      case supportTypes.All:
        this.activeFilterTypes = [];
        break;
      case supportTypes.BookkeepForMe:
        if (this.activeFilterTypes.indexOf(supportTypes.BookkeepForMe) >= 0) {
          this.activeFilterTypes.splice(
            this.activeFilterTypes.indexOf(supportTypes.BookkeepForMe),
            1,
          );
        } else {
          this.activeFilterTypes.push(supportTypes.BookkeepForMe);
        }
        break;
      case supportTypes.Mail:
        if (this.activeFilterTypes.indexOf(supportTypes.Mail) >= 0) {
          this.activeFilterTypes.splice(
            this.activeFilterTypes.indexOf(supportTypes.Mail),
            1,
          );
        } else {
          this.activeFilterTypes.push(supportTypes.Mail);
        }
        break;
      case supportTypes.Phone:
        if (this.activeFilterTypes.indexOf(supportTypes.Phone) >= 0) {
          this.activeFilterTypes.splice(
            this.activeFilterTypes.indexOf(supportTypes.Phone),
            1,
          );
        } else {
          this.activeFilterTypes.push(supportTypes.Phone);
        }
        break;
      case supportTypes.Chat:
        if (this.activeFilterTypes.indexOf(supportTypes.Chat) >= 0) {
          this.activeFilterTypes.splice(
            this.activeFilterTypes.indexOf(supportTypes.Chat),
            1,
          );
        } else {
          this.activeFilterTypes.push(supportTypes.Chat);
        }
        break;

      default:
        break;
    }
    this.filterTypes();
  }

  /**
   * Updates the typeFiltered entries to include all the entries of correct types
   */
  private filterTypes(): void {
    this.typeFilteredEntries = [];
    if (this.activeFilterTypes.length === 0) {
      this.typeFilteredEntries = this.allEntries;
    } else {
      if (this.activeFilterTypes.includes(supportTypes.BookkeepForMe)) {
        this.typeFilteredEntries = this.typeFilteredEntries.concat(
          this.bfmEntries,
        );
      }
      if (this.activeFilterTypes.includes(supportTypes.Chat)) {
        this.typeFilteredEntries = this.typeFilteredEntries.concat(
          this.chatEntries,
        );
      }
      this.allEntries.forEach((entry) => {
        switch (entry.type) {
          case 'phone':
            if (this.activeFilterTypes.includes(supportTypes.Phone)) {
              this.typeFilteredEntries.push(entry);
            }
            break;
          case 'email':
            if (this.activeFilterTypes.includes(supportTypes.Mail)) {
              this.typeFilteredEntries.push(entry);
            }
            break;

          default:
            break;
        }
      });
    }
    this.applyFilters();
  }

  isSameCompany(index: number): boolean {
    if (index === 0) {
      return false;
    }
    const companyId = this.filteredEntries[index].company
      ? this.filteredEntries[index].company.id
      : this.filteredEntries[index].companyId
        ? this.filteredEntries[index].companyId
        : this.filteredEntries[index].customer
          ? this.filteredEntries[index].customer.id
          : null;
    const prevCompanyId = this.filteredEntries[index - 1].company
      ? this.filteredEntries[index - 1].company.id
      : this.filteredEntries[index - 1].companyId
        ? this.filteredEntries[index - 1].companyId
        : this.filteredEntries[index - 1].customer
          ? this.filteredEntries[index - 1].customer.id
          : null;

    return companyId === prevCompanyId && companyId !== null;
  }

  private findIntersection(dataSets: Array<Array<any>>): Array<any> {
    const result = [];
    let lists;
    if (dataSets.length === 1) {
      return dataSets[0];
    } else {
      lists = dataSets;
    }

    for (const currentList of lists) {
      for (const currentValue of currentList) {
        if (result.indexOf(currentValue) === -1) {
          if (
            lists.filter(obj => obj.indexOf(currentValue) === -1).length === 0
          ) {
            result.push(currentValue);
          }
        }
      }
    }
    return result;
  }

  search(searchTerm: string): void {
    if (searchTerm.length === 0) {
      this.filteredEntries = this.allEntries;
    } else {
      const resultsAdvice = fuzzysort.go(searchTerm, this.allEntries, {
        keys: ['company.name', 'companyName', 'customer.name'],
        limit: 100,
        threshold: -100,
      });
      this.filteredEntries = [];
      this.activeFilterTypes = [];
      this.range.value.start = null;
      this.range.value.end = null;
      console.log(resultsAdvice);
      resultsAdvice.forEach((result) => {
        this.filteredEntries.push(result.obj);
      });
    }
  }

  private applyFilters(): void {
    this.searchValue = '';
    this.filteredEntries = this.findIntersection([
      this.typeFilteredEntries,
      this.dateFilteredEntries,
    ]);
    console.log(this.filteredEntries);
  }

  /**
   * Sorts advices and bfm entries based on companyId
   * @param  issues An array of advices and bfm entries
   * @returns groupedCompanies
   */
  private groupCompanies(issues: Array<any>): any[][] {
    console.log(issues);
    if (issues.length === 0) {
      return [];
    }
    // loop through input and create a nested array of results.
    // each inner array only contains results with the same company id.
    const formatted: any[][] = [[]];
    let outerCounter = 0;
    let innerCounter = 0;
    let company = issues[0].company
      ? issues[0].company.id
      : issues[0].companyId // Depending on type of issue
        ? issues[0].companyId
        : issues[0].customer
          ? issues[0].customer.id
          : null; // Depending on type of issue
    console.log('company');
    console.log(company);
    issues.forEach((issue) => {
      // Issue is either BFM entry or advice or chat
      if (
        (issue.company && issue.company.id === company) ||
        (issue.companyId && issue.companyId === company) ||
        (issue.customer && issue.customer.id === company)
      ) {
        console.log(
          (issue.company && issue.company.id === company) ||
          (issue.companyId && issue.companyId === company) ||
          (issue.customer && issue.customer.id === company),
        );
        formatted[outerCounter][innerCounter] = issue;
        innerCounter++;
      } else {
        innerCounter = 0;
        outerCounter++;
        company = issue.company ? issue.company.id : issue.companyId;
        formatted[outerCounter] = [];
        formatted[outerCounter][innerCounter] = issue;
        innerCounter++;
      }
    });
    console.log(formatted);
    return formatted;
  }

  // TODO FOR CHAT
  exportCSV() {
    const data = [];
    const formatted: any[][] = this.groupCompanies(this.filteredEntries);
    console.log(formatted);
    formatted.forEach((entriesByCompany) => {
      console.log(entriesByCompany);
      console.log('entriesByCompany');
      const articles = [];
      entriesByCompany.forEach((entry: any) => {
        console.log(entry);
        articles.push({
          Typ: entry.type
            ? entry.type === 'phone'
              ? 'Telefonsamtal'
              : entry.type === 'bookkeep-for-me'
                ? 'Bokför-åt-mig-tjänst'
                : entry.type === 'email'
                  ? 'Emailhjälp'
                  : 'Chatt'
            : 'Okänd Artikel',
          Tid:
            entry.type !== 'bookkeep-for-me' ? `${entry.duration} min` : '1 st',
          Pris:
            typeof entry.price === 'string' || typeof entry.price === 'number'
              ? `${entry.price} kr`
              : 'Okänt pris',
          Datum: entry.timeslot
            ? moment(entry.timeslot.start).format('YYYY-MM-DD')
            : entry.chatFinished
              ? moment(entry.chatFinished).format('YYYY-MM-DD')
              : moment(entry.issued ? entry.issued : entry.createdAt).format(
                'YYYY-MM-DD',
              ),
        });
      });
      console.warn('articles');
      console.warn(articles);
      try {
        data.push({
          Företagsnamn: entriesByCompany[0].company
            ? entriesByCompany[0].company?.name
            : entriesByCompany[0].companyName
              ? entriesByCompany[0].companyName
              : entriesByCompany[0].customer?.name,
          OrgNr: entriesByCompany[0].orgNr
            ? (entriesByCompany[0].orgNr as string)
            : entriesByCompany[0].company
              ? entriesByCompany[0].company?.orgNr
                ? (entriesByCompany[0].company?.orgNr as string)
                : entriesByCompany[0].orgNr
                  ? (entriesByCompany[0].orgNr as string)
                  : entriesByCompany[0].customer
                    ? (entriesByCompany[0].customer?.orgNr as string)
                    : 'Okänt Orgnr'
              : entriesByCompany[0].orgNr
                ? (entriesByCompany[0].orgNr as string)
                : entriesByCompany[0].customer
                  ? (entriesByCompany[0].customer?.orgNr as string)
                  : 'Okänt Orgnr',
          Email: entriesByCompany[0].email
            ? entriesByCompany[0].email
            : entriesByCompany[0].companyEmail
              ? entriesByCompany[0].companyEmail
              : entriesByCompany[0].customer
                ? entriesByCompany[0].customer?.email
                : 'Okänd Email',
          Artiklar: JSON.stringify(articles),
        });
      } catch (e) {
        console.error(e, entriesByCompany[0]);
      }
    });

    const csv = new ngxCsv(data, 'faktureringsunderlag', {
      fieldSeparator: ';',
      showLabels: true,
      headers: [
        'Företagsnamn',
        'Organisationsnummer',
        'Emailadress',
        'Artiklar',
      ],
    });
  }

  getOrgNrFromSettings(): string {
    return 'Okänt orgNr';
  }
}
