import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from './Services/auth.service';
import {environment} from '../environments/environment';
import {Observable} from 'rxjs';
import packageJson from '../../package.json'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'admin-portal-app';
  isLoggedIn$: Observable<boolean>;

  constructor(private auth: AuthenticationService) {
    this.isLoggedIn$ = this.auth.isLoggedIn$;
    console.log('production', environment.production);
    auth.autoLogin();
  }

  ngOnInit(): void {
    console.log(packageJson.version);

  }
}
