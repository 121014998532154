<div #zoomContainer class="ngxImageZoomContainer" [style.width.px]="300">
  <a href="{{ fullImage }}" download="image.jpg">
    <img
      #imageThumbnail
      class="ngxImageZoomThumbnail"
      [src]="thumbImage"
      (load)="onThumbImageLoaded()"
      [style.width.px]="300"
  /></a>

  <div
    [ngClass]="{
      ngxImageZoomFullContainer: true,
      ngxImageZoomLensEnabled: this.enableLens
    }"
    [style.display]="this.display"
    [style.top.px]="this.lensTop"
    [style.left.px]="this.lensLeft"
    [style.width.px]="this.lensWidth"
    [style.height.px]="this.lensHeight"
    [style.border-radius.px]="this.lensBorderRadius"
  >
    <a href="{{ fullImage }}" download="image.jpg">
      <img
        #fullSizeImage
        class="ngxImageZoomFull"
        [src]="fullImage"
        (load)="onFullImageLoaded()"
        [style.display]="this.display"
        [style.top.px]="this.fullImageTop"
        [style.left.px]="this.fullImageLeft"
        [style.width.px]="this.magnifiedWidth"
        [style.height.px]="this.magnifiedHeight"
    /></a>
  </div>
</div>
