import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { ChatService } from 'src/app/Services/chat.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { Advice } from 'src/app/Models/advice';
import { BookkeepForMe } from 'src/app/Models/bookkeep-for-me';
import { Chat } from 'src/app/Models/chat';

@Component({
  selector: 'history-list',
  templateUrl: './history-list.component.html',
  styleUrls: ['./history-list.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          transform: 'scaleY(1)',
        }),
      ),
      state(
        'closed',
        style({
          height: '0px',
          transform: 'scaleY(0)',
        }),
      ),
      transition('open => closed', [animate('100ms ease-in-out')]),
      transition('closed => open', [animate('100ms ease-in-out')]),
    ]),
  ],
})
export class HistoryListComponent implements OnInit {
  open = false;
  items: Array<any> = [];
  @Input() companyId: string;
  constructor(private api: ApiService, private chatAPI: ChatService) {}

  ngOnInit(): void {
    console.log('HISTORY LIST');
    console.log(this.companyId);

    const p1 = new Promise<void>((resolve) => {
      this.api.getCompanyHistory(this.companyId).subscribe((history) => {
        console.log(history);
        this.items.push(...history);
        resolve();
      });
    });
    const p2 = new Promise<void>((resolve) => {
      this.api.getBFMForCompany(this.companyId).subscribe((history) => {
        console.log(history);
        this.items.push(...history);
        resolve();
      });
    });
    const p3 = new Promise<void>((resolve) => {
      this.chatAPI.getChatsForCompany(this.companyId).subscribe((history) => {
        console.log(history);
        this.items.push(...history);
        resolve();
      });
    });
    Promise.all([p1, p2, p3]).then(() => {
      console.log('PROMISES RESOLVED');
      console.log(this.items);
    });
  }
  toggleOpen(): void {
    this.open = !this.open;
  }
  getDate(item: any) {
    switch (item.type) {
      case 'phone':
        return new Date((item as Advice).timeslot.start);
        break;

      case 'email':
        return new Date((item as any).issued);
        break;

      case 'bookkeep-for-me':
        return new Date((item as BookkeepForMe).handled);
        break;

      case 'chat':
        return new Date((item as Chat).chatFinished);
        break;

      default:
        return 'Okänt datum';
        break;
    }
  }
  setIconSource(item: any): string {
    // return correct source to icon

    switch (item.type) {
      case 'phone':
        return '../../../assets/phone-icon.svg';
      case 'email':
        return '../../../assets/mail-icon.svg';
      case 'chat':
        return '../../../assets/chat-bubble.svg';
      case 'bookkeep-for-me':
        return '../../../assets/receipt-icon.svg';

      default:
        return '';
    }
  }
}
