// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  bookkeepUrl: 'https://api.test.wrebit.helops.net',
  adminUrl: 'https://api.test.wrebit.helops.net',
  socketUrl: 'https://api.test.wrebit.helops.net',
  apiUrl: 'https://api.test.wrebit.helops.net',
  villainUrl: 'https://villain.test.wrebit.helops.net',
  baseAccountPlanId: '09c48fc1-4e62-49ff-9e96-febe5e3c454a',
  TOKEN_NAME: 'admin_jwt_token',
  ACTIVE_USER: 'admin_active_user',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
