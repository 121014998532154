<div class="list-container">
  <button class="title" (click)="toggleOpen()">
    {{ open ? 'STÄNG HISTORISKA ÄRENDEN' : 'SE HISTORISKA ÄRENDEN' }}
  </button>

  <div class="items-container" [@openClose]="open ? 'open' : 'closed'">
    <div class="item" *ngFor="let item of items">
      <div class="icon-container">
        <img [src]="setIconSource(item)" alt="" class="icon" />
      </div>
      <div class="info">
        <div class="date">{{ getDate(item) | date: 'y-MM-d' }}</div>
        <div class="titel">Rådgivning</div>
        <div class="sub"></div>
      </div>
    </div>
  </div>
</div>
