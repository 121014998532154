import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {ConsultantDetailsModalComponent} from 'src/app/Modals/consultant-details-modal/consultant-details-modal.component';
import {Consultant} from 'src/app/Models/consultant';
import {User} from 'src/app/Models/user';
import {SearchService} from 'src/app/Services/search.service';

@Component({
  selector: 'consultant-handling',
  templateUrl: './consultant-handling.component.html',
  styleUrls: ['./consultant-handling.component.scss'],
})
export class ConsultantHandlingComponent implements OnInit {
  results: object;
  consultants: Consultant[];
  searchTerm$: Subject<string>;

  hasLoaded = false;

  constructor(public dialog: MatDialog, private searchService: SearchService) {
  }

  ngOnInit(): void {
    this.searchTerm$ = new Subject<string>();

    this.searchService
      .searchConsultants(this.searchTerm$, '1000', undefined, undefined)
      .subscribe((results: any) => {
        this.results = results;
        this.consultants = results.consultants;
        this.hasLoaded = true;
      });
    this.searchTerm$.next('');
  }

  openDialog(consultant?: User): void {
    const dialogRef = this.dialog.open(ConsultantDetailsModalComponent, {
      panelClass: 'custom-dialog-no-padding',
      data: consultant ? consultant : null,
    });
    dialogRef.componentInstance.refreshEvent.subscribe((data) => {
      this.hasLoaded = false;
      this.searchTerm$.next('');
    });
  }
}
