<div class="surrounding-container">
  <div class="back-button-container">
    <back-button (click)="toHomepage()"></back-button>
  </div>

  <img
    src="../../../assets/WREBIT_Logotyp.svg"
    alt="Wrebit Logo Green"
    class="logo"
  />

  <h1 class="sublogo">Glömt<br />lösenord</h1>
  <form
    [formGroup]="form"
    (ngSubmit)="requestResetLink()"
    class="forgot-password-form"
    id="password-form"
  >
    <div class="form-group">
      <input
        type="email"
        formControlName="email"
        class="form-control"
        required
        [ngClass]="{
          'is-invalid':
            formControls.email.errors &&
            (formControls.email.dirty || formControls.email.touched)
        }"
        placeholder="Mail"
      />
    </div>

    <div
      class="error-feedback"
      *ngIf="
        formControls.email.invalid &&
        (formControls.email.dirty || formControls.email.touched)
      "
    >
      <p *ngIf="formControls.email.errors.required">Fyll i din mailadress!</p>
    </div>
    <div class="error-feedback">
      <p *ngIf="submitted && error">{{ error }}</p>
    </div>

    <div class="form-group">
      <button
        [disabled]="formControls.email.errors"
        type="submit"
        class="submit-btn"
      >
        BEGÄR NYTT LÖSENORD
      </button>
    </div>
  </form>
</div>
