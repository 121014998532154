import {Component, OnInit, OnDestroy} from '@angular/core';
import {ChatService} from 'src/app/Services/chat.service';
import {SafeUrl, DomSanitizer} from '@angular/platform-browser';
import {Chat, ChatMessage, Tag} from 'src/app/Models/chat';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from 'src/app/Services/auth.service';
import { MatDialog} from '@angular/material/dialog';
import {HandleIssueModalComponent} from 'src/app/Modals/handle-issue-modal/handle-issue-modal.component';
import {Company} from 'src/app/Models/company';
import {ApiService} from 'src/app/Services/api.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {ChatOptionsComponent} from 'src/app/Modals/chat-options/chat-options.component';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-company-chat',
  templateUrl: './company-chat.component.html',
  styleUrls: ['./company-chat.component.scss'],
})
export class CompanyChatComponent implements OnInit, OnDestroy {
  chat: Chat = new Chat();
  message = '';
  url: SafeUrl;
  company: Company;
  financialYear: any;
  sending = false;
  isHandling = false;
  innerHeight: number;
  previouslyUsedTags: string[];
  tags: Array<Tag> = [
    new Tag('Bokslut', false),
    new Tag('Moms', false),
    new Tag('Appsupport', false),
  ];
  connected = false;
  warnBeforeLeave = true;
  shouldReconnect = true;
  isMobile: boolean;
  finishedLoading = false;
  quitChatSubscription: Subscription;
  messageSubscription: Subscription;
  sendConfirmationSubscription: Subscription;

  constructor(
    private chatService: ChatService,
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthenticationService,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
  ) {
  }

  isLoading() {
    if (this.isMobile && this.finishedLoading) {
      document.getElementById('mobile-chat-page').ontouchmove = (event) => {
        event.preventDefault();
      };
      document.body.style.overflow = 'hidden';
      this.innerHeight = window.innerHeight;
      document.getElementsByTagName(
        'body',
      )[0].style.height = `${this.innerHeight}px`;
    }
    // THIS IS THE CAUSE OF EXPRESSION HAS CHANGED AFTER IT WAS CHECKED ERROR
    this.finishedLoading = this.chat && this.company ? true : false;
    return this.chat && this.company;
  }

  ngOnInit(): void {
    ////////////////////////////
    // CHECK IF MOBILE OR DESKTOP
    ///////////////////////////
    this.isMobile = this.auth.mobilCheck();
    if (this.isMobile) {
      this.sendConfirmationSubscription = this.chatService.confirmationReq.subscribe(
        (res) => {
          if (res === true) {
            this.sendConfirmation();
          }
        },
      );
      this.quitChatSubscription = this.chatService.quitChat.subscribe((res) => {
        if (res) {
          if (!this.isHandling) {
            this.isHandling = true;
            this.endChat();
          }
        }
      });
      window.onresize = () => {
        this.innerHeight = window.innerHeight;
      };
    }

    ////////////////////////////
    // GET PREVIOUSLY USED TAGS FOR AUTOCOMPLETE
    ///////////////////////////
    this.chatService.getTags().subscribe((tags: Array<any>) => {
      console.log(tags);
      this.previouslyUsedTags = tags.map((tagObject) => {
        return tagObject.text as string;
      }) as Array<string>;
      console.log('TAGS');
      console.log(this.previouslyUsedTags);
    });

    ////////////////////////////
    // GET INFO ABOUT THE CUSTOMER
    ///////////////////////////
    const promiseOfCompany = new Promise<void>((resolve, reject) => {
      this.api
        .getCompany(this.route.snapshot.queryParamMap.get('company'))
        .subscribe(
          (company: Company) => {
            this.company = company;
            this.setCurrentFinancialYear();
            resolve();
          },
          () => {
            reject();
          },
        );
    });

    ////////////////////////////
    // GET INFO ABOUT THE CHAT
    ///////////////////////////
    const promiseOfChat = new Promise<void>((resolve, reject) => {
      this.chatService
        .getChat(this.route.snapshot.queryParamMap.get('chat'))
        .subscribe(
          (chat: any) => {
            ////////////////////////////
            // Chat:{conversation, messages}
            ////////////////////////////
            console.log(chat);
            this.chat = chat.conversation;
            this.chat.messages = chat.messages;
            console.log('CHAT TAGS');
            console.log(chat.conversation.tags);
            chat.conversation.tags.forEach((activeTag) => {
              const tagToActivate = this.tags.find((tag) => {
                return tag.name === activeTag.text;
              });
              if (tagToActivate) {
                tagToActivate.active = true;
              } else {
                this.tags.push(new Tag(activeTag.text, true));
              }
            });
            console.log(this.tags);
            resolve();
            console.log(this.chat);
          },
          () => {
            reject();
          },
        );
    });

    ////////////////////////////
    // WAIT FOR COMPANY AND CHAT DATA BEFORE STARTING
    ///////////////////////////

    Promise.all([promiseOfChat, promiseOfCompany]).then(() => {
      ////////////////////////////
      // START THE CHAT
      ///////////////////////////
      this.chatService.initChat(this.chat.id);
      ////////////////////////////
      // IF CHAT FOR SOME REASON DISCONNECTS
      ///////////////////////////
      this.chatService.socket.on('disconnect', () => {
        console.warn('Socket disconnected');
        this.chatService.socket.close();
        if (this.shouldReconnect) {
          this.chatService.initChat(this.chat.id);
        }
      });
      ////////////////////////////
      // GET ALL EXISTING MESSAGES AND SCROLL IF NECESSARY
      ///////////////////////////
      this.chatService
        .getMessages(this.chat.id)
        .subscribe((messages: ChatMessage[]) => {
          this.chat.messages = messages;
          setTimeout(() => {
            const chatWindow: HTMLElement = document.getElementById(
              this.isMobile
                ? 'mobile-chat-scroll-window'
                : 'chat-scroll-window',
            );
            this.scrollToBottom(chatWindow, 600);
          }, 1000);
        });

      ///////////////////////
      // SUBSCRIBE TO MESSAGES
      //////////////////////
      this.messageSubscription = this.chatService.messageHandler.subscribe(
        (messages: any) => {
          this.chat.messages = messages;
          setTimeout(() => {
            const chatWindow = document.getElementById(
              this.isMobile
                ? 'mobile-chat-scroll-window'
                : 'chat-scroll-window',
            );
            this.scrollToBottom(chatWindow, 600);
            console.log(messages);
          }, 200);
        },
      );

      this.chatService.updateHandler.subscribe((chat: Chat) => {
        if (this.isMobile) {
          this.chatService.confirmationReq.emit(chat.status);
        }
        this.chat.status = chat.status;
      });

      ///////////////////////
      // CONNECT TO WEB APP
      //////////////////////

      this.auth.getCompanyToken(this.company.id).subscribe((companyToken) => {
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
          `${environment.villainUrl}/login?villainToken=${companyToken.signed}`,
        );
      });
    });
  }

  ngOnDestroy(): void {
    ////////////////////////////
    // IF USER LEAVES THE CHAT
    ///////////////////////////
    if (this.quitChatSubscription) {
      this.quitChatSubscription.unsubscribe();
    }
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }

    this.shouldReconnect = false;
    this.chatService.disconnectFromChat();
  }

  leave() {
    ////////////////////////////
    // BACK BUTTON PRESSED
    ///////////////////////////
    this.router.navigate(['chat']);
  }

  isConsultant(): boolean {
    if (this.chat.consultant) {
      return this.chat.consultant.id === this.auth.getUserId();
    }

    return false;
  }

  endChat(): void {
    console.log('open dialog');
    ////////////////////////////
    // OPEN DIALOG TO HANDLE CHAT AND SUBSCRIBE TO MODAL EVENTS
    ///////////////////////////
    const dialogRef = this.dialog.open(HandleIssueModalComponent, {
      data: {type: this.chat.type},
    });
    dialogRef.componentInstance.exit.subscribe((event) => {
      dialogRef.close();
    });
    dialogRef.componentInstance.send.subscribe((event) => {
      // update chat to be finished
      this.chatService
        .updateChat(
          this.chat.id,
          null,
          event.price,
          event.duration,
          this.tags
            .filter((tag) => {
              return tag.active;
            })
            .map((tag) => {
              return tag.name;
            }),
          'finished',
        )
        .subscribe(
          (updatedChat) => {
            console.log(updatedChat);
            this.refreshChat(
              updatedChat.status,
              updatedChat.chatFinished,
              updatedChat.consultant,
              updatedChat.consultantConnected,
              updatedChat.price,
              updatedChat.duration,
            );
            this.chatService
              .sendMessage(
                'Chatten har avslutats;red-dot',
                this.chat.id,
                this.chat.messages.length,
                'info',
              )
              .subscribe(() => {
              });
            dialogRef.close();
            this.warnBeforeLeave = false;
          },
          (error) => {
            alert(error);
            console.error(error);
          },
        );
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('Chat handled');
      this.isHandling = false;
    });
  }

  addTag(tagName: string): void {
    const newTag = new Tag(tagName, true);
    this.tags.push(newTag);
    this.chatService
      .updateChat(
        this.chat.id,
        null,
        null,
        null,
        this.tags
          .filter((tag) => {
            return tag.active;
          })
          .map((tag) => {
            return tag.name;
          }),
      )
      .subscribe((updatedChat) => {
        console.log(updatedChat);
        this.refreshChat(
          updatedChat.status,
          updatedChat.chatFinished,
          updatedChat.consultant,
          updatedChat.consultantConnected,
          updatedChat.price,
          updatedChat.duration,
        );
        console.log('Tags updated');
      });
    // TODO Send tag to BE
  }

  sendMessage() {
    this.sending = true;
    this.chatService
      .sendMessage(this.message, this.chat.id, this.chat.messages.length)
      .subscribe((res) => {
        console.log(res);
        this.sending = false;
        this.message = '';
      });
  }

  showOptions() {
    this.bottomSheet.open(ChatOptionsComponent, {
      panelClass: 'options-panel',
      data: {chatStatus: this.chat.status},
    });
  }

  toggleTag(chosenTag: Tag) {
    chosenTag.active = !chosenTag.active;
    this.chatService
      .updateChat(
        this.chat.id,
        null,
        null,
        null,
        this.tags
          .filter((tag) => {
            return tag.active;
          })
          .map((tag) => {
            return tag.name;
          }),
      )
      .subscribe((updatedChat) => {
        console.log(updatedChat);
        this.refreshChat(
          updatedChat.status,
          updatedChat.chatFinished,
          updatedChat.consultant,
          updatedChat.consultantConnected,
          updatedChat.price,
          updatedChat.duration,
        );
        console.log('Tags updated');
      });
  }

  sendConfirmation() {
    if (
      this.chat.status === 'pending' ||
      this.chat.status === 'confirmed' ||
      this.chat.status === 'finished' ||
      this.chat.consultant === null
    ) {
    } else {
      this.chatService
        .sendMessage(
          'SHOW CONFIRMATION',
          this.chat.id,
          this.chat.messages.length,
          'prompt',
        )
        .subscribe(() => {
          this.chatService
            .updateChat(this.chat.id, null, null, null, null, 'pending')
            .subscribe(() => {
            });
          this.chatService.confirmationReq.emit('pending');
        });
    }
  }

  assignAdvisor() {
    this.chatService
      .updateChat(this.chat.id, this.auth.getUserId())
      .subscribe((updatedChat) => {
        console.log(updatedChat);
        this.refreshChat(
          updatedChat.status,
          updatedChat.chatFinished,
          updatedChat.consultant,
          updatedChat.consultantConnected,
          updatedChat.price,
          updatedChat.duration,
        );
        console.log('Consultant assigned ');
        this.auth.getUser().subscribe((user) => {
          this.chatService
            .sendMessage(
              `${user.name} har anslutit sig till chatten;green-dot`,
              this.chat.id,
              this.chat.messages.length,
              'info',
            )
            .subscribe((res) => {
              console.log(res);
            });
        });
      });
  }

  connectToChat() {
    this.connected = true;
    this.assignAdvisor();
  }

  scrollToBottom(element, duration) {
    const start = element.scrollTop;
    const change = element.scrollHeight - start;
    let currentTime = 0;
    const increment = 20;
    // t = current time
    // b = start value
    // c = change in value
    // d = duration
    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) {
        return (c / 2) * t * t + b;
      }
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };
    const animateScroll = () => {
      currentTime += increment;
      const val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    console.log('scrolling');
    animateScroll();
  }

  setCurrentFinancialYear() {
    this.api
      .getFinancialYears(this.company.id)
      .subscribe((financialYears: Array<any>) => {
        financialYears.forEach((financialYear) => {
          console.log(financialYear);
          const from = new Date(financialYear.start);
          const to = new Date(financialYear.end);
          const today = new Date();
          if (
            today.getTime() > from.getTime() &&
            today.getTime() < to.getTime()
          ) {
            this.financialYear = {start: from, end: to};
          }
        });
      });
  }

  refreshChat(
    status,
    chatFinished,
    consultantId,
    consultantConnected,
    price,
    duration,
  ): void {
    this.chat.status = status;
    this.chat.chatFinished = chatFinished ? new Date(chatFinished) : null;
    if (consultantId) {
      this.auth.getUser(consultantId).subscribe((user) => {
        this.chat.consultant = user;
      });
    }
    this.chat.consultantConnected = consultantConnected;
    this.chat.price = price;
    this.chat.duration = duration;
  }
}
