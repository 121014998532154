<mat-action-list>
  <button
    mat-list-item
    class="option"
    (click)="sendConfirmationRequest()"
    [disabled]="data.chatStatus !== 'active'"
    [ngClass]="{
      pending: data.chatStatus === 'pending',
      confirmed: data.chatStatus === 'confirmed'
    }"
  >
    {{
      data.chatStatus === "pending"
        ? "UPPGIFTSKONTROLL SKICKAD"
        : data.chatStatus === "confirmed"
        ? "UPPFIFTER BEKRÄFTADE"
        : "SKICKA UPPGIFTSKONTROLL"
    }}
  </button>
</mat-action-list>
