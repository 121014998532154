<div class="item-container">
  <div class="information-container" (click)="onConsultantClick()">
    <div class="name-container">{{ consultant.user.name }}</div>
    <div class="company-container">{{ consultant.companyName }}</div>
    <div class="e-mail-container">{{ consultant.user.email }}</div>
    <div class="phone-container">{{ consultant.user.phoneNr }}</div>
    <div
      *ngIf="consultant.accessedCompanies; else noCompanies"
      class="ass-companies-container"
    >
      {{ consultant.accessedCompanies.length }}
    </div>
  </div>
</div>

<ng-template #noCompanies>
  <div class="ass-companies-container">0</div>
</ng-template>
