import { KeyValue } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {  MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'assignment-search-field',
  templateUrl: './assignment-search-field.component.html',
  styleUrls: ['./assignment-search-field.component.scss'],
})
export class AssignmentSearchFieldComponent implements OnInit {
  /**
   * INPUTS
   * label: descriptive label for the assignment (displayed above search field)
   * noneSelectedText: text to be displayed when no items are selected
   * selectedItems: a map containing selected items (displayed as tags below the search field),
   *                the key being a reference or id, the value a text to be displyed on the tag
   * availableItems: a map containing selected items (displayed in drop down list in search field),
   *                the key being a reference or id, the value a text to be displyed on the tag upon selection
   */
  @Input() label: string;
  @Input() noneSelectedText: string;
  @Input() selectedItems: Map<string, string>;
  @Input() availableItems: Map<string, string>;

  @Output() updateSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectItem: EventEmitter<
    KeyValue<string, string>
  > = new EventEmitter<KeyValue<string, string>>();
  @Output() unselectItem: EventEmitter<string> = new EventEmitter<string>();

  assignItemsControl: UntypedFormControl;

  constructor() {}

  ngOnInit(): void {
    this.assignItemsControl = new UntypedFormControl();
    this.assignItemsControl.setValue('');
    this.assignItemsControl.valueChanges.subscribe((value) =>
      this.updateSearch.emit(value),
    );
  }

  getItemValue(item: KeyValue<string, string>): string {
    return item.value;
  }

  selectCurrentItem(event: MatAutocompleteSelectedEvent) {
    this.selectItem.emit(event.option.value as KeyValue<string, string>);
    //Clears the text input
    this.assignItemsControl.setValue('');
  }

  unselectCurrentItem(item: KeyValue<string, string>): void {
    this.unselectItem.emit(item.key);
  }
}
