import {Component, OnInit} from '@angular/core';
import {NewsPageStore} from './news-page.store';
import {provideComponentStore} from '@ngrx/component-store';
import {Router} from '@angular/router';

@Component({
  selector: 'app-news-page',
  templateUrl: './news-page.component.html',
  styleUrls: ['./news-page.component.scss'],
  providers: [provideComponentStore(NewsPageStore)],
})
export class NewsPageComponent implements OnInit {

  constructor(public store$: NewsPageStore, private router: Router) {
  }

  ngOnInit(): void {
    this.store$.getNews();
  }

  deactivateNews(id: string): void {
    this.store$.deactivateNews(id);
  }

  async createNews() {
    await this.router.navigate(['/news/create']);
  }

}
