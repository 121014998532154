<div class="surrounding-container">

    <img
      src="../../../assets/WREBIT_Logotyp.svg"
      alt="Wrebit Logo Green"
      class="logo"
    />

    <h1 class="sublogo">Nytt<br>lösenord</h1>
    <form [formGroup]="form"
            (ngSubmit)="submit()"
            class="new-password-form"
            id="password-form"> 

        <div class="form-group">
            <input 
                type="password"
                formControlName="password"
                class="form-control"
                required
                [ngClass]="{ 'is-invalid' : formControls.password.errors && (formControls.password.dirty || formControls.password.touched) }"
                placeholder="Lösenord"
                (keyup)="resetSubmitted()"
            />
        </div>

        <div class="form-group">
            <input 
                type="password"
                formControlName="repeatedPassword"
                class="form-control"
                required
                [ngClass]="{ 'is-invalid' : formControls.repeatedPassword.errors && (formControls.repeatedPassword.dirty || formControls.repeatedPassword.touched)}"
                placeholder="Upprepa lösenord"
            />
      </div>

      <div class="error-feedback" *ngIf="formControls.password.invalid && (formControls.password.dirty || formControls.password.touched)">
        <p *ngIf="formControls.password.errors.required">Ange ett lösenord</p>
        <p class="password-requirements" *ngIf="formControls.password.errors.pattern">Lösenordet måste ha minst 12 tecken och innehålla en gemen, en versal och en siffra.</p>
      </div>
      <div class="error-feedback" *ngIf="formControls.repeatedPassword.invalid && (formControls.repeatedPassword.dirty || formControls.repeatedPassword.touched)"> 
        <p *ngIf="formControls.repeatedPassword.errors.required">Upprepa lösenordet</p>
      </div>
      <div class="error-feedback">
        <p *ngIf="submitted && !(formControls.password.dirty || formControls.repeatedPassword.dirty)"> {{ error }}</p>
      </div>
      
      <div class="form-group">
        <button [disabled]="formControls.password.errors || formControls.repeatedPassword.errors" type="submit" class="submit-btn">
          SPARA
        </button>
      </div>
    </form>

  </div>
