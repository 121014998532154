<div class="flex justify-between w-full mb-3">
  <h2 class="tk-skolar-latin my-0 flex items-center">{{ data.title }}</h2>
  <button mat-icon-button (click)="close()">
    <mat-icon class="mat-18" fontIcon="close"></mat-icon>
  </button>
</div>
<p class="font-light max-w-lg">{{ data.description }}</p>
<div class="flex w-full justify-center">
  <button mat-flat-button color="warn" (click)="confirm()" class="rounded-full">
    <span class="text-white uppercase ">{{ data.buttonText }}</span>
  </button>
</div>
