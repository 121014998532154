import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ChatService} from './Services/chat.service';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SearchPageComponent} from './Pages/search-page/search-page.component';
import {LoginPageComponent} from './Pages/login-page/login-page.component';
import {AppViewPageComponent} from './Pages/app-view-page/app-view-page.component';
import {SearchListItemComponent} from './Components/search-list-item/search-list-item.component';
import {ReactiveFormsModule} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TopBarComponent} from './Components/top-bar/top-bar.component';
import {BackButtonComponent} from './Components/back-button/back-button.component';
import {JwtModule} from '@auth0/angular-jwt';
import {environment} from 'src/environments/environment';
import {JwtInterceptor} from './Helpers/jwt.interceptor';
import {TimelineItemComponent} from './Components/Timeline/timeline-item/timeline-item.component';
import {TimelinePageComponent} from './Pages/timeline-page/timeline-page.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {DatePipe} from '@angular/common';
import {MomentPipe} from './Pipes/moment.pipe';
import {AddTimesPageComponent} from './Pages/add-times-page/add-times-page.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatListModule} from '@angular/material/list';
import {
  MatNativeDateModule,
  DateAdapter,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {CalendarDateAdapter} from './Helpers/calendar-date-adapter';
import {MatMenuModule} from '@angular/material/menu';
import {HistoryPageComponent} from './Pages/history-page/history-page.component';
import {HistoryItemComponent} from './Components/history-item/history-item.component';
import {AdviceHandleComponent} from './Pages/advice-handle/advice-handle.component';
import {BookkeepForMeComponent} from './Pages/bookkeep-for-me/bookkeep-for-me.component';
import {BfmItemComponent} from './Components/List-items/bfm-item/bfm-item.component';
import {ManualAccountingComponent} from './Pages/manual-accounting/manual-accounting.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';

import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule } from '@angular/material/select';
import {SortByPipe} from './Pipes/sort-by.pipe';
import {ChatComponent} from './Pages/chat/chat.component';
import {CompanyChatComponent} from './Pages/chat/company-chat/company-chat.component';
import {ChatItemComponent} from './Components/List-items/chat-item/chat-item.component';
import {ChatMessageComponent} from './Components/chat-message/chat-message.component';
import {ToggleButtonComponent} from './Components/toggle-button/toggle-button.component';
import {ExpandingInputFieldComponent} from './Components/expanding-input-field/expanding-input-field.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {HistoryListComponent} from './Components/history-list/history-list.component';
import {HandleIssueModalComponent} from './Modals/handle-issue-modal/handle-issue-modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {LeaveChatComponent} from './Modals/leave-chat/leave-chat.component';
import {FileSaverModule} from 'ngx-filesaver';
import {SieExportComponent} from './Components/sie-export/sie-export.component';
import {CompanyInfoComponent} from './Components/company-info/company-info.component';
import {CanDeactivateChatGuard} from './Helpers/can-deactivate-chat.guard';
import {LoadingComponent} from './Components/loading/loading.component';
import {ChatOptionsComponent} from './Modals/chat-options/chat-options.component';
import {ZoomComponent} from './Helpers/zoom/zoom.component';
import {ConsultantHandlingComponent} from './Pages/consultant-handling/consultant-handling.component';
import {ConsultantListItemComponent} from './Components/consultant-list-item/consultant-list-item.component';
import {ConsultantDetailsModalComponent} from './Modals/consultant-details-modal/consultant-details-modal.component';
import {AssignmentSearchFieldComponent} from './Components/assignment-search-field/assignment-search-field.component';
import {ConfirmActionModalComponent} from './Modals/confirm-action-modal/confirm-action-modal.component';
import {ResetPasswordPageComponent} from './Pages/reset-password-page/reset-password-page.component';
import {ForgotPasswordPageComponent} from './Pages/forgot-password-page/forgot-password-page.component';
import {PasswordSuccessPageComponent} from './Pages/password-success-page/password-success-page.component';
import { NewsPageComponent } from './Pages/news-page/news-page.component';
import {NewsListItemComponent} from './Pages/news-page/news-list-item/news-list-item.component';
import {NewsListHeaderComponent} from './Pages/news-page/news-list-header/news-list-header.component';
import {NewsCreatePageComponent} from './Pages/news-create-page/news-create-page.component';

@NgModule({
  declarations: [
    AppComponent,
    SearchPageComponent,
    LoginPageComponent,
    AppViewPageComponent,
    SearchListItemComponent,
    TopBarComponent,
    BackButtonComponent,
    TimelineItemComponent,
    TimelinePageComponent,
    MomentPipe,
    AddTimesPageComponent,
    HistoryItemComponent,
    AdviceHandleComponent,
    HistoryPageComponent,
    BookkeepForMeComponent,
    BfmItemComponent,
    ManualAccountingComponent,
    SortByPipe,
    ChatComponent,
    CompanyChatComponent,
    ChatItemComponent,
    ChatMessageComponent,
    ToggleButtonComponent,
    ExpandingInputFieldComponent,
    HistoryListComponent,
    HandleIssueModalComponent,
    LeaveChatComponent,
    SieExportComponent,
    CompanyInfoComponent,
    LoadingComponent,
    ChatOptionsComponent,
    ZoomComponent,
    ConsultantHandlingComponent,
    ConsultantListItemComponent,
    ConsultantDetailsModalComponent,
    AssignmentSearchFieldComponent,
    ConfirmActionModalComponent,
    ResetPasswordPageComponent,
    ForgotPasswordPageComponent,
    PasswordSuccessPageComponent,
    NewsPageComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => {
          console.log('getting token');
          return localStorage.getItem(environment.TOKEN_NAME);
        },
        allowedDomains: [
          environment.apiUrl,
          environment.adminUrl,
          environment.bookkeepUrl,
          environment.adminUrl + '/*',
          environment.apiUrl + '/*',
          environment.bookkeepUrl + '/*',
        ],
      },
    }),
    MatDatepickerModule,
    MatButtonModule,
    MatIconModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatMenuModule,
    NgxMaterialTimepickerModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatTooltipModule,
    MatBottomSheetModule,
    MatListModule,
    FileSaverModule,
    NewsListItemComponent,
    NewsListHeaderComponent,
    NewsCreatePageComponent
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    DatePipe,
    {provide: MAT_DATE_LOCALE, useValue: 'sv-SE'},
    {provide: DateAdapter, useClass: CalendarDateAdapter},
    ChatService,
    CanDeactivateChatGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
