import { Component, EventEmitter, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/Services/auth.service';
import { Router, RouterEvent } from '@angular/router';
import { ChatService } from 'src/app/Services/chat.service';
import { __classPrivateFieldSet } from 'tslib';

@Component({
  selector: 'top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  activeUser = '';
  isMobile: boolean;
  isAdmin: boolean;
  insideChatAndMobile = false;

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private chatService: ChatService,
  ) {}
  ngOnInit(): void {
    this.isMobile = this.auth.mobilCheck();
    this.isAdmin = this.auth.isAdmin();
    this.auth.getActiveUserName().then((username) => {
      this.activeUser = username;
    });

    if (!this.isMobile) {
      this.selectBlobFromURL();
    }
    this.router.events.subscribe((event: RouterEvent) => {
      if (!this.isMobile) {
        this.setActive();
      } else {
        if (event.url && event.url.split('/')[2]) {
          this.insideChatAndMobile = true;
        } else if (event.url && !event.url.split('/')[2]) {
          this.insideChatAndMobile = false;
        }
      }
    });
  }
  goBack() {
    this.router.navigateByUrl('/chat');
  }

  setActive(event?: MouseEvent) {
    if (document.getElementsByClassName('active').length !== 0) {
      Array.from(document.getElementsByClassName('active')).forEach(
        (element) => {
          element.classList.remove('active');
        },
      );
    }

    if (event) {
      const elId: string = (event.target as Element).id;
      document.getElementById(`${elId}-blob`).classList.add('active');
      // add cases for the other routes
      switch (elId) {
        case 'search':
          this.router.navigate(['/search']);
          break;
        case 'advice':
          this.router.navigate(['/advice']);
          break;
        case 'log':
          this.router.navigate(['/history']);
          break;
        case 'bookkeep':
          this.router.navigate(['/assignments']);
          break;
        case 'chat':
          this.router.navigate(['/chat']);
          break;
        case 'consultant-handling':
          this.router.navigate(['/consultant-handling']);
          break;
        case 'news-handling':
          this.router.navigate(['/news']);
          break;
        case 'account-handling':
          this.router.navigate(['/account-management']);
          break;

        default:
          break;
      }
    } else {
      this.selectBlobFromURL();
    }
  }
  quitChat() {
    this.chatService.quitChat.emit(true);
  }

  logout() {
    this.auth.logout();
    this.router.navigateByUrl('/');
  }

  async selectBlobFromURL(): Promise<void> {
    let checkForTopBarInitId;
    const url = window.location.href.split('/')[3];

    new Promise<void>((resolve) => {
      checkForTopBarInitId = setInterval(() => {
        if (document.getElementById('search-blob')) {
          resolve();
        }
      }, 100);
    }).then(() => {
      clearInterval(checkForTopBarInitId);
      ////////////////////////
      ////// CLEAR ACTIVES
      ////////////////////////
      Array.from(document.getElementsByClassName('active')).forEach(
        (element: Element) => {
          element.classList.remove('active');
        },
      );

      switch (url) {
        case 'search':
          document.getElementById(`search-blob`).classList.add('active');
          break;
        case 'advice':
          document.getElementById(`advice-blob`).classList.add('active');
          break;
        case 'history':
          document.getElementById(`log-blob`).classList.add('active');
          break;
        case 'assignments':
          document.getElementById(`bookkeep-blob`).classList.add('active');
          break;
        case 'chat':
          document.getElementById(`chat-blob`).classList.add('active');
          break;
        case 'consultant-handling':
          document
            .getElementById(`consultant-handling-blob`)
            .classList.add('active');
          break;
        case 'news':
          document.getElementById(`news-handling-blob`).classList.add('active');
          break;
        case 'account-management':
          document
            .getElementById('account-handling-blob')
            .classList.add('active');
          break;
        default:
          if (url.startsWith('app')) {
            document.getElementById(`bookkeep-blob`).classList.add('active');
          } else if (url.startsWith('manual-accounting')) {
            document.getElementById(`bookkeep-blob`).classList.add('active');
          } else if (url.startsWith('chat')) {
            document.getElementById(`chat-blob`).classList.add('active');
          } else if (url.startsWith('consultant-handling')) {
            document
              .getElementById(`consultant-handling-blob`)
              .classList.add('active');
          } else {
            document.getElementById(`search-blob`).classList.add('active');
          }
          break;
      }
    });
  }
}
