import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-news-list-header [forActive]',
  templateUrl: './news-list-header.component.html',
  styleUrls: ['./news-list-header.component.scss'],
  standalone: true,
})
export class NewsListHeaderComponent implements OnInit {
  @Input() forActive: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

}
