<div class="container">
  <div class="header">
    <h1 class="page-title">{{ pageTitle }}</h1>
    <h2 class="page-subtitle">Fyll i uppgifterna nedan</h2>
  </div>

  <div class="consultant-details-form">
    <form
      [formGroup]="form"
      (ngSubmit)="saveConsultant()"
      (keydown.enter)="$event.preventDefault()"
      id="consultant-form"
    >
      <div class="form-upper-box">
        <div class="form-section">
          <label for="name">Namn</label>
          <input
            type="text"
            formControlName="name"
            id="name"
            [ngClass]="{ 'is-invalid': submitted && formControls.name.errors }"
            class="form-input-field"
          />
        </div>
        <div class="form-section">
          <label for="company">Företag</label>
          <input
            type="text"
            formControlName="companyName"
            id="company"
            [ngClass]="{
              'is-invalid': submitted && formControls.companyName.errors
            }"
            class="form-input-field"
          />
        </div>
        <div class="form-section">
          <label for="email">Mail</label>
          <input
            type="email"
            formControlName="email"
            id="email"
            [ngClass]="{
              'is-invalid': submitted && formControls.email.errors
            }"
            class="form-input-field"
          />
        </div>
        <div class="form-section">
          <label for="phonenr">Mobil</label>
          <input
            type="tel"
            formControlName="phoneNr"
            id="phonenr"
            [ngClass]="{
              'is-invalid': submitted && formControls.phoneNr.errors
            }"
            class="form-input-field"
          />
        </div>
      </div>
      <hr />
      <div class="form-lower-box">
        <assignment-search-field
          [label]="'Tilldela Wrebitanvändare'"
          [noneSelectedText]="'Ingen Wrebitanvändare tilldelad'"
          [selectedItems]="accessedCompanies"
          [availableItems]="availableCompanies"
          (updateSearch)="searchForCompanies($event)"
          (selectItem)="addCompany($event)"
          (unselectItem)="removeCompany($event)"
        >
        </assignment-search-field>
      </div>
      <div class="bottom-button-section">
        <button type="submit" form="consultant-form" class="confirm-button">
          {{ confirmButtonText }}
        </button>
      </div>
      <p *ngIf="submitted && invalidEmailError" class="error-feedback">
        {{ invalidEmailError }}
      </p>
      <p *ngIf="requiredFieldsError" class="error-feedback">
        {{ requiredFieldsError }}
      </p>
      <p *ngIf="submitted" class="error-feedback">
        {{ saveError }}
      </p>
      <p *ngIf="deleteError" class="error-feedback">
        {{ deleteError }}
      </p>
    </form>
  </div>

  <div class="top-button-section">
    <button
      mat-icon-button
      (click)="openDeleteDialog()"
      *ngIf="existingConsultant"
    >
      <mat-icon class="material-icon-delete">delete</mat-icon>
    </button>
    <button mat-icon-button mat-dialog-close>
      <mat-icon class="material-icon-close">close</mat-icon>
    </button>
  </div>
</div>
