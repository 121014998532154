import { AdminAccountDTO } from '@hawco/wrebit-contracts';
import {
  EditAccountDescriptionDialogComponent,
  EditAccountDescriptionDialogResult,
} from './../../Components/dialogs/edit-account-description-dialog/edit-account-description-dialog.component';
import { AccountDescriptionService } from './../../Services/account-description.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogResult,
} from './../../Components/dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { filter, map, take } from 'rxjs/operators';
import { ApiService } from 'src/app/Services/api.service';
import { BehaviorSubject, concatMap } from 'rxjs';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from '../../Components/search-bar/search-bar.component';
import { Account } from 'src/app/Models/accounting-row';
import { AccountManagementItemComponent } from '../../Components/account-management-item/account-management-item.component';

@Component({
  selector: 'app-account-management',
  standalone: true,
  templateUrl: './account-management.component.html',
  styleUrls: ['./account-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, SearchBarComponent, AccountManagementItemComponent],
})
export class AccountManagementComponent implements OnInit {
  accounts$: BehaviorSubject<AdminAccountDTO[]> = new BehaviorSubject<AdminAccountDTO[]>([]);
  filteredAccounts$: BehaviorSubject<AdminAccountDTO[]> = new BehaviorSubject<
    AdminAccountDTO[]
  >([]);

  constructor(
    private dialog: MatDialog,
    private accountDescriptionService: AccountDescriptionService,
  ) { }

  ngOnInit(): void {
    this.accountDescriptionService
      .getAccounts()
      .pipe(
        map((plan) =>
          plan.accounts.sort(
            (accountA, accountB) =>
              +accountA.accountNumber - +accountB.accountNumber,
          ),
        ),
        take(1)
      )
      .subscribe({
        next: (accounts) => {
          this.accounts$.next(accounts);
          this.filteredAccounts$.next(accounts);
        },
        error: () => {
          alert('Kunde inte hämta kontoplanen');
        },
      });
  }
  accountTrackByFn(index: number, item: Account) {
    return item.accountNumber + item.name;
  }

  search(searchValue: string) {
    if (searchValue.length === 0) {
      this.filteredAccounts$.next(this.accounts$.value);
      return;
    }
    const filteredData = this.accounts$.value.filter(
      (account) =>
        account.name.toLowerCase().startsWith(searchValue.toLowerCase()) ||
        account.accountNumber
          .toString()
          .toLowerCase()
          .startsWith(searchValue.toLowerCase()),
    );
    this.filteredAccounts$.next(filteredData);
  }

  editDescription(account: AdminAccountDTO) {
    console.log('edit', account);
    const dialogRef = this.dialog.open(EditAccountDescriptionDialogComponent, {
      data: {
        account,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((value: EditAccountDescriptionDialogResult) => value.save),
        concatMap((value) =>
          this.accountDescriptionService
            .editAccountDescription(account.id, value.description)
            .pipe(map((response) => ({ response, input: value }))),
        ),
        take(1),
      )
      .subscribe({
        next: ({ response, input }) => {
          const indexToChange = this.accounts$.value.findIndex(
            (acc) => account.accountNumber === acc.accountNumber,
          );
          if (indexToChange < 0) {
            return;
          }
          const data = this.accounts$.value;
          const elementToChange = { ...data[indexToChange] };
          elementToChange.description = response.description;
          data[indexToChange] = elementToChange;

          this.accounts$.next(data);
          this.filteredAccounts$.next(data);
          console.log(this.filteredAccounts$.value.filter((val) => val.accountNumber.startsWith("1010")));
        },
        error: (err) => {
          console.error(err);
          alert('Kunde inte uppdatera beskrivning på konto');
        },
      });
  }

  removeDescription(account: AdminAccountDTO) {
    console.log('remove', account);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Vill du ta bort beskrivning',
        description:
          'Beskrivningen kommer tas bort och inte längre visas för användaren. Åtgärden går inte att ångra.',
        buttonText: 'Ta bort beskrivning',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        take(1),
        filter((value: ConfirmDialogResult) => value),
        concatMap(() =>
          this.accountDescriptionService.removeAccountDescription(account.id),
        ),
        take(1),
      )
      .subscribe({
        next: () => {
          const indexToChange = this.accounts$.value.findIndex(
            (acc) => account.accountNumber === acc.accountNumber,
          );
          if (indexToChange < 0) {
            return;
          }
          const data = this.accounts$.value;
          const elementToChange = { ...data[indexToChange] };
          // elementToChange.description = '';
          data[indexToChange] = elementToChange;
          this.accounts$.next(data);
          this.filteredAccounts$.next(data);
        },
        error: (err) => {
          console.error(err);
          alert('Kunde inte ta bort beskrivning');
        },
      });
  }
}
