<div
  @enterLeave
  class="item-container"
  (click)="expanded = !expanded"
  [ngClass]="{
    noScale: expanded
  }"
>
  <div class="information-container">
    <div class="header-row">
      <div class="header-row-left">
        <h4
          class="header-time"
          [ngClass]="{ booked: timeslot.advice, available: !timeslot.advice }"
        >
          {{ timeslot.start | moment: "HH:mm" }}
        </h4>
        <h4
          [ngClass]="{
            'header-status': true,
            available: !timeslot.advice,
            booked: timeslot.advice
          }"
        >
          {{ timeslot.advice ? "Bokad" : "Tillgänglig" }}
        </h4>
      </div>
      <div class="header-row-right">
        <div class="header-customer" *ngIf="timeslot.advice">
          <p class="customer-title">KUND</p>
          <p class="customer">{{ timeslot.advice.company.name }}</p>
        </div>
        <h6 class="header-consultant">
          {{ timeslot.advisor ? timeslot.advisor.name : "Okänd Rådgivare" }}
        </h6>
      </div>
    </div>
    <div
      class="expand"
      [ngClass]="{
        expanded: expanded && timeslot.advice,
        'expanded-short': expanded && !timeslot.advice
      }"
    >
      <ng-container *ngIf="timeslot.advice; else Available">
        <div class="extra-info">
          <div class="contact-container">
            <h4 class="primary-info title">Kontakt</h4>
            <h4 class="secondary-info">
              {{ timeslot.advice.address }}
            </h4>
            <h4 class="secondary-info">
              {{ timeslot.advice.postalCode }}
            </h4>
            <h4 class="secondary-info">{{ timeslot.advice.city }}</h4>
            <h4 class="secondary-info">
              {{ timeslot.advice.phoneNr }}
            </h4>
          </div>
          <div class="right-column">
            <div class="integrations-container">
              <h4 class="primary-info title">
                Integrationer
              </h4>
              <h4
                class="secondary-info"
                *ngFor="let integration of integrations"
              >
                {{ integration | titlecase }}
              </h4>
            </div>
          </div>
        </div>
        <div class="confirm-button-container">
          <button class="open-button confirm-button" (click)="enterCompany()">
            Öppna Konto
          </button>
          <button class="unbook-button" (click)="removeTimeslot()">
            AVBOKA TID
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #Available>
  <div class="cancel-container">
    <button
      class="confirm-button"
      id="unbook-button"
      (click)="removeTimeslot()"
    >
      TA BORT DIN TID
    </button>
  </div>
</ng-template>
