import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'confirm-action-modal',
  templateUrl: './confirm-action-modal.component.html',
  styleUrls: ['./confirm-action-modal.component.scss']
})
export class ConfirmActionModalComponent implements OnInit {

  pageTitle: string;
  pageBody: string;
  confirmButton: string;
  confirmButtonColor: string;

  @Output() confirmAction: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public dialogRef: MatDialogRef<ConfirmActionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { pageTitle: string, pageBody: string, confirmButton: string, confirmButtonColor: string }) {
    this.pageTitle = data.pageTitle;
    this.pageBody = data.pageBody;
    this.confirmButton = data.confirmButton;
    this.confirmButtonColor = data.confirmButtonColor;
  }

  ngOnInit(): void {
  }

  confirm(): void {
    this.confirmAction.emit();
    this.dialogRef.close();
  }

}
