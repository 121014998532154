import { Component, OnInit, Input } from '@angular/core';
import { ChatMessage } from 'src/app/Models/chat';
import { AuthenticationService } from 'src/app/Services/auth.service';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent implements OnInit {
  @Input() message: ChatMessage;
  @Input() prevMessage: ChatMessage;
  @Input() nextMessage: ChatMessage;
  showTimestamp: boolean = false;
  showAsInfo: boolean = false;
  infoMessage: string;
  infoClass: string;
  constructor(private auth: AuthenticationService) {}

  ngOnInit(): void {
    if (this.message.sender === 'info') {
      this.showAsInfo = true;
      this.infoMessage = this.message.text.split(';')[0];
      this.infoClass = this.message.text.split(';')[1];
    }
    // if more than 25 min since last message
    if (
      this.prevMessage &&
      new Date(this.message.createdAt).getTime() -
        new Date(this.prevMessage.createdAt).getTime() >
        1500000
    ) {
      this.showTimestamp = true;
    }
  }

  isSender(message): boolean {
    return message.sender === 'chat-consultant';
  }

  isFirst(): boolean {
    if (!this.prevMessage) return true;
    return this.prevMessage.sender !== this.message.sender;
  }
  isLast(): boolean {
    if (!this.nextMessage) return true;
    return this.nextMessage.sender !== this.message.sender;
  }
}
