import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {Timeslot} from 'src/app/Models/timeslot';
import {ApiService} from 'src/app/Services/api.service';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'timeline-item',
  templateUrl: './timeline-item.component.html',
  styleUrls: ['./timeline-item.component.scss'],
  animations: [
    trigger('enterLeave', [
      transition(':enter', [
        style({opacity: 0}),
        animate(250, style({opacity: 1}))
      ]),
      transition(':leave', [
        style({opacity: 1}),
        animate(250, style({opacity: 0}))
      ])
    ])
  ]
})
export class TimelineItemComponent implements OnInit {
  expanded = false;
  available = false;
  @Input() timeslot: Timeslot;
  @Output() onRemove: EventEmitter<string> = new EventEmitter<string>();
  integrations: string[] = [];

  constructor(private router: Router, private api: ApiService) {
  }

  ngOnInit(): void {
  }

  enterCompany(): void {
    if (this.timeslot.advice) {
      const company = JSON.stringify(this.timeslot.advice.company);
      this.router.navigate(['/app'], {
        queryParams: {company: btoa(company)},
      });
    }
  }

  removeTimeslot(): void {
    this.api.removeTimeslot(this.timeslot.id).subscribe((res: string): void => {
      this.onRemove.emit(this.timeslot.id);
    });
  }
}
