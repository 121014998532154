import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { Account } from './../../Models/accounting-row';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FadeInOut } from 'src/app/Helpers/animation';
import { AdminAccountDTO } from '@hawco/wrebit-contracts';

@Component({
  selector: 'app-account-management-item',
  standalone: true,
  imports: [CommonModule, MatButtonModule, FormsModule],
  templateUrl: './account-management-item.component.html',
  styleUrls: ['./account-management-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [FadeInOut(250, 200, true)]
})
export class AccountManagementItemComponent implements OnInit {
  @Input() account: AdminAccountDTO;
  @Output() editEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() trashEvent: EventEmitter<void> = new EventEmitter<void>();
  isOpen: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  trashHandler(event: Event) {
    event.stopPropagation();
    this.trashEvent.emit();
  }
  editHandler(event: Event) {
    event.stopPropagation();
    this.editEvent.emit();
  }
}
