import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-file-input',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileInputComponent implements OnInit {
  @Input() label: string;
  @Input() accept: string;
  @Input() secondaryLabel: string;
  @Output() fileChange: EventEmitter<File> = new EventEmitter<File>();
  file$ = new BehaviorSubject<File>(undefined);


  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIcon('eva-upload', domSanitizer.bypassSecurityTrustResourceUrl('/assets/eva/upload-outline.svg'));
  }

  ngOnInit(): void {
  }

  onFileSelected(event: Event) {
    const file = ((event.target as any).files as FileList).item(0);
    this.file$.next(file);
    this.fileChange.emit(file);
  }

}
