<div class="page">
  <div class="filter-container">
    <div class="filters">

      <div class="filter-top">
        <div class="filter-show">
          <div class="show-filters">
            <button
              class="show-filter"
              [ngClass]="{ 'active-filter': activeFilterTypes.length === 0 }"
              (click)="clickedFilterType(0)"
            >
              Alla
            </button>
            <button
              class="show-filter"
              [ngClass]="{
                'active-filter': activeFilterTypes.includes(1)
              }"
              (click)="clickedFilterType(1)"
            >
              Bokfört åt kund
            </button>
            <button
              class="show-filter"
              [ngClass]="{
                'active-filter': activeFilterTypes.includes(2)
              }"
              (click)="clickedFilterType(2)"
            >
              Telefon
            </button>
            <button
              class="show-filter"
              [ngClass]="{
                'active-filter': activeFilterTypes.includes(3)
              }"
              (click)="clickedFilterType(3)"
            >
              Mail
            </button>
            <button
              class="show-filter"
              [ngClass]="{
                'active-filter': activeFilterTypes.includes(4)
              }"
              (click)="clickedFilterType(4)"
            >
              Chatt
            </button>
          </div>
        </div>
      </div>
      <div class="filter-bottom">
        <div class="filter-bottom-left">
          <div class="filter-search">
            <input
              type="text"
              [(ngModel)]="searchValue"
              (ngModelChange)="search($event)"
              class="search-bar"
              placeholder="Sök företagsnamn"
            />
          </div>
          <div class="font-size">

            <mat-form-field appearance="outline">
              <mat-label>Välj datum</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input
                  matStartDate
                  formControlName="start"
                  placeholder="Start"
                />
                <input matEndDate formControlName="end" placeholder="Slut"/>
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="entries-container" *ngIf="!loading; else loadingTemplate">
    <h1
      class="company-title"
      id="unhandled"
      *ngIf="unhandledEntries.length !== 0"
    >
      <div class="circle">{{ unhandledEntries.length }}</div>
      Ohanterade - lägg till antal minuter
    </h1>
    <div class="entry-container" *ngFor="let entry of unhandledEntries">
      <app-history-item [item]="entry"></app-history-item>
    </div>
    <div
      class="entry-container"
      *ngFor="let entry of filteredEntries; let i = index"
    >
      <div class="title-container" *ngIf="!isSameCompany(i)">
        <h1 class="company-title">
          {{
          entry.company
            ? entry.company.name
              ? entry.company.name
              : 'Okänt Namn'
            : entry.companyName
              ? entry.companyName
              : entry.customer
                ? entry.customer.name
                : 'Okänt Namn'
          }}
          <span class="company-info">{{
            entry.company
              ? entry.company.orgNr
                ? entry.company.orgNr
                : 'Okänt Orgnr'
              : entry.orgNr
                ? entry.orgNr
                : entry.customer
                  ? entry.customer.orgNr
                  : 'Okänt Orgnr'
            }}</span>
          <span class="company-info"
          >{{
            entry.address
              ? (entry.address | titlecase)
              : entry.company
                ? (entry.company.address | titlecase)
                : entry.customer
                  ? (entry.customer.address | titlecase)
                  : ('' | titlecase)
            }},
            {{
            entry.postalCode
              ? entry.postalCode
              : entry.company
                ? entry.company.postalCode
                : entry.customer
                  ? entry.customer.postalCode
                  : ''
            }},
            {{
            entry.city
              ? (entry.city | titlecase)
              : entry.company
                ? (entry.company.city | titlecase)
                : entry.customer
                  ? (entry.customer.city | titlecase)
                  : ''
            }}</span
          >
          <span class="company-info">{{
            entry.email
              ? (entry.email | lowercase)
              : entry.company
                ? (entry.company.email | lowercase)
                : (entry.email | lowercase)
                  ? entry.email
                  : entry.customer
                    ? (entry.customer.email | lowercase)
                    : entry.companyEmail
                      ? (entry.companyEmail | lowercase)
                      : ''
            }}</span>
        </h1>
      </div>
      <app-history-item [item]="entry"></app-history-item>
    </div>
  </div>
  <button class="export-btn" (click)="exportCSV()">
    Exportera lista
  </button>
</div>

<ng-template #loadingTemplate>
  <div class="spinner-container">
    <mat-spinner diameter="50"></mat-spinner>
  </div>
</ng-template>
