import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {NewsService} from '../../Services/news.service';


export interface CreateNewsPageState {
  uploadStatus: 'loading' | 'finished';
  sendStatus: 'uninitialized' | 'loading' | 'success' | 'failure';
}

const defaultState: CreateNewsPageState = {
  sendStatus: 'uninitialized',
  uploadStatus: 'finished'
};

@Injectable()
export class CreateNewsPageStore extends ComponentStore<CreateNewsPageState> {
  constructor(private newsService: NewsService) {
    super(defaultState);
  }

  // SELECTORS
  readonly status$ = this.select(({sendStatus}) => sendStatus);
  readonly sendLoading$ = this.select(({sendStatus}) => sendStatus === 'loading');
  readonly uploadLoading$ = this.select(({uploadStatus}) => uploadStatus === 'loading');


  // EFFECTS


  // UPDATERS

  readonly setStatusLoading = this.updater((state) => ({...state, sendStatus: 'loading'}));
  readonly setStatusFailure = this.updater((state) => ({...state, sendStatus: 'failure'}));
  readonly setStatusSuccess = this.updater((state) => ({...state, sendStatus: 'success'}));
  readonly setUploadStatus = this.updater((state, value: 'loading' | 'finished') => ({...state, uploadStatus: value}));

}
