<div class="page">
  <h2 class="title">Lägg till rådgivningstider</h2>
  <mat-calendar
    id="calendar"
    (selectedChange)="clickedDate($event)"
  ></mat-calendar>
  <div class="times">
    <div class="time-titles"></div>
    <div class="timepickers">
      <div class="start">
        <!--<p class="time-title">Start</p>-->
        <ngx-timepicker-field
          id="timepicker"
          [format]="24"
          [defaultTime]="'09:00'"
          [controlOnly]="true"
          (timeChanged)="setTime($event)"
        ></ngx-timepicker-field>
      </div>
      <div class="stop">
        <!--<p class="time-title">Stop</p>-->
        <div class="stop-time-picker">
          <h1 class="stop-time">
            <span class="hour">{{ stopHour | number: "2.0-0" }}</span>
            <span class="colon">:</span
            ><span class="minute">{{ stopMinute | number: "2.0-0" }}</span>
          </h1>
        </div>
      </div>
    </div>
  </div>
  <button class="add-times" (click)="addTimeslots()">Lägg till</button>
</div>
