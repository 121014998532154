import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChatService } from 'src/app/Services/chat.service';
import { Chat } from 'src/app/Models/chat';
import { AuthenticationService } from 'src/app/Services/auth.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {
  chats: Chat[];
  isAvailable: boolean;
  isMobile: boolean;
  username: string = '';
  constructor(
    private chatService: ChatService,
    private auth: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.isMobile = this.auth.mobilCheck();
    this.auth.getActiveUserName().then((username) => {
      this.username = username;
    });
    this.chatService.getActiveChats().subscribe((chats: Chat[]) => {
      this.chats = chats.filter((chat) => {
        console.log(chat);
        console.log(this.auth.getUserId());
        return (
          (!chat.consultant || chat.consultant.id === this.auth.getUserId()) &&
          chat.status !== 'finished'
        );
      });
      console.log(this.chats);
    });
    this.chatService.initAvailability();
    this.chatService.socket.on('chat', (chats: any[]) => {
      console.log(chats);
      if (chats) {
        this.chats = chats.filter((chat: Chat) => {
          return chat.status !== 'finished';
        });
      }
      this.chats = chats;
    });
    this.chatService.socket.on('chat-patch', (data) => {
      console.log('Chat meta updated');
      console.log(data);
      this.chatService.patchHandler.emit(data);
    });

    this.chatService.getAvailability().subscribe((status) => {
      console.log(typeof status);
      console.log(status);
      console.log('HEEEEELO');
      this.isAvailable = status as boolean;
      console.log(this.isAvailable);
    });
  }
  ngOnDestroy(): void {
    //this.chatService.setAvailability(false);
    this.chatService.socket.close();
  }
  toggleAvailable(event) {
    this.chatService.setAvailability(event).subscribe(() => {
      console.log(event);
    });
  }
  submit() {
    console.log('submitted');
  }
}
