import { Company } from './company';

export interface ChatMessage {
  createdAt: string;
  text: string;
  sender: string;
}

export class Tag {
  name: string;
  active: boolean;
  constructor(name: string, active: boolean) {
    this.name = name;
    this.active = active;
  }
}

export class Chat {
  chatFinished: Date;
  consultantConnected: string;
  id: string;
  user: string;
  status: string;
  messages: ChatMessage[];
  customerId: string;
  customer: Company;
  consultant: any;
  price: string;
  createdAt: Date;
  duration: string;
  type?: string = 'chat';
  constructor() {
    this.messages = [];
  }

}
