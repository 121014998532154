<div class="container">
  <p class="title" [ngClass]="{ offSelected: !value }">{{ offText }}</p>
  <label class="switch">
    <input
      type="checkbox"
      value="false"
      id="toggle"
      (change)="emitValue($event)"
      [(ngModel)]="value"
    />
    <span class="slider round"></span>
  </label>
  <p class="title" [ngClass]="{ onSelected: value }">{{ onText }}</p>
</div>
