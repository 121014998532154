<div class="message-container" *ngIf="message.sender !== 'prompt'">
  <div class="timestamp" *ngIf="showTimestamp && message.sender !== 'info'">
    {{ message.createdAt | date: "yyyy-MM-dd HH:mm" }}
  </div>
  <div
    *ngIf="isSender(message); else notClient"
    class="your-message-box message-box"
    [ngClass]="{
      'your-first': isFirst() && !isLast(),
      'your-middle': !isFirst() && !isLast(),
      'your-last': !isFirst() && isLast()
    }"
    matTooltip="{{ message.createdAt | date: 'y-MM-dd HH:mm' }}"
    matTooltipClass="date-tooltip"
    [matTooltipPosition]="'left'"
  >
    <p class="message">{{ message.text }}</p>
  </div>
</div>

<ng-template #notClient>
  <div *ngIf="message.sender === 'info'; then info; else customer"></div>
</ng-template>
<ng-template #info>
  <p class="info-message">
    <i class="fa fa-circle" [ngClass]="infoClass"></i>
    {{ infoMessage }}
  </p></ng-template
>
<ng-template #customer>
  <div
    [ngClass]="{
      'their-message-box': true,
      'message-box': true,
      'their-first': isFirst() && !isLast(),
      'their-middle': !isFirst() && !isLast(),
      'their-last': !isFirst() && isLast()
    }"
    matTooltip="{{ message.createdAt | date: 'y-MM-dd HH:m' }}"
    matTooltipClass="date-tooltip"
    [matTooltipPosition]="'right'"
  >
    <p class="message">{{ message.text }}</p>
  </div>
</ng-template>
