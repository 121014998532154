import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { AuthenticationService } from 'src/app/Services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-times-page',
  templateUrl: './add-times-page.component.html',
  styleUrls: ['./add-times-page.component.scss'],
})
export class AddTimesPageComponent implements OnInit {
  public model: Array<Date> = [];
  private startTime: string = '09:00';
  public stopHour: string = '10';
  public stopMinute: string = '00';
  constructor(
    private api: ApiService,
    private auth: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    //Disable useless button for better UX
    this.disablePeriodButton();
  }

  private disablePeriodButton() {
    setTimeout(() => {
      (document
        .getElementsByClassName('mat-calendar-period-button')
        .item(0) as HTMLInputElement).disabled = true;
    }, 0);
  }

  public clickedDate(event): void {
    let date: Date = new Date(event);
    let day: number = date.getDate();
    if (this._findDate(date) === -1) {
      this.model.push(date);
      document
        .getElementsByClassName('mat-calendar-body-cell-content')
        [day - 1].classList.add('selected-calendar-cell');
    } else {
      this.remove(date);
      document
        .getElementsByClassName('mat-calendar-body-cell-content')
        [day - 1].classList.remove('selected-calendar-cell');
    }
    this._sortDates();
  }

  private _sortDates(): void {
    this.model.sort((a, b) => {
      return a.valueOf() - b.valueOf();
    });
  }

  public remove(date: Date): void {
    const index = this._findDate(date);
    this.model.splice(index, 1);
  }

  private _findDate(date: Date): number {
    return this.model.map((m) => +m).indexOf(+date);
  }
  public setTime(event) {
    this.startTime = event;
    this.stopHour = `${+this.startTime.split(':')[0] + 1}`;
    this.stopMinute = this.startTime.split(':')[1];
  }
  public addTimeslots(): void {
    let slotsToAdd = new Object();
    this.model.forEach((date: Date, index: number) => {
      date.setHours(+this.startTime.split(':')[0]);
      date.setMinutes(+this.startTime.split(':')[1]);
      slotsToAdd[`timeslot${index}`] = date;
    });

    this.api.createTimeslot(slotsToAdd).subscribe(
      () => {
        console.log('timeslot(s) created');
        this.router.navigate(['advice']);
      },
      (err) => {
        console.error(err);
      }
    );
  }
}
