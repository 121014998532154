import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginPageComponent} from './Pages/login-page/login-page.component';
import {SearchPageComponent} from './Pages/search-page/search-page.component';
import {AppViewPageComponent} from './Pages/app-view-page/app-view-page.component';
import {AuthGuard} from './Helpers/auth.guard';
import {TimelinePageComponent} from './Pages/timeline-page/timeline-page.component';
import {AddTimesPageComponent} from './Pages/add-times-page/add-times-page.component';
import {HistoryPageComponent} from './Pages/history-page/history-page.component';
import {AdviceHandleComponent} from './Pages/advice-handle/advice-handle.component';
import {BookkeepForMeComponent} from './Pages/bookkeep-for-me/bookkeep-for-me.component';
import {ManualAccountingComponent} from './Pages/manual-accounting/manual-accounting.component';
import {ChatComponent} from './Pages/chat/chat.component';
import {CompanyChatComponent} from './Pages/chat/company-chat/company-chat.component';
import {CanDeactivateChatGuard} from './Helpers/can-deactivate-chat.guard';
import {MobileGuard} from './Helpers/mobile.guard';
import {ConsultantHandlingComponent} from './Pages/consultant-handling/consultant-handling.component';
import {ResetPasswordPageComponent} from './Pages/reset-password-page/reset-password-page.component';
import {ResetPasswordGuard} from './Helpers/reset-password.guard';
import {ForgotPasswordPageComponent} from './Pages/forgot-password-page/forgot-password-page.component';
import {PasswordSuccessPageComponent} from './Pages/password-success-page/password-success-page.component';
import {AdminGuard} from './Helpers/admin.guard';
import {NewsPageComponent} from './Pages/news-page/news-page.component';
import {NewsCreatePageComponent} from './Pages/news-create-page/news-create-page.component';
import { AccountManagementComponent } from './Pages/account-management/account-management.component';

const routes: Routes = [
  {path: '', component: LoginPageComponent},
  {
    path: 'search',
    component: SearchPageComponent,
    canActivate: [AuthGuard, MobileGuard],
  },
  {
    path: 'app',
    component: AppViewPageComponent,
    canActivate: [AuthGuard, MobileGuard],
  },

  {
    path: 'advice',
    component: TimelinePageComponent,
    canActivate: [AuthGuard, MobileGuard, AdminGuard],
  },
  {
    path: 'new-timeslots',
    component: AddTimesPageComponent,
    canActivate: [AuthGuard, MobileGuard, AdminGuard],
  },
  {
    path: 'handle/:id',
    component: AdviceHandleComponent,
    canActivate: [AuthGuard, MobileGuard, AdminGuard],
  },
  {
    path: 'history',
    component: HistoryPageComponent,
    canActivate: [AuthGuard, MobileGuard, AdminGuard],
  },
  {
    path: 'assignments',
    component: BookkeepForMeComponent,
    canActivate: [AuthGuard, MobileGuard, AdminGuard],
  },
  {
    path: 'manual-accounting',
    component: ManualAccountingComponent,
    canActivate: [AuthGuard, MobileGuard, AdminGuard],
  },
  {
    path: 'chat',
    component: ChatComponent,
    canActivate: [AuthGuard, AdminGuard],
  },
  {
    path: 'chat/:id',
    component: CompanyChatComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateChatGuard],
  },
  {
    path: 'consultant-handling',
    component: ConsultantHandlingComponent,
    canActivate: [AuthGuard, MobileGuard, AdminGuard],
  },
  {
    path: 'new-password',
    component: ResetPasswordPageComponent,
    canActivate: [MobileGuard, ResetPasswordGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordPageComponent,
    canActivate: [MobileGuard],
  },
  {
    path: 'confirmation',
    component: PasswordSuccessPageComponent,
    canActivate: [MobileGuard],
  },
  {
    path: 'news',
    component: NewsPageComponent,
    canActivate: [MobileGuard, AuthGuard, AdminGuard]
  },
  {
    path: 'news/create',
    component: NewsCreatePageComponent,
    canActivate: [MobileGuard, AuthGuard, AdminGuard]
  },
  {
    path: 'account-management',
    component: AccountManagementComponent,
    canActivate: [MobileGuard, AuthGuard, AdminGuard]
  },

  //////////////
  // Default path
  //////////////
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
