import { Component, OnInit, Input } from '@angular/core';
import { BookkeepForMe } from 'src/app/Models/bookkeep-for-me';
import { ApiService } from 'src/app/Services/api.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/Services/auth.service';
import { User } from 'src/app/Models/user';

@Component({
  selector: 'bfm-item',
  templateUrl: './bfm-item.component.html',
  styleUrls: ['./bfm-item.component.scss'],
})
export class BfmItemComponent implements OnInit {
  @Input() entry: BookkeepForMe;
  constructor(
    private api: ApiService,
    private router: Router,
    private auth: AuthenticationService,
  ) {}

  ngOnInit(): void {}

  enterCompany() {
    this.auth.getUser().subscribe((user: User) => {
      this.api.assignAdvisorBFM(this.entry.id, user.id, user.name).subscribe(
        (res) => {
          this.router.navigate([
            '/manual-accounting',
            { bfm: JSON.stringify(this.entry) },
          ]);
        },
        (error) => {
          console.log('BFM redan tilldelad');
          this.router.navigate([
            '/manual-accounting',
            { bfm: JSON.stringify(this.entry) },
          ]);
        },
      );
    });
  }
}
