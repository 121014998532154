<div
  class="history-item-container"
  [ngClass]="{
    'history-item-container': true,
    'unhandled-item': item.duration === null
  }"
  (click)="handleItem()"
>
  <div class="left-container">
    <div class="icon-container full-height"><img [src]="iconSrc" /></div>

    <div class="info-container full-height">
      <p class="title">
        {{ date ? (date | date: 'y-MM-dd') : 'Okänt Datum' }}
      </p>
      <p class="content">
        {{
          item.email
            ? item.email
            : item.customer
            ? item.customer.email
            : item.companyEmail
            ? item.companyEmail
            : 'Okänd mail'
        }}
      </p>
    </div>
    <div class="info-container full-height">
      <p class="title">Typ</p>
      <p class="content">{{ adviceType }}</p>
    </div>
    <div
      class="info-container full-height"
      [ngClass]="{ hidden: item.duration === null }"
    >
      <p class="title">Tid</p>
      <p class="content">
        {{ isNumber(item.duration) ? item.duration + ' min' : '?' }}
      </p>
    </div>
    <div
      class="info-container full-height"
      [ngClass]="{ hidden: item.duration === null && item.price === null }"
    >
      <p class="title">Pris</p>
      <p class="content">
        {{ item.price ? item.price + 'kr' : item.price === 0 ? '0 kr' : '?' }}
      </p>
    </div>
  </div>
  <div class="right-container">
    <p class="advisor">
      {{
        !item.timeslot
          ? item.consultant
            ? item.consultant.name
            : item.advisorName
            ? item.advisorName
            : ''
          : item.timeslot.advisor
          ? item.timeslot.advisor.name
          : ''
      }}
    </p>
  </div>
</div>
