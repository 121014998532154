<div class="window" *ngIf="!isMobile; else mobile">
  <i class="fa fa-times exit" (click)="cancel()"></i>
  <h1 class="title">
    Du avslutar nu ärendet
  </h1>
  <p class="preamble">
    Fyll i uppgifterna nedan innan du avslutar ärendet.
  </p>
  <div class="input-container">
    <div class="times">
      <p class="label">Uppskattad tid</p>
      <div class="time-container">
        <input
          [(ngModel)]="minutes"
          type="text"
          class="form-control"
          value="0"
        />
        <div class="input-group-append">
          <span class="input-group-text"> min </span>
        </div>
      </div>
    </div>
    <div class="times">
      <p class="label">Överrenskommet pris</p>
      <div class="time-container">
        <input [(ngModel)]="price" type="text" class="form-control" value="0" />
        <div class="input-group-append">
          <span class="input-group-text"> kr </span>
        </div>
      </div>
    </div>
  </div>
  <button class="confirm-button" (click)="submit()">Avsluta</button>
</div>

<ng-template #mobile>
  <div class="mobile-window">
    <i class="fa fa-times mobile-exit" (click)="cancel()"></i>
    <h1 class="mobile-title">Du avslutar nu chatten</h1>
    <p class="mobile-preamble">
      Fyll i uppgifterna nedan innan du avslutar chatten och ärendet.
    </p>
    <div class="input-container mobile-input-container">
      <div class="mobile-times">
        <div class="left-align">
          <p class="label">Uppskattad tid</p>
          <div class="time-container">
            <input
              [(ngModel)]="minutes"
              type="text"
              class="form-control"
              value="0"
            />
            <div class="input-group-append">
              <span class="input-group-text"> min </span>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-times">
        <div class="left-align">
          <p class="label">Överrenskommet pris</p>
          <div class="time-container">
            <input
              [(ngModel)]="price"
              type="text"
              class="form-control"
              value="0"
            />
            <div class="input-group-append">
              <span class="input-group-text"> kr </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="confirm-button mobile-confirm-button" (click)="submit()">
      Avsluta
    </button>
  </div>
</ng-template>
