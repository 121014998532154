<div class="window" *ngIf="!isMobile; else mobile">
  <i class="fa fa-times exit" (click)="cancel()"></i>
  <h1 class="title">Du lämnar nu chatten!</h1>
  <p class="preamble">
    Konversationen ligger kvar!<br />
    Vill du behålla din tilldelning på denna konversation?
  </p>

  <div class="actions">
    <button class="confirm-button" (click)="keepAssignment()">
      Behåll tilldelning
    </button>
    <button class="confirm-button remove-button" (click)="removeAssignment()">
      Ta bort tilldelning
    </button>
  </div>
</div>

<ng-template #mobile>
  <div class="mobile-window">
    <i class="fa fa-times mobile-exit" (click)="cancel()"></i>
    <h1 class="mobile-title">Du lämnar nu chatten!</h1>
    <p class="mobile-preamble">
      Konversationen ligger kvar!<br />
      Vill du behålla din tilldelning på denna konversation?
    </p>
  </div>
  <div class="mobile-actions">
    <button
      class="mobile-confirm-button confirm-button"
      (click)="keepAssignment()"
    >
      Behåll tilldelning
    </button>
    <button
      class="confirm-button mobile-remove-button"
      (click)="removeAssignment()"
    >
      Ta bort tilldelning
    </button>
  </div>
</ng-template>
