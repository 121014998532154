import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {
 MatDialog,
 MatDialogRef,
   MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {Consultant} from 'src/app/Models/consultant';
import {ApiService} from 'src/app/Services/api.service';
import {Company} from 'src/app/Models/company';
import {SearchService} from 'src/app/Services/search.service';
import {Subject, BehaviorSubject} from 'rxjs';
import {KeyValue} from '@angular/common';
import {ConfirmActionModalComponent} from '../confirm-action-modal/confirm-action-modal.component';

@Component({
  selector: 'consultant-details-modal',
  templateUrl: './consultant-details-modal.component.html',
  styleUrls: ['./consultant-details-modal.component.scss'],
})
export class ConsultantDetailsModalComponent implements OnInit {
  form: UntypedFormGroup;
  submitted: boolean;
  invalidEmailError = '';
  requiredFieldsError = '';
  saveError = '';
  deleteError = '';

  pageTitle: string;
  confirmButtonText: string;
  existingConsultant: boolean;
  currentConsultant: Consultant;

  accessedCompanies: Map<string, string> = new Map<string, string>();
  excludeCompanyIds: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(
    [],
  );
  toBeAssigned: Map<string, string> = new Map<string, string>();
  toBeRemoved: Map<string, string> = new Map<string, string>();
  availableCompanies: Map<string, string> = new Map<string, string>();
  searchTerm$ = new Subject<string>();

  @Output() refreshEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private api: ApiService,
    private searchService: SearchService,
    public dialogRef: MatDialogRef<ConsultantDetailsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Consultant,
  ) {
    if (data === null) {
      this.existingConsultant = false;
      this.pageTitle = 'Lägg till konsult';
      this.confirmButtonText = 'LÄGG TILL';
    } else {
      this.existingConsultant = true;
      this.currentConsultant = data;
      data.accessedCompanies.forEach((company) => {
        this.accessedCompanies.set(company.id, company.name);
      });
      this.pageTitle = 'Redigera uppgifter';
      this.confirmButtonText = 'SPARA';
    }
  }

  ngOnInit(): void {
    this.submitted = false;
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      companyName: ['', Validators.required],
      phoneNr: ['', Validators.required],
    });
    if (this.existingConsultant) {
      this.form.setValue({
        name: this.data.user.name,
        email: this.data.user.email,
        companyName: this.data.companyName,
        phoneNr: this.data.user.phoneNr,
      });
    }
    this.excludeCompanyIds.next(Array.from(this.accessedCompanies.keys()));
    this.searchService
      .searchCompanies(this.searchTerm$, '3', undefined, this.excludeCompanyIds)
      .subscribe((response) => {
        this.availableCompanies.clear();
        response.companies.forEach((company) => {
          this.availableCompanies.set(company.id, company.name);
        });
      });
    this.searchTerm$.next('');
  }

  get formControls() {
    return this.form.controls;
  }

  get formValues() {
    return this.form.value;
  }

  getCompanyName(company: Company): string {
    return company && company.name ? company.name : '';
  }

  searchForCompanies(event: string): void {
    this.searchTerm$.next(event);
  }

  addCompany(event: KeyValue<string, string>): void {
    this.accessedCompanies.set(event.key, event.value);
    if (Array.from(this.toBeRemoved.keys()).includes(event.key)) {
      this.toBeRemoved.delete(event.key);
    } else {
      this.toBeAssigned.set(event.key, event.value);
    }
    this.excludeCompanyIds.next(Array.from(this.accessedCompanies.keys()));
  }

  removeCompany(event: string): void {
    this.accessedCompanies.delete(event);
    if (Array.from(this.toBeAssigned.keys()).includes(event)) {
      this.toBeAssigned.delete(event);
    } else {
      this.toBeRemoved.set(event, '');
    }
    this.excludeCompanyIds.next(Array.from(this.accessedCompanies.keys()));
  }

  saveConsultant(): void {
    this.invalidEmailError = '';
    this.requiredFieldsError = '';
    this.submitted = true;
    // Form input is valid and should be saved
    if (
      !(
        this.formControls.name.errors ||
        this.formControls.email.errors ||
        this.formControls.companyName.errors ||
        this.formControls.phoneNr.errors
      )
    ) {
      if (this.existingConsultant) {
        this.api.updateConsultant(this.data.id, this.formValues).subscribe(
          (response) => {
            this.saveAccessedCompanies(this.currentConsultant.id).then(() => {
              this.refreshEvent.emit(true);
              this.dialogRef.close();
            });
          },
          (error) => {
            this.setSaveError(error);
          },
        );
      } else {
        this.api.addConsultant(this.formValues).subscribe(
          (response: any) => {
            this.saveAccessedCompanies(response.consultantId);
            this.refreshEvent.emit(true);
            this.dialogRef.close();
          },
          (error) => {
            this.setSaveError(error);
          },
        );
      }
    }
      // Form input is invalid and cannot be saved
    // Display error messages
    else {
      const fields: string[] = [];
      if (this.formControls.name.errors) {
        fields.push('namn');
      }
      if (this.formControls.companyName.errors) {
        fields.push('företagsnamn');
      }
      if (this.formControls.email.errors) {
        if (this.formControls.email.errors.email)
          // Error message for invlid email format
        {
          this.invalidEmailError = 'Ange en giltig mailadress';
        } else {
          fields.push('mailadress');
        }
      }
      if (this.formControls.phoneNr.errors) {
        fields.push('telefonnummer');
      }

      // Error message for missing required input
      if (fields.length > 0) {
        this.requiredFieldsError = 'Fyll i ';
        while (fields.length > 2) {
          this.requiredFieldsError =
            this.requiredFieldsError + (fields.shift() + ', ');
        }
        if (fields.length === 2) {
          this.requiredFieldsError =
            this.requiredFieldsError +
            fields.shift() +
            ' och ' +
            fields.shift();
        }
        if (fields.length === 1) {
          this.requiredFieldsError = this.requiredFieldsError + fields.shift();
        }
      }
    }
  }

  saveAccessedCompanies(consultantId): Promise<void[]> {
    const allRequests: Promise<void>[] = [];

    this.toBeAssigned.forEach((companyName, companyId) => {
      allRequests.push(
        new Promise((resolve, reject) => {
          this.api.assignCompanyToConsultant(consultantId, companyId).subscribe(
            (response) => {
              resolve();
            },
            (error) => {
              resolve();
              console.log(error.error);
            },
          );
        }),
      );
    });

    this.toBeRemoved.forEach((companyName, companyId) => {
      allRequests.push(
        new Promise((resolve, reject) => {
          this.api
            .unassignCompanyFromConsultant(consultantId, companyId)
            .subscribe(
              (response) => {
                resolve();
              },
              (error) => {
                resolve();
                console.log(error.error);
              },
            );
        }),
      );
    });

    return Promise.all(allRequests);
  }

  setSaveError(error): void {
    const errorMessage = error.error as string;
    if (
      errorMessage.includes('unique_email') ||
      errorMessage.includes('UQ_d199be6d256ab14994e97c6fdd5')
    ) {
      this.saveError = 'Mailadressen är upptagen! Kunde inte spara ändringarna';
    } else {
      this.saveError = 'Något gick fel! \n Kunde inte spara ändringarna';
    }
  }

  openDeleteDialog(): void {
    const deleteDialogRef = this.dialog.open(ConfirmActionModalComponent, {
      width: '40%',
      panelClass: 'custom-dialog-no-padding',
      data: {
        pageTitle: 'Ta bort',
        pageBody:
          'Är du säker på att du vill ta bort konsulten? Denna åtgärd går inte att ångra.',
        confirmButton: 'TA BORT',
        confirmButtonColor: '#B85454',
      },
    });
    deleteDialogRef.componentInstance.confirmAction.subscribe(() => {
      this.api.removeConsultant(this.data.id).subscribe(
        (response) => {
          this.refreshEvent.emit(true);
          this.dialogRef.close();
        },
        (error) => {
          this.deleteError = 'Något gick fel! \n Kunde inte ta bort konsulten';
        },
      );
    });
    deleteDialogRef
      .afterClosed()
      .subscribe(() =>
        deleteDialogRef.componentInstance.confirmAction.unsubscribe(),
      );
  }
}
