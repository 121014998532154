import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CsvParserService {

  constructor() {
  }


  parseFile(file: File): Promise<string[]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onerror = () => {
        reject();
      };
      reader.onload = () => {
        resolve((reader.result as string).split('\n').map((value) => value.trim()).filter((value) => value));
      };
    });
  }
}
