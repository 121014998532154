<div class="flex justify-between w-full mb-3">
  <h2 class="tk-skolar-latin my-0 flex items-center">Beskrivning för konto</h2>
  <button mat-icon-button (click)="close()">
    <mat-icon class="mat-18" fontIcon="close"></mat-icon>
  </button>
</div>

<p class="font-light max-w-lg w-[32rem]">
  Skriv en beskrivning för {{ data.account.accountNumber }}
  {{ data.account.name }}.
</p>

<app-text-input-area
  [id]="'description-edit-area'"
  [maxLength]="200"
  (valueChange)="onDescriptionChange($event)"
  [initialValue]="data.account.description"
  class="mb-4 block"
></app-text-input-area>
<!-- <textarea
  name="description"
  [id]="data.account.accountNumber + '-description-edit'"
  rows="10"
  class="w-full pointer-events-auto p-2 outline-none resize-none cursor-default bg-transparent text-gray-500 font-light text-lg "
  [ngModel]="data.account.name"
  (ngModelChange)="onDescriptionChange($event)"
></textarea> -->
<div class="flex justify-center w-full">
  <button mat-flat-button color="primary" (click)="save()" class="rounded-full">
    <span class="text-white uppercase flex items-center">Spara</span>
  </button>
</div>
