import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { BookkeepForMe } from 'src/app/Models/bookkeep-for-me';

enum bookkeepForMeFilters {
  all = 0,
  contactCustomer = 1,
  received = 2,
}

@Component({
  selector: 'app-bookkeep-for-me',
  templateUrl: './bookkeep-for-me.component.html',
  styleUrls: ['./bookkeep-for-me.component.scss'],
})
export class BookkeepForMeComponent implements OnInit {
  activeFilter: bookkeepForMeFilters = bookkeepForMeFilters.all;
  unhandledEntries: Array<Array<BookkeepForMe>> = [];
  contactedEntries: Array<Array<BookkeepForMe>> = [];
  activeEntries: Array<Array<BookkeepForMe>> = [];

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    const promiseOfUnhandled = new Promise<void>((resolve, reject) => {
      this.api.getUnhandledBookkeepForMe().subscribe((entries) => {
        console.log('UNHANDLED');
        this.unhandledEntries = entries ? entries : [];
        resolve();
      });
    });
    const promiseOfContacted = new Promise<void>((resolve, reject) => {
      this.api.getContactedBookkeepForMe().subscribe((entries) => {
        console.log('CONTACTED');
        this.contactedEntries = entries ? entries : [];
        resolve();
      });
    });
    Promise.all([promiseOfUnhandled, promiseOfContacted]).then((res) => {
      console.log('RESOLVED');

      this.activeEntries = this.unhandledEntries.concat(this.contactedEntries);
      console.log(this.activeEntries);
    });
  }

  filterClicked(filter) {
    // Design decision: filters in this view are mutually exclusive --> no array, just an active filter
    this.activeEntries = [];
    switch (filter) {
      case bookkeepForMeFilters.all:
        this.activeFilter = bookkeepForMeFilters.all;
        this.activeEntries = this.unhandledEntries.concat(
          this.contactedEntries
        );
        break;
      case bookkeepForMeFilters.contactCustomer:
        this.activeFilter = bookkeepForMeFilters.contactCustomer;
        this.contactedEntries.forEach((entry) => {
          this.activeEntries.push(entry);
        });
        break;
      case bookkeepForMeFilters.received:
        this.activeFilter = bookkeepForMeFilters.received;
        this.activeEntries = this.unhandledEntries;
        break;

      default:
        break;
    }
    console.log(this.activeEntries);
    this.applyFilter();
  }
  applyFilter() {}
}
