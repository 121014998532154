import { Account } from './../../../Models/accounting-row';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TextInputAreaComponent } from "../../text-input-area/text-input-area.component";

export interface EditAccountDescriptionDialogData {
  account: Account;
}

export interface EditAccountDescriptionDialogResult {
  description: string;
  save: boolean;
}

@Component({
    selector: 'app-edit-account-description-dialog',
    standalone: true,
    templateUrl: './edit-account-description-dialog.component.html',
    styleUrls: ['./edit-account-description-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, MatIconModule, MatButtonModule, FormsModule, TextInputAreaComponent]
})
export class EditAccountDescriptionDialogComponent implements OnInit {
  private description: string;
  constructor(
    public dialogRef: MatDialogRef<EditAccountDescriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditAccountDescriptionDialogData,
  ) {}

  ngOnInit(): void {
    this.description = this.data.account.description;
  }

  close() {
    const data: EditAccountDescriptionDialogResult = {
      description: this.description,
      save: false,
    };

    this.dialogRef.close(data);
  }

  save() {
    const data: EditAccountDescriptionDialogResult = {
      description: this.description,
      save: true,
    };

    this.dialogRef.close(data);
  }

  onDescriptionChange(value: string) {
    this.description = value;
  }
}
