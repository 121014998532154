
export class Row {
  order: number;
  amount: number;
  account: number;
  debit: boolean;
  constructor(
    order?: number,
    amount?: number,
    account?: number,
    debit?: string,
  ) {
    this.order = typeof order === 'number' ? order : null;
    this.amount = amount ? amount : null;
    this.account = account ? account : null;
    this.debit = debit === 'debit' ? true : false;
  }
}
export class Verification {
  financialYearId: string;
  series: string;
  accountingDate: string;
  description: string;
  rows: Array<Row>;
  images: Array<string>;
  otherInfo: any;
  bookkeepForMeId?: string;
  constructor() {}
}

export interface VerificationResponse {
  number: number;
  id: string;
}

export interface FinancialYear {
  id: string;
  start: Date;
  end: Date;
  locked: boolean;
}

export interface Account {
  id: string;
  accountNumber: number;
  name: string;
  description: string;
}
