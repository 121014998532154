<div class="chat-item-container" *ngIf="!isMobile; else mobile">
  <div class="left-container">
    <div class="info-container">
      <p class="sub-label">{{ chat.createdAt | date: 'y-MM-dd' }}</p>
      <p class="label">
        <span class="blue-text">
          {{
            chat.customer
              ? chat.customer.name
                ? chat.customer.name
                : chat.companyName
                ? chat.companyName
                : 'Okänd'
              : 'Okänd'
          }}</span
        >
        har påbörjat en chatt
      </p>
    </div>
  </div>
  <div class="right-container">
    <p class="advisor"></p>
    <button class="confirm-button blue-button" (click)="enterChat()">
      ÖPPNA ÄRENDE <i class="fa fa-arrow-right arrow-right"></i>
    </button>
  </div>
</div>

<ng-template #mobile>
  <div class="mobile-container">
    <div class="mobile-left">
      <p class="mobile-date">{{ chat.createdAt | date: 'y-MM-dd' }}</p>
      <p class="mobile-label">{{ chat.customer.name }} vill starta en chatt</p>
    </div>
    <div class="mobile-right">
      <button class="mobile-join" (click)="enterChat()">
        <i class="fa fa-arrow-right mobile-arrow-right"></i>
      </button>
    </div>
  </div>
</ng-template>
