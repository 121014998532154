import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { LeaveChatComponent } from '../Modals/leave-chat/leave-chat.component';
import { CompanyChatComponent } from '../Pages/chat/company-chat/company-chat.component';
import { AuthenticationService } from '../Services/auth.service';
import { ChatService } from '../Services/chat.service';

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateChatGuard
  implements CanDeactivate<CompanyChatComponent> {
  constructor(
    private auth: AuthenticationService,
    private dialog: MatDialog,
    private chatService: ChatService
  ) {}

  canDeactivate(
    component: CompanyChatComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Promise<boolean> | boolean {
    if (
      component.chat.status !== 'finished' &&
      component.chat.consultantConnected &&
      component.chat.consultant.id === this.auth.getUserId()
    ) {
      return new Promise<boolean>((resolve, reject) => {
        console.log('guard running');
        const dialogRef = this.dialog
          .open(LeaveChatComponent)
          .afterClosed()
          .subscribe((keepAssignement: boolean) => {
            if (typeof keepAssignement === 'undefined') {
              reject(false);
            } else if (!keepAssignement) {
              this.chatService
                .updateChat(component.chat.id, 'null')
                .subscribe((res) => {
                  console.log(res);
                  resolve(true);
                });
            } else {
              resolve(true);
            }
          });
      })
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
    } else {
      return true;
    }
  }
}
