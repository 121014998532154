import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../../Services/auth.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  form: UntypedFormGroup;
  loading = false;
  submitted = false;
  error = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private auth: AuthenticationService,
    private router: Router
  ) {
    if (!this.auth.isTokenExpired()) {
      if (this.auth.mobilCheck()) {
        this.router.navigateByUrl('/chat');
      } else {
        this.router.navigateByUrl('/search');
      }
    }
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });

    this.onChanges();
  }

  onChanges(): void {
    this.form.valueChanges.subscribe(val => {
      this.error = '';
    });
  }

  get formControls() {
    return this.form.controls;
  }

  get formValues() {
    return this.form.value;
  }

  login() {
    this.submitted = true; 
    this.auth.login(this.formValues.username, this.formValues.password).subscribe(
      (resp) => {
        console.log(resp);
        this.auth.setToken(JSON.stringify(resp));
        if (this.auth.mobilCheck()) {
          this.router.navigateByUrl('/chat');
        } else {
          this.router.navigateByUrl('/search');
        }
      },
      (error) => {
        switch (error.error) {
          case "NO_SUCH_USER":
            this.error = 'Okänt användarnamn';
            break;
          case "INVALID_PASSWORD": 
            this.error = 'Felaktigt lösenord';
            break; 
          default: 
            this.error = 'Kunde inte logga in';
        }
      }
    );
  }

  forgotPassword() {
    localStorage.setItem('forgotPasswordEmail', this.formValues.username);
    this.router.navigateByUrl('/forgot-password');
  }
}
