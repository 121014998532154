<div class="surrounding-container">
  <img
    src="../../../assets/WREBIT_Logotyp.svg"
    alt="Wrebit Logo Green"
    class="logo"
  />
  <h1 class="sublogo">Portalen</h1>
  <form [formGroup]="form" (ngSubmit)="login()" class="login-form">
    <div class="form-group">
      <input
        type="email"
        formControlName="username"
        required
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && formControls.username.errors }"
        placeholder="Mail"
      />
    </div>

    <div class="form-group">
      <input
        type="password"
        formControlName="password"
        required
        class="form-control"
        [ngClass]="{ 'is-invalid': submitted && formControls.password.errors }"
        placeholder="Lösenord"
      />
    </div>
    <div class="input-errors">
      <div *ngIf="submitted && formControls.username.errors" class="error-feedback">
        <p *ngIf="formControls.username.errors.required">Fyll i din mailadress!</p>
      </div>
      <div *ngIf="submitted && formControls.password.errors" class="error-feedback">
        <p *ngIf="formControls.password.errors.required">Fyll i ditt lösenord!</p>
      </div>
      <p class="error-feedback" *ngIf="error && !formControls.username.errors && !formControls.password.errors">{{ error }}</p>
    </div>
    <div class="form-group">
      <button [disabled]="loading" type="submit" class="submit-btn">
        LOGGA IN
      </button>
    </div>
  </form>
  <a class="txt-btn forget-btn" (click)="forgotPassword()">GLÖMT LÖSENORD?</a>
</div>
