import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Form, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-advice-handle',
  templateUrl: './advice-handle.component.html',
  styleUrls: ['./advice-handle.component.scss'],
})
export class AdviceHandleComponent implements OnInit {
  minutesControl: UntypedFormControl = new UntypedFormControl();
  bfmPriceControl: UntypedFormControl = new UntypedFormControl();
  messageControl: UntypedFormControl = new UntypedFormControl();
  id: string = '';
  type: string = '';

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.type = this.route.snapshot.queryParams.type;
  }

  makeHandled(): void {
    switch (this.type) {
      case 'bookkeep-for-me':
        this.api
          .handleBookkeepForMe(
            this.id,
            null,
            null,
            null,
            null,
            +this.minutesControl.value,
            this.bfmPriceControl.value ? +this.bfmPriceControl.value : undefined,
          )
          .subscribe(() => {
            this.router.navigate(['/history']);
          });
        break;

      default:
        this.api
          .setHandledAdvice(
            this.id,
            +this.minutesControl.value,
            this.messageControl.value,
          )
          .subscribe((response: any): void => {
            console.log(response);
            this.router.navigate(['/history']);
          });
        break;
    }
  }

  ConvertStringToNumber(input: string): number {
    let numeric = parseInt(input);
    if (!isNaN(numeric)) {
      return numeric;
    }
    return 0;
  }
}
