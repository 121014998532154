<ng-container *ngIf="finishedLoading || !isLoading(); else loading">
  <ng-container *ngIf="isMobile; then mobile; else desktop"></ng-container>
</ng-container>

<ng-template #loading>
  <loading></loading>
</ng-template>

<ng-template #desktop>
  <div class="chat-page page">
    <back-button (click)="leave()"></back-button>
    <div class="top-row">
      <company-info [company]="company"></company-info>
      <div class="assigned-info">
        <p class="label">Ärendet är tilldelat:</p>
        <p class="name">
          {{ chat.consultant ? chat.consultant.name : "Ingen" }}
        </p>
      </div>
    </div>
    <div class="app-container" id="app-window">
      <img src="../../../assets/screen.png" alt="" class="screen" />
      <div class="app-container" *ngIf="url">
        <iframe [src]="url" frameborder="0" id="app-frame"></iframe>
      </div>
    </div>
    <div class="chat-window">
      <div class="messages" id="chat-scroll-window">
        <app-chat-message
          *ngFor="let message of chat.messages; let i = index"
          [message]="message"
          [prevMessage]="chat.messages[i - 1]"
          [nextMessage]="chat.messages[i + 1]"
          tabindex="1"
          class="message"
        ></app-chat-message>
      </div>
      <div
        class="chat-inputs"
        *ngIf="(connected && chat.status !== 'finished') || isConsultant()"
      >
        <textarea
          type="text"
          class="message-input"
          [(ngModel)]="message"
          placeholder="Meddelande"
          ngDefaultControl
          rows="3"
        ></textarea>

        <button
          class="confirm-button send-button"
          (click)="sendMessage()"
          [disabled]="message.length === 0 || chat.status === 'finished'"
          [ngClass]="{ disabled: message.length === 0 }"
        >
          Skicka
        </button>
      </div>
      <button
        class="confirm-button connect-button"
        *ngIf="
          !connected &&
          !isConsultant() &&
          chat.status !== 'finished' &&
          !chat.consultant
        "
        (click)="connectToChat()"
      >
        ANSLUT MIG TILL KONVERSATIONEN
      </button>
    </div>
    <!--
  <history-list class="history-list" [companyId]="company.id"></history-list>-->

    <div class="misc-info-container">
      <p class="title">Taggar</p>
      <div class="active-tags">
        <button
          class="tag"
          *ngFor="let tag of tags; let i = index"
          (click)="toggleTag(tag)"
          [ngClass]="{ 'active-tag': tag.active }"
        >
          {{ tag.name }}
        </button>
      </div>
      <div class="add-tag-container">
        <expanding-input-field
          (onSubmit)="addTag($event)"
          [autocompleteOptions]="previouslyUsedTags"
        ></expanding-input-field>
      </div>
      <button
        class="send-confirmation confirm-button"
        [disabled]="
          this.chat.status === 'pending' ||
          this.chat.status === 'confirmed' ||
          this.chat.status === 'finished' ||
          !this.chat.consultant
        "
        [ngClass]="{
          pending: chat.status === 'pending',
          confirmed: chat.status === 'confirmed' || chat.status === 'finished'
        }"
        (click)="sendConfirmation()"
      >
        {{
          chat.status === "pending"
            ? "UPPGIFTSKONTROLL SKICKAD"
            : chat.status === "confirmed"
            ? "UPPFIFTER BEKRÄFTADE"
            : chat.status === "finished"
            ? "CHATT AVSLUTAD"
            : "SKICKA UPPGIFTSKONTROLL"
        }}
      </button>
      <button
        class="confirm-button end-button"
        [ngClass]="{
          confirmed: chat.status === 'finished'
        }"
        (click)="endChat()"
        [disabled]="chat.status === 'finished'"
      >
        {{ chat.status === "finished" ? "CHATT AVSLUTAD" : "AVSLUTA CHATT" }}
      </button>
    </div>
  </div></ng-template
>

<ng-template #mobile>
  <div
    class="mobile-page"
    id="mobile-chat-page"
    [ngStyle]="{
      'height.px': innerHeight,
      'min-height.px': innerHeight,
      'max-height.px': innerHeight
    }"
  >
    <div
      class="chat-window mobile-chat-window"
      [ngStyle]="{
        'height.px': innerHeight - 190,
        'min-height.px': innerHeight - 190,
        'max-height.px': innerHeight - 190
      }"
    >
      <div
        class="mobile-messages"
        id="mobile-chat-scroll-window"
        [ngStyle]="{
          'height.px': innerHeight - 190,
          'min-height.px': innerHeight - 190,
          'max-height.px': innerHeight - 190
        }"
      >
        <app-chat-message
          *ngFor="let message of chat.messages; let i = index"
          [message]="message"
          [prevMessage]="chat.messages[i - 1]"
          [nextMessage]="chat.messages[i + 1]"
          class="message"
        ></app-chat-message>
      </div>
    </div>
    <div
      class="chat-inputs mobile-chat-inputs"
      id="mobile-inputs"
      *ngIf="(connected && chat.status !== 'finished') || isConsultant()"
    >
      <button mat-button class="mobile-options" (click)="showOptions()">
        <i class="fa fa-ellipsis-h"></i>
      </button>
      <textarea
        type="text"
        class="message-input mobile-message-input"
        [(ngModel)]="message"
        placeholder="Meddelande"
        ngDefaultControl
        rows="3"
      ></textarea>

      <button
        mat-button
        class="confirm-button send-button mobile-send-button"
        (click)="sendMessage()"
        [disabled]="message.length === 0 || sending"
        [ngClass]="{ disabled: message.length === 0 }"
      >
        <i class="fa fa-paper-plane send-icon"></i>
      </button>
    </div>
    <button
      class="confirm-button connect-button"
      *ngIf="
        !connected &&
        !isConsultant() &&
        chat.status !== 'finished' &&
        !chat.consultant
      "
      (click)="connectToChat()"
    >
      ANSLUT MIG TILL KONVERSATIONEN
    </button>
  </div>
</ng-template>
