import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {CreateNewsRequestDTO, CreateNewsResponseDTO, GetInboxItemsAsAdminResponseDTO} from '@hawco/wrebit-contracts';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient) {
  }


  getNews() {
    return this.http.get<GetInboxItemsAsAdminResponseDTO>(`${environment.apiUrl}/news-inbox`);
  }

  createNews(news: CreateNewsRequestDTO) {
    return this.http.post<CreateNewsResponseDTO>(`${environment.apiUrl}/news-inbox`, news);
  }

  inactivateNews(id: string) {
    return this.http.delete(`${environment.apiUrl}/news-inbox/${id}`);
  }
}
