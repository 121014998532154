import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {provideComponentStore} from '@ngrx/component-store';
import {CreateNewsPageStore} from './news-create-page.store';
import {FileInputComponent} from '../../Components/file-input/file-input.component';
import {CsvParserService} from '../../Services/csv-parser.service';
import {MatSelectChange, MatSelectModule} from '@angular/material/select';
import {TextInputComponent} from '../../Components/text-input/text-input.component';
import {ImageService} from '../../Services/image.service';
import {forkJoin, from, take} from 'rxjs';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TextInputAreaComponent} from '../../Components/text-input-area/text-input-area.component';
import {MatRadioModule} from '@angular/material/radio';
import {FormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {environment} from '../../../environments/environment';
import {CtaTypes, NewsInboxItemSlide, NewsTemplatesModule} from '@hawco/wrebit-news-templates';
import {NewsService} from '../../Services/news.service';
import {Router} from '@angular/router';
import {CreateNewsRequestDTO, ParagraphModel} from '@hawco/wrebit-contracts';
import imageCompression from 'browser-image-compression';
import {switchMap} from 'rxjs/operators';

interface TemplateOption {
  label: string;
  value: number;
}

interface InAppLinkOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-news-create-page',
  standalone: true,
  imports: [
    CommonModule,
    FileInputComponent,
    MatSelectModule,
    TextInputComponent,
    MatProgressSpinnerModule,
    TextInputAreaComponent,
    MatRadioModule,
    FormsModule,
    MatButtonModule,
    NewsTemplatesModule
  ],
  providers: [provideComponentStore(CreateNewsPageStore)],
  templateUrl: './news-create-page.component.html',
  styleUrls: ['./news-create-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsCreatePageComponent implements OnInit {
  villainUrl = environment.villainUrl;
  readonly previewDate = new Date().toISOString();
  templateOptions: TemplateOption[] = [{label: 'Template 1', value: 0}];
  inAppLinkOptions: InAppLinkOption[] = [{label: 'Experthjälp', value: 'expert-help'}];
  buttonType = 1;
  templateRef: number;
  private companyIdFile: File;
  private imageUrl: string;
  private thumbnailImageUrl: string;
  private title = '';
  private paragraphs: ParagraphModel[] = [];
  private buttonName = '';
  private inAppLink: string;
  private externalAppLink: string;

  constructor(public store$: CreateNewsPageStore,
              private csvParser: CsvParserService,
              private imageService: ImageService,
              private newsService: NewsService,
              private router: Router,
  ) {
  }

  private get ctaType(): CtaTypes {
    switch (this.buttonType) {
      case 1:
        return CtaTypes.internalNavigation;
        break;
      case 2:
        return CtaTypes.externalNavigation;
        break;
      default:
        return undefined;
        break;
    }
  }

  private get backendCTAType(): string {
    switch (this.ctaType) {
      case CtaTypes.internalNavigation:
        return 'internal-url';
        break;
      case CtaTypes.externalNavigation:
        return 'external-url';
        break;
      default:
        return undefined;
        break;

    }
  }

  private get ctaNavigationPath() {
    switch (this.buttonType) {
      case 1:
        return this.inAppLink;
        break;
      case 2:
        return this.externalAppLink;
        break;
      default:
        return undefined;
        break;
    }
  }

  get isSlideValid(): boolean {
    return !!this.previewSlide.title && !!this.previewSlide.paragraphs.length && !!this.previewSlide?.ctas[0]?.text &&
      this.previewSlide.templateRef !== undefined && !!this.previewSlide.imageUrls;
  }

  get previewSlide(): NewsInboxItemSlide {
    const data: NewsInboxItemSlide = {
      id: 'preview',
      templateRef: this.templateRef,
      title: this.title,
      paragraphs: this.paragraphs,
      imageUrls: [this.imageUrl],
      videoUrls: [],
      ctaId: 'previewCta',
      ctas: [],
      viewedState: {
        isRead: true,
        companyId: 'test'
      }
    };

    if (this.buttonType !== 0 && this.buttonName) {
      data.ctas.push({
        type: this.ctaType,
        navigationPath: this.ctaNavigationPath,
        text: this.buttonName
      });
    }

    return data;
  }

  ngOnInit(): void {
  }

  onCompanyIdFileChange(file: File) {
    this.companyIdFile = file;
  }

  onTitleChange(title: string) {
    this.title = title;
    if (this.paragraphs.length) {
      this.paragraphs[0].title = title;
    }
  }

  onUrlChange(url: string) {
    this.externalAppLink = url;
  }

  onInAppLinkChange(event: MatSelectChange) {
    this.inAppLink = event.value;
  }

  onButtonNameChange(buttonName: string) {
    this.buttonName = buttonName;
  }

  onTextChange(text: string) {
    this.paragraphs = text
      .split('\n')
      .filter((paragraph) => paragraph.length)
      .map((paragraph) => ({
        title: '',
        content: paragraph.trim()
      }));
    this.paragraphs[0].title = this.title;
  }

  onImageFileChange(file: File) {
    this.store$.setUploadStatus('loading');
    const compressOptions = {
      maxWidthOrHeight: 120,
      useWebWorker: true,
    };
    const compressed = from(imageCompression(file, compressOptions))
      .pipe(
        take(1),
        switchMap((compressedFile) => this.imageService.uploadImage(compressedFile)
          .pipe(take(1))));
    const mainImage = this.imageService.uploadImage(file).pipe(take(1));
    forkJoin([compressed, mainImage])
      .subscribe({
        next: (result) => {
          if (!result[0]?.url || !result[1]?.url) {
            this.store$.setUploadStatus('finished');

            alert('Kunde inte ladda upp bilden. Försök med en annan eller kontakt support');
            return;
          }

          this.thumbnailImageUrl = result[0].url;
          this.imageUrl = result[1].url;
          this.store$.setUploadStatus('finished');
        },
        error: (error) => {
          this.store$.setUploadStatus('finished');
          alert('Kunde inte ladda upp bilden. Försök med en annan eller kontakt support');
        }
      });
  }

  templateSelectChange(event: MatSelectChange) {
    this.templateRef = event.value as number;
  }

  async send() {
    this.store$.setStatusLoading();
    try {
      const companyIds = await this.csvParser.parseFile(this.companyIdFile);
      const payload: CreateNewsRequestDTO = {
        companyIds,
        inboxItem: {
          title: this.title,
          thumbnailImageUrl: this.thumbnailImageUrl,
          slides: [
            {
              templateRef: this.templateRef,
              title: this.title,
              paragraphs: this.paragraphs,
              imageUrls: [this.imageUrl],
              videoUrls: [],
              inboxItemId: undefined,
              ctas: []
            }
          ],
        },
      };
      if (this.buttonType !== 0 && this.buttonName) {
        payload.inboxItem.slides[0].ctas.push({
          type: this.backendCTAType as any,
          navigationPath: this.ctaNavigationPath,
          text: this.buttonName
        });
      }
      this.newsService.createNews(payload).pipe(take(1)).subscribe({
        next: (response) => {
          this.store$.setStatusSuccess();
          alert('Nyhet publicerad');
          this.router.navigate(['/news']);

        }, error: (error) => {
          this.store$.setStatusFailure();
          alert('Kunde inte skapa nyhet, försök igen eller kontakta support');
        }
      });
    } catch (error) {
      alert('Ogiltig fil');
      this.store$.setStatusFailure();
    }

  }


}
