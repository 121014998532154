import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {NewsService} from '../../Services/news.service';
import {concatMap, Observable, of, tap} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {GetInboxItemsAsAdminResponseDTO} from '@hawco/wrebit-contracts';
import {InboxItemWithViewStats} from '@hawco/wrebit-contracts';

export interface NewsPageState {
  status: 'loading' | 'success' | 'failure';
  activeNews: InboxItemWithViewStats[];
  inactiveNews: InboxItemWithViewStats[];
}

const defaultState: NewsPageState = {
  status: 'loading',
  activeNews: [],
  inactiveNews: [],
};

@Injectable()
export class NewsPageStore extends ComponentStore<NewsPageState> {
  constructor(private newsService: NewsService) {
    super(defaultState);
  }

  // SELECTORS
  readonly status$ = this.select(({status}) => status);
  readonly activeNews$ = this.select(({activeNews}) => activeNews);
  readonly inactiveNews = this.select(({inactiveNews}) => inactiveNews);

  // EFFECTS
  readonly getNews = this.effect((trigger$: Observable<void>) =>
    trigger$
      .pipe(
        concatMap(() => this.newsService.getNews()),
        catchError(() => of(null)),
        tap((response: GetInboxItemsAsAdminResponseDTO | null) => {
          if (response === null) {
            this.setStatusFailure();
          } else {
            const active = response.filter((item) => !item.deleted);
            const inactive = response.filter((item) => item.deleted);
            this.updateActiveNews(active);
            this.updateInactiveNews(inactive);
          }
        })
      )
  );

  readonly deactivateNews = this.effect((id$: Observable<string>) => id$.pipe(
    concatMap((id) => this.newsService.inactivateNews(id).pipe(map((response) => ({response, id})))),
    catchError((err, caught) => of(null)),
    tap((value: { response: any, id: string } | null) => {
      if (value === null) {
        return;
      }
      this.moveActiveNewsToInactive(value.id);
    })
  ));

  // UPDATERS

  readonly updateActiveNews = this.updater((state, value: InboxItemWithViewStats[]) => ({...state, status: 'success', activeNews: value}));
  readonly updateInactiveNews = this.updater((state, value: InboxItemWithViewStats[]) => ({
    ...state,
    status: 'success',
    inactiveNews: value
  }));
  readonly moveActiveNewsToInactive = this.updater((state, id: string) => {
    const indexToMove = state.activeNews.findIndex((news) => news.id === id);
    const activeCopy = [...state.activeNews];
    const inactiveCopy = [...state.inactiveNews];
    const newsItemToMove = activeCopy.splice(indexToMove, 1);
    inactiveCopy.unshift(...newsItemToMove);
    return {
      ...state,
      activeNews: activeCopy,
      inactiveNews: inactiveCopy,
    };
  });
  readonly setStatusFailure = this.updater((state) => ({...state, status: 'failure'}));


}
