<div class="surrounding-container">

    <img
      src="../../../assets/WREBIT_Logotyp.svg"
      alt="Wrebit Logo Green"
      class="logo"
    />

    <div class="sublogo">
        <mat-icon
        class="material-icon-round"
        >done</mat-icon>
    </div>
   
    <div class="confirmation-section">
        <div class="form-group confirmation-text">
            <div>{{ confirmationText }}</div>
        </div>
        <div class="form-group"> 
            <button type="submit" class="submit-btn" (click)="toHomepage()">
                TILL STARTSIDA
            </button>
        </div>
    </div>

  </div>
