<div class="container-misc">
  <div class="misc-info">
    <div class="info">
      <div class="info-label">Firmanamn</div>
      <div class="info-text">
        {{ company ? (company.name ? company.name : "Okänt") : "Okänt" }}
      </div>
    </div>
    <div class="info">
      <div class="info-label">Orgnr</div>
      <div class="info-text">
        {{ company ? (company.orgNr ? company.orgNr : "Okänt") : "Okänt" }}
      </div>
    </div>
    <div class="info">
      <div class="info-label">Nuvarande räkenskapsår</div>
      <div class="info-text">
        {{ financialYear ? (financialYear.start | date: "yyyy-MM-dd") : "Okänt" }}
        -
        {{ financialYear ? (financialYear.end | date: "yyyy-MM-dd") : "Okänt" }}
      </div>
    </div>
    <div class="info">
      <div class="info-label">Telefon</div>
      <div class="info-text">
        {{ company ? (company.phoneNr ? company.phoneNr : "okänt") : "Okänt" }}
      </div>
    </div>
    <div class="info">
      <div class="info-label">Email</div>
      <div class="info-text">
        {{ company ? (company.email ? company.email : "Okänt") : "Okänt" }}
      </div>
    </div>
  </div>
</div>
