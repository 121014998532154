<div class="page">
  <h1 class="page-title">Sök företag</h1>
  <div class="search-container">
    <div class="search-bar-container">
      <input
        type="text"
        [(ngModel)]="value"
        class="search-bar"
        placeholder="Sök företag (Org nr, mail, företagsnamn etc.)"
        (keyup)="searchTerm$.next($event.target.value)"
      />

      <div class="search-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          id="search-icon"
          width="36px"
          height="36px"
        >
          <path
            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </div>
    </div>

    <div class="mat-spinner-container" *ngIf="!hasLoaded; else loadedCompanies">
      <mat-spinner diameter="50"></mat-spinner>
    </div>
  </div>
</div>

<ng-template #loadedCompanies>
  <div *ngIf="availableCompanies">
    <p class="results-title">Resultat ({{ resultsCount }})</p>
    <div class="results-container">
      <search-list-item
        *ngFor="let company of companies"
        [company]="company"
      ></search-list-item>
    </div>
  </div>
  <p *ngIf="!availableCompanies" class="no-companies-title">
    {{ noCompaniesText }}
  </p>
</ng-template>
