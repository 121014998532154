import { KeyValue } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiService } from 'src/app/Services/api.service';
import { AuthenticationService } from 'src/app/Services/auth.service';
import { SearchService } from 'src/app/Services/search.service';

@Component({
  selector: 'search-list-item',
  templateUrl: './search-list-item.component.html',
  styleUrls: ['./search-list-item.component.scss'],
})
export class SearchListItemComponent implements OnInit {
  expanded: boolean = false;
  isHovered: boolean = false;
  @Input() company: any;
  integrations: string[] = [];
  isAdmin: boolean;

  assignedConsultants: Map<string, string> = new Map<string, string>();
  availableConsultants: Map<string, string> = new Map<string, string>();
  searchTerm$ = new Subject<string>();

  constructor(
    private router: Router,
    private searchService: SearchService,
    private auth: AuthenticationService,
    private api: ApiService,
  ) {}

  ngOnInit(): void {
    this.company.availableConsultants?.forEach((consultant) => {
      this.assignedConsultants.set(consultant.id, consultant.user.name);
    });
    this.company.integrations?.forEach((integration) => {
      if (
        (integration.typeTag && integration.typeTag != 'izettle') ||
        (integration.typeTag &&
          integration.typeTag == 'izettle' &&
          integration.metadata.access_token)
      ) {
        this.integrations.push(integration.typeTag);
      }
    });
    this.isAdmin = this.auth.isAdmin();
  }

  companyClicked(): void {
    this.expanded = !this.expanded;
    if (this.expanded && this.isAdmin) {
      this.searchService
        .searchConsultants(this.searchTerm$, '3', this.company.id, undefined)
        .subscribe((response) => {
          this.availableConsultants.clear();
          response.consultants.forEach((consultant) => {
            this.availableConsultants.set(consultant.id, consultant.user.name);
          });
        });
      this.searchTerm$.next('');
    }
  }

  enterCompany() {
    let company = JSON.stringify(this.company);
    localStorage.setItem('villainCompany', company);
    this.router.navigate(['/app']);
  }

  searchForConsultants(event: string): void {
    this.searchTerm$.next(event);
  }

  addConsultant(event: KeyValue<string, string>): void {
    this.api.assignCompanyToConsultant(event.key, this.company.id).subscribe(
      (response) => {},
      (error) => {
        console.log(error.error);
      },
    );
    this.assignedConsultants.set(event.key, event.value);
  }

  removeConsultant(event): void {
    this.api.unassignCompanyFromConsultant(event, this.company.id).subscribe(
      (response) => {},
      (error) => {
        console.log(error.error);
      },
    );
    this.assignedConsultants.delete(event);
  }

  setHovered(isHovered: boolean): void {
    this.isHovered = isHovered;
  }
}
