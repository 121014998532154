<div class="container">
  <input
    id="expandInput"
    type="text"
    placeholder="Lägg till tag..."
    [formControl]="myControl"
    [matAutocomplete]="auto"
    (keyup.enter)="clickInput()"
  />
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
    <mat-option *ngFor="let option of filteredOptions" [value]="option">
      {{ option }}
    </mat-option>
  </mat-autocomplete>
  <div (click)="clickInput()" class="search">
    <i class="fa fa-plus"></i>
  </div>
</div>
