<div class="container">

    <div class="header">
        <h1 class="page-title">{{pageTitle}}</h1>
    </div>
    <div class="body">
        <h2 class="page-text">{{pageBody}}</h2>
    </div>
    <div class="bottom-button-section">
        <button 
        class="confirm-button"
        (click)="confirm()"
        [ngStyle]="{'background-color': confirmButtonColor}">
            {{confirmButton}}
        </button>
    </div>
    <div class="close-button-section">
        <button mat-icon-button mat-dialog-close>
            <mat-icon class="material-icon-close">
                close
            </mat-icon>
        </button>
    </div>

</div>
