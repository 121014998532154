<div class="page flex justify-center flex-row  w-full">
  <div class="max-w-screen-lg w-full flex flex-row">
    <div class="w-1/2 pt-24">
      <!--    LEFT SIDE     -->
      <app-file-input class="" [label]="'Ladda upp målgrupp(.csv fil)'" accept=".csv"
                      (fileChange)="onCompanyIdFileChange($event)"></app-file-input>
      <h6 class="tk-skolar-latin m-0 text-base font-bold mt-4">Välj template</h6>
      <mat-form-field appearance="outline" class="w-full block">
        <mat-select (selectionChange)="templateSelectChange($event)">
          <mat-option *ngFor="let option of templateOptions" [value]="option.value">{{option.label}}</mat-option>
        </mat-select>
      </mat-form-field>
      <app-text-input [id]="'title-input'" [label]="'Rubrik'" class="mt-8 block" maxLength="60"
                      (valueChange)="onTitleChange($event)"></app-text-input>
      <app-file-input [label]="'Välj bild'" class="block mt-4" (fileChange)="onImageFileChange($event)"
                      [accept]="'image/png, image/jpeg'" [secondaryLabel]="'max 400x400px'"></app-file-input>
      <app-text-input-area class="mt-4 block" [id]="'paragraph-input'" [label]="'Text'" maxLength="300"
                           (valueChange)="onTextChange($event)"></app-text-input-area>
      <app-text-input [id]="'button-name-input'" [label]="'Knappnamn'" class="mt-8 block" maxLength="10"
                      (valueChange)="onButtonNameChange($event)"></app-text-input>
      <mat-radio-group [(ngModel)]="buttonType" aria-label="select button type"
                       class="w-10/12 flex justify-between mt-4">
        <mat-radio-button [value]="0" [disabled]="true">Ingen CTA</mat-radio-button>
        <mat-radio-button [value]="1">In-app</mat-radio-button>
        <mat-radio-button [value]="2">URL</mat-radio-button>
      </mat-radio-group>
      <ng-container [ngSwitch]="buttonType">
        <ng-container *ngSwitchCase="1">
          <h6 class="tk-skolar-latin m-0 text-base font-bold mt-6">Välj in-app-länkning</h6>
          <mat-form-field appearance="outline" class="w-full block">
            <mat-select (selectionChange)="onInAppLinkChange($event)">
              <mat-option *ngFor="let option of inAppLinkOptions" [value]="option.value">{{option.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
        <ng-container *ngSwitchCase="2">
          <app-text-input [id]="'url-input'" [label]="'URL'" class="mt-6 block"
                          (valueChange)="onUrlChange($event)"></app-text-input>
        </ng-container>
      </ng-container>

      <div class="w-full flex justify-center mt-8 mb-12">
        <button mat-flat-button color="primary" class="rounded-full w-52 h-12" (click)="send()"
                [disabled]="(store$.uploadLoading$ | async) || (store$.sendLoading$ | async)">PUBLICERA NYHET
        </button>
      </div>
    </div>

    <div class="w-1/2">
      <!--    RIGHT SIDE    -->
      <div class="w-full h-full flex justify-center items-center relative" [ngSwitch]="templateRef" *ngIf="isSlideValid">
        <app-default-template *ngSwitchCase="templateOptions[0].value" [slide]="previewSlide" [date]="previewDate"
                              class="preview absolute border-black rounded-2xl w-2/3 h-2/3 p-4"></app-default-template>
      </div>
    </div>
  </div>
</div>

<div class="absolute w-screen h-screen opacity-40 bg-black flex justify-center items-center z-50 left-0 top-0"
     *ngIf="(store$.uploadLoading$ | async) || (store$.sendLoading$ | async)">
  <mat-spinner diameter="50"></mat-spinner>
</div>
