import {Component, OnInit, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {HandleIssueModalComponent} from 'src/app/Modals/handle-issue-modal/handle-issue-modal.component';
import {Advice} from 'src/app/Models/advice';
import {BookkeepForMe} from 'src/app/Models/bookkeep-for-me';
import {Chat} from 'src/app/Models/chat';
import {ChatService} from 'src/app/Services/chat.service';

@Component({
  selector: 'app-history-item',
  templateUrl: './history-item.component.html',
  styleUrls: ['./history-item.component.scss'],
})
export class HistoryItemComponent implements OnInit {
  @Input() item: any;
  iconSrc: string;
  date: Date;
  adviceType: string;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private chatService: ChatService,
  ) {
  }

  ngOnInit(): void {
    if (this.item && this.item.type) {
      switch (this.item.type) {
        case 'phone':
          this.iconSrc = '../../../assets/phone-icon.svg';
          this.adviceType = 'Telefon';
          this.date = (this.item as Advice).timeslot.start;
          break;
        case 'email':
          this.iconSrc = '../../../assets/mail-icon.svg';
          this.adviceType = 'Mail';
          this.date = (this.item as any).issued;
          break;
        case 'bookkeep-for-me':
          this.iconSrc = '../../../assets/receipt-icon.svg';
          this.adviceType = 'Bokfört åt kund';
          this.date = (this.item as BookkeepForMe).handled;
          break;
        case 'chat':
          this.iconSrc = '../../../assets/chat-bubble.svg';
          this.adviceType = 'Chatt';
          this.date = (this.item as Chat).chatFinished;
          break;

        default:
          break;
      }
    } else {
      this.iconSrc = '';
      this.adviceType = 'Okänt';
    }
  }

  handleItem() {
    switch (this.item.type) {
      case 'chat':
        const dialogRef = this.dialog.open(HandleIssueModalComponent, {
          data:
            {
              type: 'chat'
            }
        });
        dialogRef.componentInstance.exit.subscribe((event) => {
          dialogRef.close();
        });

        dialogRef.componentInstance.send.subscribe((event) => {
          // update chat to be finished
          this.chatService
            .updateChat(this.item.id, null, event.price, event.duration)
            .subscribe(
              (updatedChat) => {
                dialogRef.close();
                window.location.reload();
              },
              (error) => {
                alert(error);
                console.error(error);
              },
            );
        });
        break;
      default:
        this.router.navigate(['handle', this.item.id], {
          queryParams: {type: this.item.type},
          skipLocationChange: true,
        });
        break;
    }
  }

  isNumber(val: unknown): boolean {
    return typeof val === 'number';
  }
}
