import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private url: string = environment.apiUrl;
  constructor(private http: HttpClient) {}

  /**
   * Performs a search for companies matching the given search term.
   * If called by admin, the search is performed among all companies.
   * If called by consultant, the search is performed among all companies assigned to that consultant.
   *
   * Calling searchCompanies with one of the two optional parameters:
   * Pass 'undefined' for the parameter that is left out
   * @param terms search terms
   * @param ps number of search results per page
   * @param consultantId (optional) if supplied, the companies assigned to the given consultant will be left out from the search result
   * @param excludeCompanyIds (optional) if supplied, the companies listed will be left out from the search result
   * @returns
   *  companies: list of company objects
   *  count: number of companies in the result
   *  page: current page
   *  pageSize: number of companies per page
   *  search: search term
   */
  searchCompanies(
    terms: Observable<string>,
    ps: string,
    consultantId?: string,
    excludeCompanyIds?: BehaviorSubject<string[]>,
  ): Observable<any> {
    return terms.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((term) => {
        let params: any = {
          search: term,
          p: '0',
          ps,
        };
        if (consultantId) {
          params = {
            ...params,
            consultantId,
          };
        }
        if (excludeCompanyIds) {
          params = {
            ...params,
            excludeCompanyIds: JSON.stringify(excludeCompanyIds.getValue()),
          };
        }
        return this.http.get(this.url + '/companies', {
          params: params as HttpParams,
        });
      }),
    );
  }

  /**
   * Performs a search for consultants matching the given search term.
   *
   * Calling searchConsultants with one of the two optional parameters:
   * Pass 'undefined' for the parameter that is left out
   * @param terms search terms
   * @param ps number of search results per page
   * @param companyId (optional) if supplied, the consultants assigned to the given company will be left out from the search result
   * @param excludeConsultantIds (optional) if supplied, the consultants listed will be left out from the search result
   * @returns
   *  consultants: list of consultant objects
   *  count: number of consultants in the result
   *  page: current page
   *  pageSize: number of consultants per page
   *  search: search term
   */
  searchConsultants(
    terms: Observable<string>,
    ps: string,
    companyId?: string,
    excludeConsultantIds?: BehaviorSubject<string[]>,
  ): Observable<any> {
    return terms.pipe(
      debounceTime(400),
      switchMap((term) => {
        let params: any = {
          search: term,
          p: '0',
          ps,
        };
        if (companyId) {
          params = {
            ...params,
            excludeCompanyId: companyId,
          };
        }
        if (excludeConsultantIds) {
          params = {
            ...params,
            excludeConsultantIds: JSON.stringify(
              excludeConsultantIds.getValue(),
            ),
          };
        }
        return this.http.get(this.url + '/consultants', {
          params: params as HttpParams,
        });
      }),
    );
  }
}
