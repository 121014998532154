<div class="page">
  <div class="filter-container show-filters">
    <button
      class="show-filter"
      [ngClass]="{
        'active-filter': activeFilter === 0
      }"
      (click)="filterClicked(0)"
    >
      Alla
    </button>
    <button
      class="show-filter"
      [ngClass]="{
        'active-filter': activeFilter === 2
      }"
      (click)="filterClicked(2)"
    >
      Inkomna
    </button>
    <button
      class="show-filter"
      [ngClass]="{
        'active-filter': activeFilter === 1
      }"
      (click)="filterClicked(1)"
    >
      Kontakta kund
    </button>
  </div>
  <div class="entries-container">
    <div class="company-container" *ngFor="let companyEntries of activeEntries">
      <!--<h1 class="company-title">
        <div class="circle">{{ companyEntries.length }}</div>
        {{ companyEntries[0].companyName }}
      </h1>-->
      <bfm-item
        *ngFor="let entry of companyEntries | sortBy: 'bfm'"
        [entry]="entry"
      ></bfm-item>
    </div>
  </div>
</div>
