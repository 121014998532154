import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/Services/auth.service';

@Component({
  selector: 'app-chat-item',
  templateUrl: './chat-item.component.html',
  styleUrls: ['./chat-item.component.scss'],
})
export class ChatItemComponent implements OnInit {
  @Input() chat: any;
  isMobile: boolean;

  constructor(private router: Router, private auth: AuthenticationService) {}

  ngOnInit(): void {
    console.log(this.chat);
    this.isMobile = this.auth.mobilCheck();
  }
  enterChat() {
    this.router.navigate(['/chat', this.chat.id], {
      queryParams: {
        company: this.chat.customer.id,
        chat: this.chat.id,
      },
    });
  }
}
