<div class="page">
  <company-info [company]="companyInfo"></company-info>
  <div class="page-content">
    <div class="left-container">
      <form [formGroup]="rowsForm" class="generic-info">
        <div class="top-row-container">
          <div class="top-row">
            <mat-form-field id="datepicker" appearance="outline">
              <mat-label>Datum:</mat-label>
              <input
                [value]="date"
                matInput
                formControlName="date"
                [matDatepicker]="myPicker"
                placeholder="date"
                required
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="myPicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #myPicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Beskrivning:</mat-label>
              <input
                matInput
                placeholder="Beskrivning"
                formControlName="description"
                [(ngModel)]="note"
                required
              />
            </mat-form-field>
          </div>
        </div>

        <div
          formArrayName="rows"
          class="example-box"
          *ngFor="let item of formData.controls; let i = index"
        >
          <div [formGroupName]="i" class="example-box box">
            <mat-form-field appearance="outline" class="account-field">
              <mat-label>Konto:</mat-label>
              <input
                matInput
                placeholder="Kontonummer"
                formControlName="account"
                name="account"
                required
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Belopp:</mat-label>
              <input
                matInput
                placeholder="Belopp"
                formControlName="amount"
                name="amount"
                required
              />
            </mat-form-field>
            <mat-radio-group required formControlName="debit">
              <mat-radio-button color="primary" value="debit" class="radio-button"
                >Debet</mat-radio-button
              >
              <mat-radio-button color="primary" value="kredit" class="radio-button"
                >Kredit</mat-radio-button
              >
            </mat-radio-group>
          </div>
        </div>
        <div class="add-row-container">
          <button class="add-row confirm-button" (click)="addRow()">
            Lägg till rad
          </button>
        </div>
      </form>
      <div class="end">
        <p class="balance">
          Balans: <span id="balance">{{ getBalance() }}</span>
        </p>
        <button class="confirm-button send-button" (click)="sendVerification()">
          Skapa verifikation
        </button>
      </div>
    </div>
    <div class="right-container">
      <div class="show-accounts-container">
        <button
          class="confirm-button show-accounts"
          (click)="toggleShowAccounts()"
        >
          Visa kontoplan
        </button>
      </div>
      <div class="text-info">
        <p class="info">
          Datum:
          <span class="highlight">{{
            bfm.paymentDate | date: 'yyyy-MM-dd'
          }}</span>
        </p>
        <p class="info">
          Belopp: <span class="highlight">{{ bfm.amount }}</span>
        </p>
        <p class="info">
          Finansiella medel: <span class="highlight">{{ bfm.source }}</span>
        </p>
        <p class="info">
          Anteckning: <span class="highlight">{{ bfm.other }}</span>
        </p>
        <mat-checkbox color="primary"
          [(ngModel)]="customerContacted"
          class="customer-contacted"
          (click)="updateCustomerContacted()"
        >
          Kund kontaktad
        </mat-checkbox>
      </div>
      <div class="receipt-container">
        <zoom [thumbImage]="bfm.receiptURL" [fullImage]="bfm.receiptURL"></zoom>
      </div>
    </div>
    <div class="account-plan-container" *ngIf="showAccountPlan">
      <ul class="account-plan">
        <li
          class="account"
          *ngFor="let account of accountPlan | sortBy: 'accountPlan'"
        >
          <span class="number">{{ account.accountNumber }}</span>
          <span class="low-key"> - </span>
          {{ account.name }}
        </li>
      </ul>
    </div>
  </div>
</div>
