import { Account } from './../Models/accounting-row';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {BookkeepForMe} from '../Models/bookkeep-for-me';
import {
  Verification,
  FinancialYear,
  VerificationResponse,
} from '../Models/accounting-row';
import {Consultant} from '../Models/consultant';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {
  }

  /**
   * Fetches all the timeslots in the future
   */
  getAllTimeslots() {
    return this.http.get(`${environment.apiUrl}/advice/timeslots/future`) as Observable<Array<any>>;
  }

  /**
   *creates a new timeslot available for booking
   * @param {Object} timeslots Should be changed to array instead of object
   */
  createTimeslot(timeslots: Object) {
    return this.http.put(`${environment.apiUrl}/advice/timeslots`, {
      times: timeslots,
    });
  }

  /**
   * fetches all the previous phone advices that were booked
   */
  getAllHistory() {
    return this.http.get(`${environment.apiUrl}/advice/history`);
  }

  /**
   * Fetches all history based on a company
   */
  getCompanyHistory(companyId: string): Observable<Array<any>> {
    return this.http.get(
      `${environment.apiUrl}/advice/history/company?companyId=${companyId}`,
    ) as Observable<Array<any>>;
  }

  /**
   * Removes a timeslot from bookable
   * @param timeslotId the id of the timeslot that should be removed
   */
  removeTimeslot(timeslotId: string) {
    return this.http.delete(
      `${environment.apiUrl}/advice/timeslots/${timeslotId}`,
      {responseType: 'text'},
    );
  }

  /**
   * Gets all the handled customers BFM tasks
   * @returns {Observable<Array<Array<BookkeepForMe>>>} The array of BFM Objects
   */
  getHandledBookkeepForMe(): Observable<Array<Array<BookkeepForMe>>> {
    return this.http.get(
      `${environment.adminUrl}/bookkeepforme/handled`,
    ) as Observable<Array<Array<BookkeepForMe>>>;
  }

  /**
   * Gets all the Contacted customers BFM tasks
   * @returns {Observable<Array<Array<BookkeepForMe>>>} The array of BFM Objects
   */
  getContactedBookkeepForMe() {
    return this.http.get(
      `${environment.adminUrl}/bookkeepforme/customerContacted`,
    ) as Observable<Array<Array<BookkeepForMe>>>;
  }

  /**
   * Gets all the unhandled BFM tasks
   * @returns {Observable<Array<Array<BookkeepForMe>>>} The array of BFM Objects
   */

  getUnhandledBookkeepForMe(): Observable<Array<Array<BookkeepForMe>>> {
    return this.http.get(
      `${environment.adminUrl}/bookkeepforme/unhandled`,
    ) as Observable<Array<Array<BookkeepForMe>>>;
  }

  /**
   * assign an advisor to a BFM
   * @param {string} bfmID
   * @param {string} advisorID
   * @param {string} advisorName
   * @returns {Observable<Object>} The array of BFM Objects
   */

  assignAdvisorBFM(bfmId: string, advisorId: string, advisorName: string) {
    return this.http.patch(`${environment.adminUrl}/bookkeepforme/${bfmId}`, {
      advisorId: advisorId,
      advisorName: advisorName,
    });
  }

  /**
   * Sets an advice to handled
   * @param {string} id Id of the advice
   * @param {number} duration Duration it took to resolve the advice
   * @param {string} message Internal message of the advice
   * @returns {Observable<Object>}
   */
  setHandledAdvice(id: string, duration: number, message: string = null) {
    return this.http.post(`${environment.apiUrl}/advice/${id}/handled`, {
      duration: duration,
      message: message,
    });
  }

  /**
   * Ge the price of a given service
   * @param {string} serviceName The name of the service advice-phone || advice-email || bfm
   * @returns {Observable<object>}
   */
  getPrice(serviceName: string) {
    return this.http.get(`${environment.apiUrl}/price/current/${serviceName}`);
  }

  /**
   * Fetches a company based on ID
   * @param {string} companyId The id of the company
   * @returns {Observable<object>}
   */
  getCompany(companyId: string) {
    return this.http.get(`${environment.apiUrl}/companies/${companyId}`);
  }

  /**
   * Creates a verification
   * @param {Verification} verification
   * @param companyId
   * @returns {Observable<VerificationResponse>}
   */
  createVer(
    verification: Verification,
    companyId: string,
    consultantId: string,
  ): Observable<VerificationResponse> {
    return this.http.post(
      `${environment.bookkeepUrl}/companies/${companyId}/financialyears/${verification.financialYearId}/verifications`,
      {...verification, consultantId: consultantId},
    ) as Observable<VerificationResponse>;
  }

  /**
   * Gets all the financial years for one account
   * @param {string} companyId
   * @returns {Observable<Array<FinancialYear>>} The array of financial years
   */
  getFinancialYears(companyId: string, token?: string): Observable<Array<FinancialYear>> {

    return this.http.get(
      `${environment.bookkeepUrl}/companies/${companyId}/financialyears`,
      {headers: token ? new HttpHeaders({"Authorization": 'Bearer ' + token}) : new HttpHeaders()}
    ) as Observable<Array<FinancialYear>>;
  }

  /**
   * Sets the status of a bfm task to handled
   * @param {string} id id of the BFM task
   *
   */
  handleBookkeepForMe(
    id: string,
    note?: string,
    advisorId?: string,
    advisorName?: string,
    verificationId?: string,
    duration?: number,
    price?: number
  ) {
    return this.http.patch(`${environment.adminUrl}/bookkeepforme/${id}`, {
      handled: true,
      note: note ? note : null,
      advisorId: advisorId ? advisorId : null,
      price: price,
      advisorName: advisorName ? advisorName : null,
      verificationId: verificationId ? verificationId : null,
      duration: duration,
    });
  }

  /**
   * Gets all bfm tasks for one company
   * @param {string} cpompanyId id of the Company
   * @returns {Observable<BookkeepForMe[]>} List of BFM task
   */
  getBFMForCompany(companyId: string): Observable<BookkeepForMe[]> {
    return this.http.get(
      `${environment.adminUrl}/bookkeepforme/${companyId}`,
    ) as Observable<BookkeepForMe[]>;
  }

  /**
   * Gets the base account plan for wrebit accounts
   * @returns {Observable<Object>} The account plan
   */
  getAccountPlan() {
    return this.http.get<{accounts: Account[]}>(
      `${environment.bookkeepUrl}/accountplans/${environment.baseAccountPlanId}`,
    );
  }

  /**
   * updates customer contacted for BFM task
   * @param {string} id id of the bfm task
   * @param {string} customerContacted null to reset, [any] to set
   */
  updateBFM(id: string, customerContacted: string | null) {
    return this.http.patch(`${environment.adminUrl}/bookkeepforme/${id}`, {
      customerContacted,
    });
  }

  /**
   * Gets SIE data for a company's financial year
   * @param {string} companyId Id of the company
   * @param {string} financialYearId Id of the financialyear
   */
  getSieData(companyId: string, financialYearId: string, companyToken: string): Observable<Blob> {
    return this.http.get(
      `${environment.bookkeepUrl}/companies/${companyId}/sie/export/${financialYearId}`,
      {responseType: 'blob', headers: new HttpHeaders({"Authorization": 'Bearer ' + companyToken})},
    );
  }

  /**
   * Creates a consultant user
   * @param {Consultant} consultantData the consultant information
   * @returns {Observable<Consultant>}
   */
  addConsultant(consultantData) {
    return this.http.post(`${environment.apiUrl}/consultants`, {
      user: {
        name: consultantData.name,
        email: consultantData.email,
        phoneNr: consultantData.phoneNr,
      },
      companyName: consultantData.companyName,
    });
  }

  /**
   * Updates an existing consultant user
   * @param {string} consultantId id of the user
   * @param {Consultant} consultantData the consultant information
   * @returns {Observable<Consultant>}
   */
  updateConsultant(
    consultantId: string,
    consultantData,
  ): Observable<Consultant> {
    return this.http.patch(
      `${environment.apiUrl}/consultants/${consultantId}`,
      {
        user: {
          name: consultantData.name,
          email: consultantData.email,
          phoneNr: consultantData.phoneNr,
        },
        companyName: consultantData.companyName,
      },
    ) as Observable<Consultant>;
  }

  /**
   * Removes an existing consultant user
   * @param consultantId the id of the user
   * @returns {Observable<Boolean>}
   */
  removeConsultant(consultantId: string) {
    return this.http.delete(
      `${environment.apiUrl}/consultants/${consultantId}`,
      {responseType: 'text'},
    );
  }

  assignCompanyToConsultant(consultantId: string, companyId: string) {
    return this.http.post(
      `${environment.apiUrl}/consultants/${consultantId}/assign/${companyId}`,
      {},
    );
  }

  unassignCompanyFromConsultant(consultantId: string, companyId: string) {
    return this.http.delete(
      `${environment.apiUrl}/consultants/${consultantId}/unassign/${companyId}`,
    );
  }
}
