import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CommonModule } from '@angular/common';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-text-input-area [id]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './text-input-area.component.html',
  styleUrls: ['./text-input-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextInputAreaComponent implements OnInit {
  @Input() id: string;
  @Input() label: string;
  @Input() initialValue: string = "";
  @Input() maxLength: number;

  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();


  constructor() {
  }

  ngOnInit(): void {
  }

  onValueChange(event) {
    this.valueChange.emit(event.target.value);
  }

}
