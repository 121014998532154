import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationService } from 'src/app/Services/auth.service';
import { PasswordReset } from 'src/app/Models/password-reset';

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password-page.component.html',
  styleUrls: ['./reset-password-page.component.scss'],
})
export class ResetPasswordPageComponent implements OnInit {
  token: string;
  decodedToken: PasswordReset;
  form: UntypedFormGroup;
  // a valid password is at least 12 characters long and contains at least one digit, one uppcase and one lowercase letter
  passwordRequirement: RegExp = new RegExp(
    /^(?=.*[a-z,å,ä,ö])(?=.*[A-Z,Å,Ä,Ö])(?=.*\d)[^\s]{12,}$/,
  );
  submitted = false;
  error = 'De angivna lösenorden stämmer inte överens';

  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private auth: AuthenticationService,
    private router: Router,
    public helper: JwtHelperService,
  ) {}

  // form
  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams.token;
    this.decodedToken = this.helper.decodeToken(this.token);
    this.form = this.formBuilder.group({
      password: [
        '',
        {validators: [Validators.required, Validators.pattern(this.passwordRequirement)], updateOn: "blur"},
      ],
      repeatedPassword: ['', Validators.required],
    });
  }

  get formControls() {
    return this.form.controls;
  }

  get formValues() {
    return this.form.value;
  }

  submit(): void {
    this.submitted = true;
    if (this.formValues.password === this.formValues.repeatedPassword) {
      this.auth
        .submitNewPassword(this.token, this.formValues.password)
        .subscribe(
          (resp) => {},
          (error) => {},
        );
      const confirmation = JSON.stringify({
        type: 'savedNewPassword',
        email: this.decodedToken.email,
      });
      localStorage.setItem('confirmationObject', confirmation);
      this.router.navigateByUrl('/confirmation');
    } else {
      this.formControls.password.markAsPristine();
      this.formControls.repeatedPassword.markAsPristine();
      console.log('password mismatch');
    }
  }

  resetSubmitted() {
    this.submitted = false;
  }
}
