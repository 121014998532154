import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';

import { environment } from '../../environments/environment';
import { AuthenticationService } from '../Services/auth.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const idToken = localStorage.getItem(environment.TOKEN_NAME);
    const alreadyHasToken = !!req.headers.get("Authorization");


    if (!alreadyHasToken && idToken) {
      req = req.clone({
        headers: req.headers.set(
          'Authorization',
          'Bearer ' + JSON.parse(idToken).token
        ),
      });
      if (req.url.includes(':userId')) {
        if (req.url.includes(':userId')) {
          req = req.clone({
            url: req.url.replace(':userId', this.auth.getUserId()),
          });
        } else {
          console.log('Missing userid');
        }
      }
      return next.handle(req);
    } else {
      return next.handle(req);
    }
  }
  constructor(private auth: AuthenticationService) {}
}
