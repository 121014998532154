<div class="page">
  <div class="flex w-full justify-center mt-8">
    <h2 class="text-center tk-skolar-latin text-3xl font-bold">
      Kontobeskrivningar
    </h2>
  </div>
  <div class="w-4/6 mx-auto">
    <app-search-bar
      (valueChange)="search($event)"
      [placeholder]="'Sök kontonamn...'"
    ></app-search-bar>
  </div>
  <div class="grid grid-rows-1 grid-cols-12 w-4/6 mx-auto mt-8">
    <p class="col-start-1 col-end-2">Kontonr</p>
    <p class="col-start-2 col-end-3">Kontonamn</p>
    <p class="col-start-11 col-end-13 text-right">Antal öppnade</p>
  </div>
  <div class="w-4/6 mx-auto ">
    <app-account-management-item
    class="block mb-2"
      *ngFor="let account of filteredAccounts$ | async; trackBy:accountTrackByFn"
      [account]="account"
      (trashEvent)="removeDescription(account)"
      (editEvent)="editDescription(account)"
    ></app-account-management-item>
  </div>
</div>
