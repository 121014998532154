import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Services/api.service';
import { Timeslot } from 'src/app/Models/timeslot';
import {
  TimelineHelperService,
  ALL_ADVISORS,
} from 'src/app/Services/timeline-helper.service';
import * as moment from 'moment';
import { Router } from '@angular/router';

enum AvailableFilter {
  All = 0,
  Available = 1,
  Booked = 2,
}

@Component({
  selector: 'timeline-page',
  templateUrl: './timeline-page.component.html',
  styleUrls: ['./timeline-page.component.scss'],
})
export class TimelinePageComponent implements OnInit {
  public currentTimeslots: Array<Timeslot> = [];
  public advisorFilterApplied: Array<Timeslot> = [];
  public availableFilterApplied: Array<Timeslot> = [];
  public allFutureTimeslots: Array<Timeslot> = [];
  public comingDays: Array<string> = [];
  public availableFilter: AvailableFilter = AvailableFilter.All;
  public advisors: Array<string> = [ALL_ADVISORS];
  public filteredAdvisor: string = ALL_ADVISORS;

  constructor(
    private api: ApiService,
    private helper: TimelineHelperService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.updateTimeslotsList();
  }

  applyAvailableFilter(filter: number): void {
    switch (filter) {
      case 0:
        this.availableFilterApplied = this.allFutureTimeslots;
        this.availableFilter = AvailableFilter.All;
        break;
      case 1:
        if (this.allFutureTimeslots.length !== 0) {
          this.availableFilterApplied = this.helper.filterAvailable(
            true,
            this.allFutureTimeslots
          );
        }
        this.availableFilter = AvailableFilter.Available;
        break;
      case 2:
        if (this.allFutureTimeslots.length !== 0) {
          this.availableFilterApplied = this.helper.filterAvailable(
            false,
            this.allFutureTimeslots
          );
        }
        this.availableFilter = AvailableFilter.Booked;
        break;

      default:
        break;
    }
  }

  applyAdvisorFilter(advisor: any): void {
    this.filteredAdvisor = advisor;
    this.advisorFilterApplied = this.helper.filterAdvisor(
      advisor,
      this.allFutureTimeslots
    );
  }

  applyAllFilters(advisorName?: string, available?): void {
    if (advisorName) {
      this.applyAdvisorFilter(advisorName);
    } else if (available >= 0) {
      this.applyAvailableFilter(available);
    }
    this.currentTimeslots = this.advisorFilterApplied.filter((timeslot) =>
      this.availableFilterApplied.includes(timeslot)
    );
    this.comingDays = this.helper.getComingDays(this.currentTimeslots);
  }
  shouldShowDay(timeslot: Timeslot, index: number): boolean {
    if (index !== 0) {
      return !moment(timeslot.start).isSame(
        this.currentTimeslots[index - 1].start,
        'day'
      );
    } else {
      return true;
    }
  }
  getDay(timeslot: Timeslot): string {
    return moment(timeslot.start).format('D MMMM, YYYY');
  }
  goToNewTimeslots(): void {
    this.router.navigate(['new-timeslots']);
  }

  updateTimeslotsList(event?: string): void {
    console.log(event);
    this.api.getAllTimeslots().subscribe((timeslots: Array<Timeslot>): void => {
      let splitTimes = this.helper.splitTimeslots(timeslots);
      this.allFutureTimeslots = splitTimes[0];
      this.currentTimeslots = splitTimes[0];
      this.advisorFilterApplied = splitTimes[0];
      this.comingDays = this.helper.getComingDays(this.currentTimeslots);
      this.getAllAdvisors();
    });
  }
  getAllAdvisors(): void {
    this.advisors = [ALL_ADVISORS];
    this.currentTimeslots.forEach((timeslot: Timeslot) => {
      if (timeslot.advisor && !this.advisors.includes(timeslot.advisor.name)) {
        this.advisors.push(timeslot.advisor.name);
      }
    });
  }
}
