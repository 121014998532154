<div class="bfm-item-container">
  <div class="left-container">
    <div class="info-container">
      <p class="sub-label">{{ entry.createdAt | date: "yyyy-MM-dd" }}</p>
      <p class="label">
        {{ entry.companyName }} har skickat in
        <span class="blue-text">1 underlag</span>
      </p>
    </div>
  </div>
  <div class="right-container">
    <p class="advisor">{{ entry.advisorName ? entry.advisorName : "" }}</p>
    <button class="confirm-button blue-button" (click)="enterCompany()">
      ÖPPNA ÄRENDE <i class="fa fa-arrow-right arrow-right"></i>
    </button>
  </div>
</div>
