import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
})
export class ToggleButtonComponent implements OnInit, OnChanges {
  @Output() switch = new EventEmitter<boolean>();
  @Input() offText: string;
  @Input() onText: string;
  @Input() initialValue: boolean;
  value: any;

  constructor() {}

  ngOnInit(): void {
    console.log(this.value);
    this.value = this.initialValue;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initialValue) {
      this.value = changes.initialValue.currentValue;
    }
  }
  emitValue(event): void {
    this.value = (document.querySelector(
      '#toggle'
    ) as HTMLInputElement).checked;
    this.switch.emit(this.value);
  }
}
