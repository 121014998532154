import { Component, OnInit } from '@angular/core';
import {  MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/Services/auth.service';

@Component({
  selector: 'app-leave-chat',
  templateUrl: './leave-chat.component.html',
  styleUrls: ['./leave-chat.component.scss'],
})
export class LeaveChatComponent implements OnInit {
  isMobile: boolean;
  constructor(
    private dialogRef: MatDialogRef<LeaveChatComponent>,
    private auth: AuthenticationService
  ) {
    this.isMobile = auth.mobilCheck();
  }

  ngOnInit(): void {}

  keepAssignment(): void {
    this.dialogRef.close(true);
  }

  removeAssignment(): void {
    this.dialogRef.close(false);
  }
  cancel(): void {
    this.dialogRef.close();
  }
}
