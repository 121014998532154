<div
  class="w-full grid grid-cols-12 grid-rows-[auto_auto] py-4 transition-colors duration-300 rounded-l px-2 relative"
  [ngClass]="{ 'bg-white': !isOpen, 'bg-green': isOpen }"
  (click)="isOpen = !isOpen"
>
<div *ngIf="account.description" [hidden]="isOpen" class="w-4 h-4 right-20 top-5 rounded-full bg-primary absolute"></div>
  <p class="row-span-1 col-start-1 col-end-2 text-lg my-0 ml-2 font-light">
    {{ account.accountNumber }}
  </p>
  <p class="row-span-1 col-start-2 col-end-8 text-lg my-0">
    {{ account.name }}
  </p>
  <p
    class="row-span-1 col-start-11 col-end-13 text-right text-lg my-0 mr-2"
    *ngIf="!isOpen; else openControls"
  >
    {{ account.numberOfDescriptionUses}}
  </p>

  <div class="row-span-2 col-start-2 col-end-12 mt-2" *ngIf="isOpen">
    <textarea
      [@fadeInOut]
      (click)="$event.stopPropagation()"
      [readOnly]="true"
      name="description"
      [id]="account.accountNumber + '-description'"
      rows="10"
      class="w-full pointer-events-auto p-2 outline-none resize-none cursor-default bg-transparent text-gray-500 font-light text-lg"
      [ngModel]="account.description"
    ></textarea>
  </div>
</div>

<ng-template #openControls>
  <button
    mat-icon-button
    [color]="'accent'"
    class="row-span-1 col-start-11 col-end-12 w-8 h-8 ml-8 justify-center flex items-center"
    (click)="editHandler($event)"
    id="edit-account-btn"
  >
    <div class="w-6 h-6"></div>
  </button>
  <button
    mat-icon-button
    id="trash-account-btn"
    [color]="'warn'"
    class="row-span-1 col-start-12 col-end-13 w-8 h-8 justify-center flex items-center"
    (click)="trashHandler($event)"
  >
    <div class="w-6 h-6"></div>
  </button>
</ng-template>
