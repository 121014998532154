import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/Services/auth.service';
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from '@angular/platform-browser';
import { FinancialYear } from 'src/app/Models/accounting-row';
import { ApiService } from 'src/app/Services/api.service';
import { Company } from 'src/app/Models/company';
import { UntypedFormControl } from '@angular/forms';
import {Environment} from '@angular/cli/lib/config/workspace-schema';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-app-view-page',
  templateUrl: './app-view-page.component.html',
  styleUrls: ['./app-view-page.component.scss'],
})
export class AppViewPageComponent implements OnInit {
  company: Company;
  companyToken: string;
  url: SafeUrl;
  financialYears: FinancialYear[];
  financialYearControl: UntypedFormControl = new UntypedFormControl();
  selectedFinancialYear: FinancialYear;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authentificationService: AuthenticationService,
    public sanitizer: DomSanitizer,
    private api: ApiService,
  ) {}

  ngOnInit(): void {
    const decoded = localStorage.getItem('villainCompany');
    this.company = JSON.parse(decoded);

    this.financialYearControl.valueChanges.subscribe((fy) => {
      this.selectedFinancialYear = fy;
    });
    this.authentificationService
      .getCompanyToken(this.company.id)
      .subscribe((companyToken) => {
        this.companyToken = companyToken.signed;

        this.getFinancialYears();
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
          `${environment.villainUrl}/login?villainToken=${companyToken.signed}`,
        );
      });
  }

  isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME'

  closeConnectionAndGoBack() {
    this.router.navigateByUrl('search');
  }
  getFinancialYears() {

    this.api
      .getFinancialYears(this.company.id, this.companyToken)
      .subscribe((financialYears: FinancialYear[]) => {
        this.financialYears = financialYears;
      });
  }
  sieExport() {
    if (this.selectedFinancialYear) {

      this.api
        .getSieData(this.company.id, this.selectedFinancialYear.id, this.companyToken)
        .subscribe((data) => {
          console.log(data);
        });
    }
  }
}
