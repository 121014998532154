import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Consultant } from 'src/app/Models/consultant';

@Component({
  selector: 'consultant-list-item',
  templateUrl: './consultant-list-item.component.html',
  styleUrls: ['./consultant-list-item.component.scss'],
})
export class ConsultantListItemComponent implements OnInit {
  @Input() consultant: Consultant;
  @Output()
  consultantClicked: EventEmitter<Consultant> = new EventEmitter<Consultant>();

  constructor() {}

  ngOnInit(): void {}

  onConsultantClick(): void {
    this.consultantClicked.emit(this.consultant);
  }
}
