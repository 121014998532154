<div class="page">
  <div class="list-container">
    <div class="list">
      <div class="filter-container">
        <button
          class="filter-button"
          (click)="applyAllFilters(null, 0)"
          [ngClass]="{ 'active-filter': availableFilter === 0 }"
        >
          Alla
        </button>
        <button
          class="filter-button"
          (click)="applyAllFilters(null, 1)"
          [ngClass]="{ 'active-filter': availableFilter === 1 }"
        >
          Tillgängliga
        </button>
        <button
          class="filter-button"
          (click)="applyAllFilters(null, 2)"
          [ngClass]="{ 'active-filter': availableFilter === 2 }"
        >
          Bokade
        </button>
      </div>
      <div class="day-list">
        <div
          class="timeline-item-container"
          *ngFor="let timeslot of currentTimeslots; let i = index"
        >
          <h3 class="time-title" *ngIf="shouldShowDay(timeslot, i)">
            {{ getDay(timeslot) }}
          </h3>
          <timeline-item
            [timeslot]="timeslot"
            (onRemove)="updateTimeslotsList($event)"
          ></timeline-item>
        </div>
      </div>
    </div>
  </div>
  <div class="fab-button-container">
    <button mat-fab color="primary" (click)="goToNewTimeslots()">
      <mat-icon
        class="material-icon"
        aria-hidden="false"
        aria-label="Example home icon"
      >add
      </mat-icon
      >
    </button>
  </div>
</div>
