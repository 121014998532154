<div class="page" *ngIf="!isMobile; else mobile">
  <div class="filter-container show-filters">
    <app-toggle-button
      [onText]="'Tillgänglig'"
      [offText]="'Otillgänglig'"
      (switch)="toggleAvailable($event)"
      [initialValue]="isAvailable"
      class="desktop-toggle"
    ></app-toggle-button>
  </div>
  <div class="entries-container">
    <app-chat-item *ngFor="let chat of chats" [chat]="chat"></app-chat-item>
  </div>
</div>

<ng-template #mobile>
  <div class="mobile-page">
    <p class="mobile-name">{{ username ? username : "DEVELOPER" }}</p>
    <p class="mobile-toggle-title">Status i chatten:</p>
    <app-toggle-button
      [onText]="'Tillgänglig'"
      [offText]="'Otillgänglig'"
      (switch)="toggleAvailable($event)"
      [initialValue]="isAvailable"
      class="mobile-toggle"
    ></app-toggle-button>
    <div class="mobile-entries-container">
      <app-chat-item *ngFor="let chat of chats" [chat]="chat"></app-chat-item>
    </div>
  </div>
</ng-template>
