import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/Services/chat.service';

interface iChatOptions {
  chatStatus: string;
}
@Component({
  selector: 'app-chat-options',
  templateUrl: './chat-options.component.html',
  styleUrls: ['./chat-options.component.scss'],
})
export class ChatOptionsComponent implements OnInit, OnDestroy {
  confirmationSubscription: Subscription;
  constructor(
    private chatService: ChatService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: iChatOptions
  ) {}

  //TODO SET CLASS AND TEXT OF BUTTON DEPENDING ON data.chatStatus and res from subscription

  ngOnInit(): void {
    this.confirmationSubscription = this.chatService.confirmationReq.subscribe(
      (res) => {
        switch (res) {
          case 'pending':
            this.data.chatStatus = 'pending';
            break;
          case 'confirmed':
            this.data.chatStatus = 'confirmed';
            break;

          default:
            break;
        }
      }
    );
  }
  ngOnDestroy(): void {
    this.confirmationSubscription.unsubscribe();
  }

  sendConfirmationRequest(): void {
    this.chatService.confirmationReq.emit(true);
  }
}
