import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-text-input [id]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextInputComponent implements OnInit {
  @Input() id: string;
  @Input() label: string;
  @Input() maxLength: number;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();


  constructor() {
  }

  ngOnInit(): void {
  }

  onValueChange(event) {
    this.valueChange.emit(event.target.value);
  }

}
