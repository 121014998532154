<div class="flex w-full p-3 justify-between bg-white rounded items-center">
  <div class="w-1/4">
    <p>{{item.title}}</p>
  </div>
  <div class="w-1/5"><p>{{item.createdAt | date:'yyyy-MM-dd'}}</p></div>
  <div class="w-1/5"><p>{{item.viewStats.numActiveFor}}</p></div>
  <div class="w-1/4"><p>{{item.viewStats.numHasSeen}}</p></div>
  <div class="w-12">
    <button (click)="onDelete()" mat-icon-button color="accent" *ngIf="!item.deleted">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>
</div>
