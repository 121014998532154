import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AccountPlanPatchDTO, AdminAccountPlanDTO } from '@hawco/wrebit-contracts';

@Injectable({
  providedIn: 'root',
})
export class AccountDescriptionService {
  constructor(private http: HttpClient) { }

  getAccounts(): Observable<AdminAccountPlanDTO> {
    return this.http.get<AdminAccountPlanDTO>(`${environment.bookkeepUrl}/admin/accountplans/${environment.baseAccountPlanId}`);
  }

  removeAccountDescription(accountId: string): Observable<any> {
    const data: AccountPlanPatchDTO = {
      description: '',
    };
    return this.http.patch<any>(
      `${environment.bookkeepUrl}/accounts/${accountId}`,
      data,
    );
  }

  editAccountDescription(
    accountId: string,
    description: string,
  ): Observable<any> {
    const data: AccountPlanPatchDTO = {
      description,
    };
    return this.http.patch<any>(
      `${environment.bookkeepUrl}/accounts/${accountId}`,
      data,
    );
  }
}
