import { Injectable, EventEmitter } from '@angular/core';
import * as io from 'socket.io-client';
import { HttpClient } from '@angular/common/http';
import { ChatMessage, Chat } from '../Models/chat';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private url = environment.socketUrl;
  public socket;
  public quitChat: EventEmitter<boolean> = new EventEmitter<boolean>();
  public messageHandler: EventEmitter<ChatMessage[]> = new EventEmitter();
  public updateHandler: EventEmitter<any> = new EventEmitter();
  public patchHandler: EventEmitter<any> = new EventEmitter();
  public confirmationReq: EventEmitter<any> = new EventEmitter();
  constructor(private http: HttpClient) {}

  public sendMessage(
    messageContent: string,
    conversationId: string,
    counter: number,
    sender?: string,
  ): Observable<any> {
    return this.http.post(
      `${environment.adminUrl}/chat/${conversationId}/message`,
      {
        text: messageContent,
        counter: counter,
        sender: sender ? sender : 'chat-consultant',
      },
      { responseType: 'text' },
    );
  }

  public initChat(conversationId: string) {
    if (this.socket) {
      this.socket.close();
    }
    this.socket = io(this.url, { path: '/socket' });

    this.socket.on('message', (messages): void => {
      console.log('message received');
      this.messageHandler.emit(messages);
      console.log(messages);
    });
    this.socket.on('connect', () => {
      console.log('Socket connected');
      this.socket.emit('init', {
        conversationId: conversationId,
        type: 'chat-consultant',
      });
    });
    this.socket.on('chatUpdates', (data) => {
      console.log('Chat meta updated');
      console.log(data);
      this.updateHandler.emit(data);
    });
  }

  public initAvailability() {
    this.socket = io(this.url, { path: '/socket' });
    this.socket.on('connect', () => {
      console.log('Socket connected');
      this.socket.emit('init', {
        type: 'admin-chat-menu',
      });
    });
    this.socket.on('chat-patch', (data) => {
      console.log('Chat data updated');
      console.log(data);
      this.patchHandler.emit(data);
      //TESTA DETTA
    });
  }

  public setAvailability(value: boolean) {
    return this.http.post(
      `${environment.adminUrl}/chat/:userId`,
      {
        available: value.toString(),
      },
      { responseType: 'text' },
    );
  }
  public getAvailability() {
    return this.http.get(
      `${environment.adminUrl}/chat/consultant/:userId/status`,
    );
  }

  public getMyChats(): Observable<any> {
    return this.http.get(`${environment.adminUrl}/chat/consultant/:UserId`);
  }
  public updateChat(
    conversationId: string,
    consultantId?: string,
    price?: string,
    duration?: string,
    tags?: Array<string>,
    status?: string,
  ): Observable<any> {
    return this.http.patch(`${environment.adminUrl}/chat/${conversationId}`, {
      consultantId: consultantId,
      price: price,
      duration: duration,
      tags: tags,
      status: status,
    });
  }

  public getActiveChats(): Observable<Chat[]> {
    return this.http.get(`${environment.adminUrl}/chat`, {
      params: { status: 'active' },
    }) as Observable<Chat[]>;
  }
  public getFinishedChats(): Observable<Chat[]> {
    return this.http.get(`${environment.adminUrl}/chat`, {
      params: { status: 'finished' },
    }) as Observable<Chat[]>;
  }

  public getMessages(conversationId: string): Observable<any> {
    return this.http.get(
      `${environment.adminUrl}/chat/${conversationId}/messages`,
    );
  }

  public disconnectFromChat(): void {
    this.socket.close();
  }

  public getChatsForCompany(companyId: string): Observable<Chat[]> {
    return this.http.get(
      `${environment.adminUrl}/chat/customer/${companyId}`,
    ) as Observable<Chat[]>;
  }

  public getChat(conversationId: string): Observable<Chat> {
    return this.http.get(
      `${environment.adminUrl}/chat/${conversationId}`,
    ) as Observable<Chat>;
  }

  public getTags() {
    return this.http.get(`${environment.adminUrl}/chat/tags`);
  }
}
