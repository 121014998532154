import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { defaultIfEmpty } from 'rxjs/operators';

@Component({
  selector: 'app-password-success-page',
  templateUrl: './password-success-page.component.html',
  styleUrls: ['./password-success-page.component.scss']
})
export class PasswordSuccessPageComponent implements OnInit {

  confirmationObject; 
  confirmationText: string; 

  constructor(
    private router: Router, 
  ) { }

  ngOnInit(): void {
    let confirmation = localStorage.getItem('confirmationObject');
    this.confirmationObject = JSON.parse(confirmation);
    switch (this.confirmationObject.type){
      case 'sentResetEmail':
        this.confirmationText = 'Ett mail har skickats till ' + this.confirmationObject.email + ' med en länk för lösenordsåterställning';
        break;
      case 'savedNewPassword':
        this.confirmationText = 'Lösenordet för ' + this.confirmationObject.email + ' har uppdaterats';
        break;
      default:
        break;
    }
  }

  toHomepage(): void {
    this.router.navigateByUrl('/');
  }

}
